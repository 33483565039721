import { IStick } from '../../models/article.model';
import { ArticleState, IStickState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromStickActions } from './stick.actions';

export const initialState = ArticleState.create<IStick, IStickState>({
	skip: 0,
	top: 100,
});

export const stickReducer = EntityReducer.create<IStick, IStickState>(initialState, fromStickActions);

import { IDvd } from '../../models/article.model';
import { IDvdState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromDvdActions } from './dvd.actions';

export const initialState = MediaArticleState.create<IDvd, IDvdState>(
	{
		skip: 0,
		top: 10,
	},
	{
		current: 3650,
		min: 3650,
		max: 3650,
	}
);

export const dvdReducer = EntityReducer.create<IDvd, IDvdState>(initialState, fromDvdActions);

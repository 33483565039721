import { Injectable } from '@angular/core';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { ArticleKind } from '../../models/article.model';
import { WindowRefService } from '../../services/utility/window.service';
import { AppState, CheckoutStepEnum, ConfigStepEnum } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable()
export class RouterSelector extends BaseSelector<RouterReducerState> {
	constructor(private windowRefService: WindowRefService) {
		super();
	}

	protected stateSelector = (state: AppState): RouterReducerState => state.router;

	public url = getSelectors(this.selectState).selectUrl;
	public routeData = getSelectors(this.selectState).selectRouteData;
	public routeParams = getSelectors(this.selectState).selectRouteParams;
	public queryParams = getSelectors(this.selectState).selectQueryParams;
	public urlComponents = createSelector(
		this.url,
		url =>
			url
				?.substring(1)
				.split('/')
				.map(x => x.split('?')[0]) || []
	);

	public pageUrl = createSelector(this.urlComponents, urlComponents => (urlComponents.length >= 1 ? decodeURIComponent(urlComponents[0]) : null));
	public childUrl = createSelector(this.urlComponents, urlComponents => (urlComponents.length >= 2 ? decodeURIComponent(urlComponents[1]) : null));
	public orderId = createSelector(this.routeParams, routeParams => routeParams.orderId as string);
	public newspaperId = createSelector(this.routeParams, routeParams => routeParams.newspaperId as string);
	public magazineId = createSelector(this.routeParams, routeParams => routeParams.magazineId as string);
	public checkoutStep = createSelector(this.routeParams, routeParams => routeParams?.checkoutStep as CheckoutStepEnum);
	public configStep = createSelector(this.routeParams, routeParams => routeParams?.configStep as ConfigStepEnum);
	public articleKind = createSelector(this.routeParams, routeParams => _.values(ArticleKind).find(articleKind => articleKind.toLowerCase().replace(' ', '-') == routeParams.articleKind?.toLowerCase()) as ArticleKind);
	public token = createSelector(this.queryParams, queryParams => queryParams.token as string);
	public payerId = createSelector(this.queryParams, queryParams => queryParams.PayerID as string);
	public redirect = createSelector(this.queryParams, queryParams => queryParams.redirect as boolean);
	public isReturn = createSelector(this.queryParams, queryParams => queryParams.return as boolean);
	public isCancel = createSelector(this.queryParams, queryParams => queryParams.cancel as boolean);
	public positionIndex = createSelector(this.queryParams, queryParams => (queryParams?.position != null ? parseInt(queryParams.position) : null));
	public isEditMode = createSelector(this.queryParams, queryParams => queryParams?.edit == 1);
	public searchDate = createSelector(this.routeParams, routeParams => routeParams?.searchDate as string);
}

import { Injectable } from '@angular/core';

export interface IGeschenkzeitungDocument extends Document {
	uetq: unknown[];
	createRBadge(shopId: number, badgeType: number, protocol: string): void;
	loadSRT(myToken: string, mySrc: string): void;
}

function getDocument(): any {
	return document;
}

@Injectable()
export class DocumentRefService {
	get nativeDocument(): IGeschenkzeitungDocument {
		return getDocument();
	}

	get location(): string {
		return this.nativeDocument.location.href.split('?')[0];
	}
}

<ng-container *ngFor="let block of blocks; let i = index">
	<shop-introteaser [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.IntroTeaser" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-introteaser>
	<shop-searchheader [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.SearchHeader" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-searchheader>
	<shop-headline [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Headline" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-headline>
	<shop-text [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Text" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-text>
	<shop-image [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Image" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-image>
	<shop-imagetext [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.ImageText" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-imagetext>
	<shop-video [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Video" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-video>
	<shop-customerreviews [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.CustomerReviews" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-customerreviews>
	<shop-calltoaction [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.CallToAction" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-calltoaction>
	<shop-accordion [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Accordion" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-accordion>
	<shop-newsletter [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Newsletter" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-newsletter>
	<shop-spacer [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Spacer" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-spacer>
	<shop-divider [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Divider" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-divider>
	<shop-virtual-tour [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.VirtualTour" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-virtual-tour>
	<shop-grid [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Grid" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-grid>
	<shop-usps [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.Usps" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-usps>
	<shop-howitworks [class.content-blocks--last]="isLast(i)" *ngIf="block.blockKind == BlockKinds.HowItWorks" [parameters]="block.parameters" [queryParameters]="block.queryParameters"></shop-howitworks>
</ng-container>

<mat-form-field appearance="outline">
	<mat-label class="wunschdatum">Wunschdatum eingeben</mat-label>
	<input
		matInput
		placeholder="Datum eingeben"
		#dateInput
		(focus)="onFocus(dateInput.value)"
		(keyup)="onKeyUp(dateInput.value)"
		(keyup.enter)="newspaperFacade.load()"
		(blur)="onBlur(dateInput.value)"
		[min]="minDate"
		[max]="maxDate"
		maxlength="10"
		[value]="date$ | async"
		(dateChange)="onDateChange($event.value)"
		autocomplete="off"
		[matDatepicker]="picker"
		inputmode="decimal" />
	<mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
	<mat-datepicker #picker touchUi startView="multi-year" [calendarHeaderComponent]="anniversaryHeaderComponent"></mat-datepicker>
</mat-form-field>

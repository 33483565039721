import { createAction, props } from '@ngrx/store';

const stateName = '[External Libraries]';

const shopvoteReputationLoad = createAction(`${stateName} Shopvote Reputation Load`);
const shopvoteReputationLoaded = createAction(`${stateName} Shopvote Reputation Loaded`);
const shopvoteReputationFailed = createAction(`${stateName} Shopvote Reputation Failed`);

const shopvoteReviewsLoad = createAction(`${stateName} Shopvote Reviews Load`);
const shopvoteReviewsLoaded = createAction(`${stateName} Shopvote Reviews Loaded`);
const shopvoteReviewsFailed = createAction(`${stateName} Shopvote Reviews Failed`);

const youtubeLoad = createAction(`${stateName} Youtube Load`);
const youtubeLoaded = createAction(`${stateName} Youtube Loaded`);
const youtubeFailed = createAction(`${stateName} Youtube Failed`);

export const fromExternalLibrariesActions = {
	shopvoteReputationLoad,
	shopvoteReputationLoaded,
	shopvoteReputationFailed,
	shopvoteReviewsLoad,
	shopvoteReviewsLoaded,
	shopvoteReviewsFailed,
	youtubeLoad,
	youtubeLoaded,
	youtubeFailed,
};

import { on } from '@ngrx/store';
import { IFolder } from '../../models/article.model';
import { ArticleState, IFolderState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromFolderActions } from './folder.actions';

export const initialState = ArticleState.create<IFolder, IFolderState>({
	skip: 0,
	top: 100,
});

export const folderReducer = EntityReducer.create<IFolder, IFolderState>(
	initialState,
	fromFolderActions,
	'folder',
	on(fromFolderActions.loaded, (state, { list }) => ({ ...state, preselected: state.preselected == null ? null : list.find(folder => folder._id == state.preselected._id) })),
	on(fromFolderActions.preselected, (state, { entity }) => ({ ...state, preselected: entity }))
);

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WindowRefService } from '../../services/utility/window.service';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { RouterSelector } from './router.selectors';

@Injectable()
export class RouterFacade extends BaseFacade {
	constructor(store: Store<AppState>, private routerSelector: RouterSelector, private windowRefService: WindowRefService) {
		super(store);
	}

	public url$ = this.store.select(this.routerSelector.url);
	public routeData$ = this.store.select(this.routerSelector.routeData);
	public routeParams$ = this.store.select(this.routerSelector.routeParams);
	public queryParams$ = this.store.select(this.routerSelector.queryParams);

	public checkoutStep$ = this.createBehaviourSubject(this.store.select(this.routerSelector.checkoutStep));
	public configStep$ = this.createBehaviourSubject(this.store.select(this.routerSelector.configStep));
	public articleKind$ = this.store.select(this.routerSelector.articleKind);
	public isEditMode$ = this.createBehaviourSubject(this.store.select(this.routerSelector.isEditMode));
	public searchDate$ = this.createBehaviourSubject(this.store.select(this.routerSelector.searchDate));
	public positionIndex$ = this.createBehaviourSubject(this.store.select(this.routerSelector.positionIndex));

	private buildPaypalUrl = () => `${this.windowRefService.nativeWindow.location.protocol}//${this.windowRefService.nativeWindow.location.host}${this.windowRefService.nativeWindow.location.pathname}`;
	public buildPaypalReturnUrl = () => `${this.buildPaypalUrl()}?return=true`;
	public buildPaypalCancelUrl = () => `${this.buildPaypalUrl()}?cancel=true`;
}

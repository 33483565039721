import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { INewspaper } from '../../models/article.model';
import { AppState, INewspaperState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromNewspaperActions } from './newspaper.actions';
import { NewspaperSelector } from './newspaper.selectors';

@Injectable()
export class NewspaperFacade extends EntityFacade<INewspaper, INewspaperState> {
	constructor(store: Store<AppState>, private newspaperSelector: NewspaperSelector) {
		super(store, newspaperSelector);
	}

	public isExtending$ = this.store.select(this.newspaperSelector.isExtending);
	public paging$ = this.createBehaviourSubject(this.store.select(this.newspaperSelector.paging), null);
	public dayRange$ = this.createBehaviourSubject(this.store.select(this.newspaperSelector.dayRange), null);
	public date$ = this.store.select(this.newspaperSelector.date);
	public dateOfCurrentYear$ = this.store.select(this.newspaperSelector.dateOfCurrentYear);
	public isFilterVisible$ = this.store.select(this.newspaperSelector.isFilterVisible);
	public name$ = this.store.select(this.newspaperSelector.name);

	public extend(): void {
		this.store.dispatch(fromNewspaperActions.pagingChanged({ paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}

	public changeDate(date: Date): void {
		if (date != null) {
			this.store.dispatch(fromNewspaperActions.dateChanged({ date }));
		}
	}

	public changeIsFilterVisible(isFilterVisible: boolean): void {
		this.store.dispatch(fromNewspaperActions.isFilterVisibleChanged({ isFilterVisible }));
	}

	public changeName(name: string): void {
		this.store.dispatch(fromNewspaperActions.nameChanged({ name }));
	}

	public isSearchDate(date: Date) {
		return this.store.select(this.newspaperSelector.isSearchDate(date));
	}

	protected actions = fromNewspaperActions;
}

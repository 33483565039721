import { createReducer, on } from '@ngrx/store';
import { IExternalLibrariesState } from '../app.state';
import { fromExternalLibrariesActions } from './externallibraries.actions';

const initialState: IExternalLibrariesState = {
	isYoutubeLoaded: false,
	isShopvoteReputationLoaded: false,
	isShopvoteReviewsLoaded: false,
};

export const externalLibrariesReducer = createReducer(
	initialState,

	on(fromExternalLibrariesActions.youtubeLoaded, state => ({ ...state, isYouTubeLoaded: true })),
	on(fromExternalLibrariesActions.youtubeFailed, state => ({ ...state, isYouTubeLoaded: false })),

	on(fromExternalLibrariesActions.shopvoteReputationLoaded, state => ({ ...state, isShopvoteReputationLoaded: true })),
	on(fromExternalLibrariesActions.shopvoteReputationFailed, state => ({ ...state, isShopvoteReputationLoaded: false })),

	on(fromExternalLibrariesActions.shopvoteReviewsLoaded, state => ({ ...state, isShopvoteReviewsLoaded: true })),
	on(fromExternalLibrariesActions.shopvoteReviewsFailed, state => ({ ...state, isShopvoteReviewsLoaded: false }))
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export interface IEmptyResult {
	success?: boolean;
}

export interface IResult<T> extends IEmptyResult {
	data: T;
	success?: boolean;
}

export interface IListResult<T> extends IEmptyResult {
	data: T[];
	success?: boolean;
}

export interface IErrorResult {
	error: { error: string; success: boolean; message: string };
}

@Injectable()
export abstract class DtoApiService<TDto> extends ApiService {
	protected abstract urlPathName: string;

	constructor(http: HttpClient) {
		super(http);
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IYearBook } from '../../models/article.model';
import { AppState, IYearBookState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { NewspaperSelector } from '../newspaper/newspaper.selectors';
import { fromYearBookActions } from './year-book.actions';
import { YearBookSelector } from './year-book.selectors';

@Injectable()
export class YearBookFacade extends EntityFacade<IYearBook, IYearBookState> {
	constructor(store: Store<AppState>, yearBookSelector: YearBookSelector, private newspaperSelector: NewspaperSelector) {
		super(store, yearBookSelector);
	}

	protected actions = fromYearBookActions;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IPage } from 'src/models/page.model';
import { EntityService, IEntityListResult } from '../entity.service';

@Injectable()
export class PageApiService extends EntityService<IPage> {
	protected entityName = 'pages';

	constructor(http: HttpClient) {
		super(http);
	}

	public getAll(): Observable<IPage[]> {
		return this.get<IEntityListResult<IPage>>([this.entityName, 'public'], null).pipe(map(result => result.data));
	}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WindowRefService } from '../../services/utility/window.service';
import { OrderFacade } from '../../state/order/order.facade';

@Component({
	selector: 'shop-ordererror-alert',
	templateUrl: './ordererror-alert.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderErrorAlertComponent {
	constructor(public orderFacade: OrderFacade, private windowService: WindowRefService) {}

	public reload(): void {
		this.windowService.nativeWindow.localStorage.clear();
		this.windowService.nativeWindow.location.reload();
	}
}

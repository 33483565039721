import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { INewspaper } from '../../models/article.model';
import { AppState, INewspaperState } from '../app.state';
import { MediaArticleSelector } from '../entity.selector';

@Injectable()
export class NewspaperSelector extends MediaArticleSelector<INewspaper, INewspaperState> {
	protected stateSelector = (state: AppState): INewspaperState => state.newspaper;

	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public date = createSelector(this.selectState, state => state.date);
	public dateOfCurrentYear = createSelector(this.date, date => moment(date).year(moment().year()).toDate());
	public isFilterVisible = createSelector(this.selectState, state => state.isFilterVisible);
	public name = createSelector(this.selectState, state => state.name);

	public isSearchDate = (date: Date) =>
		createSelector(this.date, searchDate => {
			if (!searchDate) {
				return false;
			}

			return moment(date).isSame(searchDate, 'day');
		});
}

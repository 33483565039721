import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, IFolder } from '../../models/article.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { FolderFacade } from '../../state/folder/folder.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { PageFacade } from '../../state/page/page.facade';
import { FolderDetailDialogComponent } from '../detaildialog/folder-detaildialog.component';

@Component({
	selector: 'shop-searchresult-folder',
	templateUrl: './searchresult-folder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultFolderComponent {
	@Output()
	public selectedFolder = new EventEmitter<IFolder>();
	public ArticleKinds = ArticleKind;

	constructor(private googleService: GoogleService, public folderFacade: FolderFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade, private dialog: MatDialog) {}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(folder: IFolder) {
		this.folderFacade.select(folder);
		this.selectedFolder.emit(folder);
		this.googleService.selectItem(folder);
		this.dialog.open(FolderDetailDialogComponent, { data: { article: folder }, panelClass: 'xselling-dialog' });
	}
}

<section class="customer-reviews">
	<div class="container">
		<div class="customer-reviews--left">
			<picture>
				<source srcset="/assets/images/testimonials.avif" type="image/avif" />
				<source srcset="/assets/images/testimonials.webp" type="image/webp" />
				<img src="/assets/images/testimonials.png" alt="Kundenbewertungen" width="650" height="504" loading="lazy" />
			</picture>
		</div>
		<div class="customer-reviews--right">
			<img src="/assets/images/customer-reviews-stars.svg" alt="Stars" class="stars" width="175" height="29" loading="lazy" />
			<p>{{ params.review || 'Tolle Geschenkemappe mit alter Zeitung und Zertifikat. Alles reibungslos verlaufen. Schneller Versand. Von mir ein „sehr gut“.' }}</p>
			<p *ngIf="params.customer">
				<small>{{ params.customer }}</small>
				<br />
				<small>Weitere Kundenbewertungen findest Du <a class="cmplazyload" [attr.data-cmp-vendor]="Vendors.Shopvote" href="https://www.shopvote.de/bewertung_geschenkzeitung_de_17463.html" target="_blank">hier</a></small>
			</p>
		</div>
	</div>
</section>

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { withLatestFrom } from 'rxjs';
import slugify from 'slugify';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, INewspaper } from '../../models/article.model';
import { IRegion } from '../../models/region.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { PageFacade } from '../../state/page/page.facade';
import { RegionFacade } from '../../state/region/region.facade';

@UntilDestroy()
@Component({
	selector: 'shop-searchresult-newspaper',
	templateUrl: './searchresult-newspaper.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultNewspaperComponent {
	@Output()
	public selectedNewspaper = new EventEmitter<INewspaper>();
	public ArticleKinds = ArticleKind;
	private region: IRegion = null;
	private resultWithoutRegion: INewspaper = null;

	constructor(private googleService: GoogleService, public newspaperFacade: NewspaperFacade, public regionFacade: RegionFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade) {
		newspaperFacade.isLoading$.pipe(untilDestroyed(this), withLatestFrom(regionFacade.selected$)).subscribe(([, region]) => {
			this.region = region;
			this.resultWithoutRegion = null;
		});
	}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(newspaper: INewspaper) {
		this.newspaperFacade.select(newspaper);
		this.selectedNewspaper.emit(newspaper);
		this.googleService.selectItem(newspaper);
	}

	public doesNotContainRegion(newspaper: INewspaper): boolean {
		if (this.resultWithoutRegion?._id == newspaper._id) {
			return true;
		}

		if (this.resultWithoutRegion == null && this.region != null && !newspaper.product.regions.some(x => x._id === this.region._id)) {
			this.resultWithoutRegion = newspaper;
			return true;
		}

		return false;
	}

	public slugify(value: string): string {
		return value ? slugify(value, { lower: true }) : null;
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface IIpInfo {
	ip: string;
	hostname: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	postal: string;
	org: string;
	timezone: string;
}

@Injectable()
export class IpInfoService {
	constructor(private http: HttpClient) {}

	public get(): Observable<IIpInfo> {
		return this.http.get<IIpInfo>(`https://ipinfo.io/json?token=${environment.ipInfoToken}`);
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMagazine } from '../../models/article.model';
import { AppState, IMagazineState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { NewspaperSelector } from '../newspaper/newspaper.selectors';
import { fromMagazineActions } from './magazine.actions';
import { MagazineSelector } from './magazine.selectors';

@Injectable()
export class MagazineFacade extends EntityFacade<IMagazine, IMagazineState> {
	constructor(store: Store<AppState>, private magazineSelector: MagazineSelector, private newspaperSelector: NewspaperSelector) {
		super(store, magazineSelector);
	}

	public isExtending$ = this.store.select(this.magazineSelector.isExtending);
	public paging$ = this.createBehaviourSubject(this.store.select(this.magazineSelector.paging), null);
	public dayRange$ = this.createBehaviourSubject(this.store.select(this.magazineSelector.dayRange), null);

	public extend(): void {
		this.store.dispatch(fromMagazineActions.pagingChanged({ paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
		this.store.dispatch(fromMagazineActions.dayRangeChanged({ dayRange: { ...this.dayRange$.value, current: this.dayRange$.value.min } }));
	}

	public isSearchDate(date: Date) {
		return this.store.select(this.newspaperSelector.isSearchDate(date));
	}

	protected actions = fromMagazineActions;
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'shop-shippingkind-dialog',
	templateUrl: 'shippingkind-dialog.component.html',
})
export class ShippingKindDialogComponent {
	constructor(private dialog: MatDialog) {}

	public onChange(): void {
		this.dialog.closeAll();
	}
}

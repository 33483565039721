import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { ShippingKind } from '../../models/order.model';
import { PositionKind } from '../../models/position.model';
import { AppState, ICartState } from '../app.state';
import { BaseSelector } from '../base.selector';
import { RouterSelector } from '../router/router.selectors';

@Injectable()
export class CartSelector extends BaseSelector<ICartState> {
	constructor(private routerSelector: RouterSelector) {
		super();
	}

	protected stateSelector = (state: AppState): ICartState => state.cart;

	public cartState = createSelector(this.selectState, state => state);
	public allowedShippingKinds = createSelector(this.selectState, state => state.allowedShippingKinds);
	public paymentKind = createSelector(this.selectState, state => state.paymentKind);
	public receiverForm = createSelector(this.selectState, state => state.receiverForm);
	public payerForm = createSelector(this.selectState, state => state.payerForm);
	public customerForm = createSelector(this.selectState, state => state.customerForm);
	public isReceiverDiffering = createSelector(this.selectState, state => state.isReceiverDiffering);
	public allowShippingAddress = createSelector(this.selectState, state => state.allowShippingAddress);
	public allowPackstation = createSelector(this.selectState, state => state.allowPackstation);
	public shippingWithoutInvoice = createSelector(this.selectState, state => state.shippingWithoutInvoice);
	public consentEmailTransfer = createSelector(this.selectState, state => state.consentEmailTransfer);
	public enableSaturdayDelivery = createSelector(this.selectState, state => state.enableSaturdayDelivery);
	public isPackstation = createSelector(this.selectState, state => state.isPackstation);
	public shippingKind = createSelector(this.selectState, state => state.shippingKind);
	public taxes = createSelector(this.selectState, state => state.taxes);
	public payer = createSelector(this.selectState, state => state.payer);
	public receiver = createSelector(this.selectState, state => state.receiver);
	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public ipInfo = createSelector(this.selectState, state => state.ipInfo);
	public error = createSelector(this.selectState, state => state.error);
	public hasError = createSelector(this.error, error => error != null);
	public isDisabled = createSelector(this.selectState, state => state.isDisabled);
	public totalPrice = createSelector(this.selectState, state => state.totalPrice);
	public positions = createSelector(this.selectState, state => state.positions);
	public productPositions = createSelector(this.selectState, state => state.positions.filter(position => position.positionKind == PositionKind.Article || position.positionKind == PositionKind.Bundle));
	public positionsAvailable = createSelector(this.productPositions, productPositions => productPositions.filter(productPosition => !productPosition.isUnavailable));
	public positionsUnavailable = createSelector(this.productPositions, productPositions => productPositions.filter(productPosition => productPosition.isUnavailable));
	public shippingPosition = createSelector(this.selectState, state => state.positions.find(position => position.positionKind == PositionKind.Shipping));
	public paymentPosition = createSelector(this.selectState, state => state.positions.find(position => position.positionKind == PositionKind.Payment));
	public subtotal = createSelector(this.productPositions, productPositions => _.sumBy(productPositions, productPosition => productPosition.price));
	public isUnavailable = createSelector(this.positions, positions => positions.some(position => position.isUnavailable));
	public isPartiallyUnavailable = createSelector(this.positions, positions => positions.some(position => position.isPartiallyUnavailable));
	public selectedPostion = createSelector(this.positionsAvailable, this.routerSelector.positionIndex, (positions, index) => (index != null ? positions[index] : null));
	public isAvailable = (shippingKind: ShippingKind) => createSelector(this.allowedShippingKinds, allowedShippingKinds => allowedShippingKinds.includes(shippingKind));
}

<div class="gz-radio-group gz-radio-group--shipping">
	<ng-container *ngIf="cartFacade.shippingKind$ | async as shippingKind">
		<mat-radio-group
			class="gz-radio-group__radio-group"
			[value]="shippingKind"
			(change)="onChange($event.value)"
			aria-labelledby="gz-radio-label"
			[disabled]="!(cartFacade.isAvailable(ShippingKinds.Dhl) | async)"
			*ngIf="configurationFacade.shippingInformation$ | async as shippingInformation">
			<mat-radio-button class="gz-radio-group__radio-button" [value]="shippingType.shippingKind" *ngIf="configurationFacade.shippingType(ShippingKinds.Dhl) | async as shippingType">
				<div class="gz-radio-group__radio-button-grid">
					<img src="/assets/images/dhl.svg" alt="Logo DHL" width="120" height="26" class="gz-radio-group__radio-button-image" />
					<div class="gz-radio-group__radio-button-headline">DHL <small class="text-error" *ngIf="!(cartFacade.isAvailable(ShippingKinds.Dhl) | async)">Nicht verfügbar für die ausgewählte Zahlart</small></div>
					<div class="gz-radio-group__radio-button-description">
						<shop-shippingkind-deliveryrange [shippingType]="shippingType"></shop-shippingkind-deliveryrange>
						<ng-container *ngIf="shippingKind == shippingType.shippingKind">
							<div class="shipping-checkboxes">
								<mat-checkbox *ngIf="shippingType.priceSaturdayDelivery != null" [checked]="cartFacade.enableSaturdayDelivery$ | async" (change)="cartFacade.changeEnableSaturdayDelivery($event.checked)">
									Samstagszustellung (zzgl. {{ shippingType.priceSaturdayDelivery | currency }})
								</mat-checkbox>
								<mat-checkbox [checked]="cartFacade.consentEmailTransfer$ | async" (change)="cartFacade.changeConsentEmailTransfer($event.checked)">
									Statusaktualisierungen von {{ shippingKind }} per E-Mail erhalten<br />
									<small>Ich stimme der zweckgebundenen Weitergabe meiner Kontaktdaten an den Versanddienstleister gemäß aktueller <a href="/rechtliches/datenschutz" target="_blank">Datenschutzbestimmungen</a> zu.</small>
								</mat-checkbox>
								<mat-checkbox *ngIf="cartFacade.allowShippingAddress$ | async" [checked]="cartFacade.isReceiverDiffering$ | async" (change)="cartFacade.changeReceiverDiffering($event.checked)">
									Abweichende Lieferadresse
								</mat-checkbox>
							</div>
						</ng-container>
					</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && !(cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && (cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price + shippingType.priceSaturdayDelivery | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price == 0">kostenlos</div>
				</div>
			</mat-radio-button>

			<mat-radio-button
				class="gz-radio-group__radio-button"
				[value]="shippingType.shippingKind"
				[disabled]="!(cartFacade.isAvailable(ShippingKinds.DhlPremium) | async)"
				*ngIf="configurationFacade.shippingType(ShippingKinds.DhlPremium) | async as shippingType">
				<div class="gz-radio-group__radio-button-grid">
					<img src="/assets/images/dhl.svg" alt="Logo DHL" width="120" height="26" class="gz-radio-group__radio-button-image" />
					<div class="gz-radio-group__radio-button-headline">
						DHL (mit Premiumbearbeitung) <small class="text-error" *ngIf="!(cartFacade.isAvailable(ShippingKinds.DhlPremium) | async)">Nicht verfügbar für die ausgewählte Zahlart</small>
					</div>
					<div class="gz-radio-group__radio-button-description">
						<shop-shippingkind-deliveryrange [shippingType]="shippingType"></shop-shippingkind-deliveryrange>
						<ng-container *ngIf="shippingKind == shippingType.shippingKind">
							<div class="shipping-checkboxes">
								<mat-checkbox *ngIf="shippingType.priceSaturdayDelivery != null" [checked]="cartFacade.enableSaturdayDelivery$ | async" (change)="cartFacade.changeEnableSaturdayDelivery($event.checked)">
									Samstagszustellung (zzgl. {{ shippingType.priceSaturdayDelivery | currency }})
								</mat-checkbox>
								<mat-checkbox [checked]="cartFacade.consentEmailTransfer$ | async" (change)="cartFacade.changeConsentEmailTransfer($event.checked)">
									Statusaktualisierungen von {{ shippingKind }} per E-Mail erhalten<br />
									<small>Ich stimme der zweckgebundenen Weitergabe meiner Kontaktdaten an den Versanddienstleister gemäß aktueller <a href="/rechtliches/datenschutz" target="_blank">Datenschutzbestimmungen</a> zu.</small>
								</mat-checkbox>
								<mat-checkbox *ngIf="cartFacade.allowShippingAddress$ | async" [checked]="cartFacade.isReceiverDiffering$ | async" (change)="cartFacade.changeReceiverDiffering($event.checked)">
									Abweichende Lieferadresse
								</mat-checkbox>
							</div>
						</ng-container>
					</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && !(cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && (cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price + shippingType.priceSaturdayDelivery | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price == 0">kostenlos</div>
				</div>
			</mat-radio-button>

			<mat-radio-button
				class="gz-radio-group__radio-button"
				[value]="shippingType.shippingKind"
				[disabled]="!(cartFacade.isAvailable(ShippingKinds.DhlExpress) | async)"
				*ngIf="configurationFacade.shippingType(ShippingKinds.DhlExpress) | async as shippingType">
				<div class="gz-radio-group__radio-button-grid">
					<img src="/assets/images/dhl_express.svg" alt="Logo DHL Express" width="120" height="37" class="gz-radio-group__radio-button-image" />
					<div class="gz-radio-group__radio-button-headline">DHL Express <small class="text-error" *ngIf="!(cartFacade.isAvailable(ShippingKinds.DhlExpress) | async)">Nicht verfügbar für die ausgewählte Zahlart</small></div>
					<div class="gz-radio-group__radio-button-description">
						<shop-shippingkind-deliveryrange [shippingType]="shippingType"></shop-shippingkind-deliveryrange>
						<ng-container *ngIf="shippingKind == shippingType.shippingKind">
							<div class="shipping-checkboxes">
								<mat-checkbox *ngIf="shippingType.priceSaturdayDelivery != null" [checked]="cartFacade.enableSaturdayDelivery$ | async" (change)="cartFacade.changeEnableSaturdayDelivery($event.checked)">
									Samstagszustellung (zzgl. {{ shippingType.priceSaturdayDelivery | currency }})
								</mat-checkbox>
								<mat-checkbox [checked]="cartFacade.consentEmailTransfer$ | async" (change)="cartFacade.changeConsentEmailTransfer($event.checked)">
									Statusaktualisierungen von {{ shippingKind }} per E-Mail erhalten<br />
									<small>Ich stimme der zweckgebundenen Weitergabe meiner Kontaktdaten an den Versanddienstleister gemäß aktueller <a href="/rechtliches/datenschutz" target="_blank">Datenschutzbestimmungen</a> zu.</small>
								</mat-checkbox>
								<mat-checkbox *ngIf="cartFacade.allowShippingAddress$ | async" [checked]="cartFacade.isReceiverDiffering$ | async" (change)="cartFacade.changeReceiverDiffering($event.checked)">
									Abweichende Lieferadresse
								</mat-checkbox>
							</div>
						</ng-container>
					</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && !(cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0 && (cartFacade.enableSaturdayDelivery$ | async)">{{ shippingType.price + shippingType.priceSaturdayDelivery | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price == 0">kostenlos</div>
				</div>
			</mat-radio-button>

			<mat-radio-button
				class="gz-radio-group__radio-button"
				[value]="shippingType.shippingKind"
				[disabled]="!(cartFacade.isAvailable(ShippingKinds.Pickup) | async)"
				*ngIf="configurationFacade.shippingType(ShippingKinds.Pickup) | async as shippingType"
				[class.mat-radio-disabled]="!(cartFacade.isAvailable(ShippingKinds.Pickup) | async)">
				<div class="gz-radio-group__radio-button-grid">
					<img src="/assets/images/pickup.svg" alt="Logo Abholung" width="120" height="37" class="gz-radio-group__radio-button-image" />
					<div class="gz-radio-group__radio-button-headline">Abholung in Wuppertal <small class="text-error" *ngIf="!(cartFacade.isAvailable(ShippingKinds.Pickup) | async)">Nicht verfügbar für die ausgewählte Zahlart</small></div>
					<div class="gz-radio-group__radio-button-description">
						<shop-shippingkind-deliveryrange [shippingType]="shippingType"></shop-shippingkind-deliveryrange>
						<ng-container *ngIf="shippingKind == shippingType.shippingKind">
							<div class="shipping-checkboxes">
								<mat-checkbox *ngIf="shippingType.priceSaturdayDelivery != null" [checked]="cartFacade.enableSaturdayDelivery$ | async" (change)="cartFacade.changeEnableSaturdayDelivery($event.checked)">
									Abholung am Samstag (zzgl. {{ shippingType.priceSaturdayDelivery | currency }})
								</mat-checkbox>
							</div>
						</ng-container>
					</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price > 0">{{ shippingType.price | currency }}</div>
					<div class="gz-radio-group__radio-button-price" *ngIf="shippingType.price == 0">kostenlos</div>
				</div>
			</mat-radio-button>
		</mat-radio-group>
	</ng-container>
</div>

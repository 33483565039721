import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICertificate } from '../../models/article.model';
import { AddressForm, OccasionType } from '../../models/product.model';
import { AppState, ICertificateState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCertificateActions } from './certificate.actions';
import { CertificateSelector } from './certificate.selectors';

@Injectable()
export class CertificateFacade extends EntityFacade<ICertificate, ICertificateState> {
	constructor(store: Store<AppState>, private certificateSelector: CertificateSelector) {
		super(store, certificateSelector);
	}

	public anniversary$ = this.store.select(this.certificateSelector.anniversary);
	public text$ = this.store.select(this.certificateSelector.text);
	public customMessage$ = this.store.select(this.certificateSelector.customMessage);
	public isComplete$ = this.store.select(this.certificateSelector.isComplete);

	public changeCustomOccasion(customOccasion: string) {
		this.store.dispatch(fromCertificateActions.customOccasionChanged({ customOccasion }));
	}

	public changeDate(date: Date) {
		this.store.dispatch(fromCertificateActions.dateChanged({ date }));
	}

	public changePartner(partner: string) {
		this.store.dispatch(fromCertificateActions.partnerChanged({ partner }));
	}

	public changePartnerBirthName(partnerBirthName: string) {
		this.store.dispatch(fromCertificateActions.partnerBirthNameChanged({ partnerBirthName }));
	}

	public changeGiftee(giftee: string) {
		this.store.dispatch(fromCertificateActions.gifteeChanged({ giftee }));
	}

	public changeGifteeBirthName(gifteeBirthName: string) {
		this.store.dispatch(fromCertificateActions.gifteeBirthNameChanged({ gifteeBirthName }));
	}

	public changeMessage(message: string) {
		this.store.dispatch(fromCertificateActions.messageChanged({ message }));
	}

	public changeCustomMessage(customMessage: string) {
		this.store.dispatch(fromCertificateActions.customMessageChanged({ customMessage }));
	}

	public changeIsCustomized(isCustomized: boolean) {
		this.store.dispatch(fromCertificateActions.isCustomizedChanged({ isCustomized }));
	}

	public changeOccasion(occasion: OccasionType) {
		this.store.dispatch(fromCertificateActions.occasionChanged({ occasion }));
	}

	public changeAddressForm(addressForm: AddressForm) {
		this.store.dispatch(fromCertificateActions.addressFormChanged({ addressForm }));
	}

	protected actions = fromCertificateActions;
}

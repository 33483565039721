import { Directive } from '@angular/core';
import { IArticle } from '../../models/article.model';
import { IEntityState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { EntityFacade } from '../../state/entity.facade';

@Directive()
export abstract class DetailDialogComponent<TArticle extends IArticle, TArticleState extends IEntityState<TArticle>> {
	constructor(private data: { article: TArticle }, public cartFacade: CartFacade, public articleFacade: EntityFacade<TArticle, TArticleState>) {}

	public get article(): TArticle {
		return this.data.article;
	}
}

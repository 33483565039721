import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IBook } from '../../models/article.model';
import { AppState, IBookState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { NewspaperSelector } from '../newspaper/newspaper.selectors';
import { fromBookActions } from './book.actions';
import { BookSelector } from './book.selectors';

@Injectable()
export class BookFacade extends EntityFacade<IBook, IBookState> {
	constructor(store: Store<AppState>, private newspaperSelector: NewspaperSelector, private bookSelector: BookSelector) {
		super(store, bookSelector);
	}

	protected actions = fromBookActions;
}

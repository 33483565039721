import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDvd } from '../../models/article.model';
import { AppState, IDvdState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDvdActions } from './dvd.actions';
import { DvdSelector } from './dvd.selectors';

@Injectable()
export class DvdFacade extends EntityFacade<IDvd, IDvdState> {
	constructor(store: Store<AppState>, dvdSelector: DvdSelector) {
		super(store, dvdSelector);
	}

	protected actions = fromDvdActions;
}

<h2 class="mat-h3" mat-dialog-title>{{ bundle.headline }}</h2>
<mat-dialog-content>
	<div class="text-preline" [innerHtml]="bundle.description | safe"></div>
	<shop-picture [image]="bundle.image"></shop-picture>
	<div class="text-preline" [innerHtml]="bundle.descriptionModal | safe"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="bundle-dialog--price-info">ab zzgl. {{ bundle.price | currency }}</div>
	<button mat-flat-button mat-dialog-close color="accent" (click)="configure()">
		<mat-icon svgIcon="mdi-pencil"></mat-icon>
		Jetzt auswählen
	</button>
</mat-dialog-actions>
<button mat-icon-button class="dialog-close">
	<mat-icon svgIcon="mdi-close" mat-dialog-close></mat-icon>
</button>

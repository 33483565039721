import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import moment from 'moment';
import { IConfiguration } from '../../models/configuration.model';
import { PaymentKind, ShippingKind } from '../../models/order.model';
import { AppState, IConfigurationState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable()
export class ConfigurationSelector extends EntitySelector<IConfiguration, IConfigurationState> {
	protected stateSelector = (state: AppState): IConfigurationState => state.configuration;

	public shippingInformation = createSelector(this.selectState, state => state.shippingInformation);
	public paymentInformation = createSelector(this.selectState, state => state.paymentInformation);
	public estimatedDeliveries = createSelector(this.selectState, state => state.estimatedDeliveries);
	public shippingType = (shippingKind: ShippingKind) => createSelector(this.shippingInformation, shippingInformation => shippingInformation?.shippingTypes.find(shippingType => shippingType.shippingKind === shippingKind));
	public paymentType = (paymentKind: PaymentKind) => createSelector(this.paymentInformation, paymentInformation => paymentInformation?.paymentTypes.find(paymentType => paymentType.paymentKind === paymentKind));
	public estimatedDelivery = (shippingKind: ShippingKind) => createSelector(this.estimatedDeliveries, estimatedDeliveryDates => estimatedDeliveryDates.find(x => x.shippingKind == shippingKind));
	public suggestShippingKind = (shippingKind: ShippingKind, referenceDate: Date) =>
		createSelector(this.estimatedDeliveries, this.estimatedDelivery(shippingKind), (estimatedDeliveries, estimatedDelivery) => {
			const reference = moment(referenceDate).startOf('day');

			if (reference.isSameOrAfter(estimatedDelivery.shippingRange.until)) {
				return shippingKind;
			}

			const orderedDeliveries = _.orderBy(estimatedDeliveries, x => x.shippingRange.from).reverse();

			for (const delivery of orderedDeliveries) {
				const shippingRange = moment(delivery.shippingRange.until).startOf('day');

				if (reference.isSameOrAfter(shippingRange)) {
					return delivery.shippingKind;
				}
			}

			return shippingKind;
		});
}

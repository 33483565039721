import { on } from '@ngrx/store';
import { IOrder } from '../../models/order.model';
import { EntityState, IOrderState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromOrderActions } from './order.actions';

export const initialState = { ...EntityState.create<IOrder, IOrderState>(), isLoading: true };
export const orderReducer = EntityReducer.create<IOrder, IOrderState>(
	initialState,
	fromOrderActions,
	null,
	on(fromOrderActions.createTransaction, state => ({ ...state, isLoading: true })),
	on(fromOrderActions.createdTransaction, (state, { order }) => ({ ...state, isLoading: true, list: [order], selected: order })),
	on(fromOrderActions.captureTransaction, state => ({ ...state, isLoading: true })),
	on(fromOrderActions.capturedTransaction, (state, { order }) => ({ ...state, isLoading: false, list: [order], selected: order })),
	on(fromOrderActions.abortTransaction, state => ({ ...state, isLoading: false }))
);

import { ICustomer } from '../state/app.state';
import { IArticle } from './article.model';
import { IContactInfo } from './contact-info.model';
import { IEntity } from './entity.model';
import { IPosition } from './position.model';

export interface IOrder extends IEntity {
	positions: IPosition[];
	primaryReceipt: IReceipt;
	secondaryReceipts: IReceipt[];
	number: number;
	hasPriority: boolean;
	shippings: IShipping[];
	customer: ICustomer;
}

export interface IReceipt {
	payments: IPayment[];
	number: number;
	biller: IContactInfo;
	receiver: IContactInfo;
	date: Date;
	receiptKind: ReceiptKind;
	totalPrice: number;
}

export enum ReceiptKind {
	Invoice = 'Rechnung',
	Correction = 'Korrektur',
	Cancellation = 'Stornierung',
}

export interface IPayment extends IEntity {
	dueDate: Date;
	paidDate?: Date;
	dueAmount: number;
	paymentKind: PaymentKind;
}

export interface IPaypalPayment extends IPayment {
	paypalOrder?: IPaypalOrder;
	paypalTransaction?: IPaypalTransaction;
}

export interface IPaypalOrder {
	id: string;
	link: string;
	payerId?: string;
}

export interface IPaypalTransaction {
	id: string;
	state: string;
	payer: IPayPalPayer;
}

export interface IPayPalPayer {
	id: string;
	email: string;
	name: string;
}

export enum PaymentKind {
	CashOnDelivery = 'Nachnahme',
	Cash = 'Bar bei Abholung',
	DirectDebit = 'Lastschrifteinzug',
	Paypal = 'Paypal',
	Prepayment = 'Vorkasse',
	PurchaseOnAccount = 'Rechnungskauf',
	Barzahlen = 'Barzahlen',
}

export interface IShipping {
	articles: IArticle[];
	shippingKind: ShippingKind;
	deliveryNote: IDeliveryNote;
	shippingWithoutInvoice: boolean;
	consentEmailTransfer: boolean;
}

export interface IDeliveryNote {
	receiver: IContactInfo;
}

export enum ShippingKind {
	Dhl = 'DHL',
	DhlPremium = 'DHL Premium',
	DhlExpress = 'DHL Express',
	Pickup = 'Abholung',
}

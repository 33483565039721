import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IMagazine } from '../../models/article.model';
import { AppState, IMagazineState } from '../app.state';
import { MediaArticleSelector } from '../entity.selector';

@Injectable()
export class MagazineSelector extends MediaArticleSelector<IMagazine, IMagazineState> {
	protected stateSelector = (state: AppState): IMagazineState => state.magazine;

	public isExtending = createSelector(this.selectState, state => state.isExtending);
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import slugify from 'slugify';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, IMagazine } from '../../models/article.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { MagazineFacade } from '../../state/magazine/magazine.facade';
import { PageFacade } from '../../state/page/page.facade';

@Component({
	selector: 'shop-searchresult-magazine',
	templateUrl: './searchresult-magazine.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultMagazineComponent {
	@Output()
	public selectedMagazine = new EventEmitter<IMagazine>();
	public ArticleKinds = ArticleKind;

	constructor(private googleService: GoogleService, public magazineFacade: MagazineFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade) {}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(magazine: IMagazine) {
		this.magazineFacade.select(magazine);
		this.selectedMagazine.emit(magazine);
		this.googleService.selectItem(magazine);
	}

	public slugify(value: string): string {
		return value ? slugify(value, { lower: true }) : null;
	}
}

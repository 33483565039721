<section class="page-top">
	<div class="container">
		<div class="intro">
			<div class="intro--image">
				<shop-picture [image]="imageFacade.loadSingleById(params.image) | async" [width]="684" [height]="417" [loading]="'eager'" [aspectRatio]="'684 / 417'"></shop-picture>
				<small>Preisbeispiel: BILD vom 29.10.1968 (Original) + Geschenkmappe V.I.P. mit persönlicher Geschenkurkunde = 49,85&thinsp;€</small>
			</div>
			<h1 class="mat-h1 intro--headline" [innerHtml]="params.headline | safe"></h1>
			<div class="intro--text">
				<p [innerHtml]="params.text | safe"></p>
			</div>
			<div class="intro--form">
				<form>
					<shop-date-input></shop-date-input>
					<button type="button" mat-flat-button color="accent" (click)="newspaperFacade.load()" routerLink="/suche">
						<mat-icon svgIcon="mdi-magnify"></mat-icon>
						Zeitungen finden
					</button>
				</form>
				<small class="intro--form__small text-center"><b>Original-Zeitung ab 39,90&thinsp;€</b> (Preis variiert nach Datum und Titel)</small>
			</div>
		</div>
	</div>
</section>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationFacade } from 'src/state/configuration/configuration.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { ShippingKindDialogComponent } from '../shippingkind-dialog/shippingkind-dialog.component';

@Component({
	selector: 'shop-shipping-alert',
	templateUrl: './shipping-alert.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingAlertComponent {
	constructor(public newspaperFacade: NewspaperFacade, public configurationFacade: ConfigurationFacade, public cartFacade: CartFacade, private dialog: MatDialog) {}

	public openShippingKindDialog(): void {
		this.dialog.open(ShippingKindDialogComponent);
	}
}

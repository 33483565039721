import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../models/address.model';

@Pipe({
	name: 'address',
})
export class AddressPipe implements PipeTransform {
	transform(address: IAddress): string {
		const postCodeAndCity = `${address.postCode || ''} ${address.city || ''}`.trim();
		const streetAndStreetNumber = `${address.street || ''} ${address.streetNumber || ''}`.trim();
		const addressComponents = [streetAndStreetNumber, postCodeAndCity, address.country].filter(x => x);

		return addressComponents.join(', ');
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { ExternalLibrariesSelector } from './externallibraries.selectors';
import { fromExternalLibrariesActions } from './externallibraries.actions';

@Injectable()
export class ExternalLibrariesFacade extends BaseFacade {
	constructor(store: Store<AppState>, private externalLibrariesSelector: ExternalLibrariesSelector) {
		super(store);
	}
	public isShopvoteReputationLoaded$ = this.createBehaviourSubject(this.store.select(this.externalLibrariesSelector.isShopvoteReputationLoaded), false);
	public isShopvoteReviewsLoaded$ = this.createBehaviourSubject(this.store.select(this.externalLibrariesSelector.isShopvoteReviewsLoaded), false);
	public isYoutubeLoaded$ = this.createBehaviourSubject(this.store.select(this.externalLibrariesSelector.isYoutubeLoaded), false);

	public loadShopvote(): void {
		this.store.dispatch(fromExternalLibrariesActions.shopvoteReputationLoad());
		this.store.dispatch(fromExternalLibrariesActions.shopvoteReviewsLoad());
	}

	public loadYoutube(): void {
		this.store.dispatch(fromExternalLibrariesActions.youtubeLoad());
	}
}

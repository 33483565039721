import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { IYearBook } from '../../models/article.model';
import { IYearBookState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { YearBookFacade } from '../../state/year-book/year-book.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-yearbook-detaildialog',
	templateUrl: 'yearbook-detaildialog.component.html',
	animations: [crossFade],
})
export class YearBookDetailDialogComponent extends DetailDialogComponent<IYearBook, IYearBookState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: IYearBook }, cartFacade: CartFacade, yearbookFacade: YearBookFacade) {
		super(data, cartFacade, yearbookFacade);
	}
}

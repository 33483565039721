import { createReducer, on } from '@ngrx/store';
import { IAddressState } from '../app.state';
import { fromAddressActions } from './address.actions';

const initialState: IAddressState = {
	isSuggesting: false,
	error: null,
	suggestedPayerList: [],
	suggestedReceiverList: [],
	paging: {
		skip: 0,
		top: 5,
	},
};

export const addressReducer = createReducer(
	initialState,
	on(fromAddressActions.suggestPayer, state => ({ ...state, isSuggesting: true, error: null })),
	on(fromAddressActions.suggestedPayer, (state, { suggestedList }) => ({ ...state, isSuggesting: false, error: null, suggestedPayerList: suggestedList })),
	on(fromAddressActions.suggestReceiver, state => ({ ...state, isSuggesting: true, error: null })),
	on(fromAddressActions.suggestedReceiver, (state, { suggestedList }) => ({ ...state, isSuggesting: false, error: null, suggestedReceiverList: suggestedList })),
	on(fromAddressActions.failed, (state, { message }) => ({ ...state, isSuggesting: false, error: message, suggestedPayerList: [], suggestedReceiverList: [] })),
	on(fromAddressActions.cleared, state => ({ ...state, isSuggesting: false, error: null, suggestedPayerList: [], suggestedReceiverList: [] }))
);

<ng-container *ngIf="!(cdFacade.isLoading$ | async) && !(cdFacade.list$ | async).length"> </ng-container>

<ng-container *ngIf="cdFacade.isLoading$ | async">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>CDs</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(cdFacade.isLoading$ | async) && (cdFacade.list$ | async).length">
	<div class="search-results" [@crossFade]>
		<div class="search-results--tables">
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>CDs</strong></p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let cd of cdFacade.list$ | async; trackBy: articleById" class="search-results--table__row">
						<div class="search-results--table__column search-results--table__column-image">
							<shop-picture *ngIf="cd.product.image != null" [image]="cd.product.image" [width]="130" [height]="130" (click)="onSelect(cd)"></shop-picture>
							<shop-picture *ngIf="cd.product.image == null && cd.product.mediaBrand.image != null" [image]="cd.product.mediaBrand.image" [width]="130" [height]="130" (click)="onSelect(cd)"></shop-picture>
							<shop-picture
								*ngIf="cd.product.image == null && cd.product.mediaBrand.image == null"
								[image]="imageFacade.loadSingleById('62d43f5ad17e089e55373f04') | async"
								[width]="130"
								[height]="130"
								(click)="onSelect(cd)"></shop-picture>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<shop-picture *ngIf="cd.product.logo != null" [image]="cd.product.logo" [width]="200" [height]="40"></shop-picture>
							<shop-picture *ngIf="cd.product.logo == null && cd.product.mediaBrand.logo != null" [image]="cd.product.mediaBrand.logo" [width]="200" [height]="40"></shop-picture>
							<div>
								<b>{{ cd.product.name }}</b>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div *ngIf="cd.product.shortDescription">{{ cd?.product?.shortDescription }}</div>
							<div *ngIf="cd.product.shortDescription == null && cd.product.mediaBrand.shortDescription">{{ cd?.product?.mediaBrand.shortDescription }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="availability"><b>&bull;</b> verfügbar</div>
							<div class="price">{{ cd.product.price | currency: 'EUR' }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<button mat-flat-button color="accent" (click)="onSelect(cd)">
								<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
								<span>Auswählen</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

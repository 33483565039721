import { IYearBook } from '../../models/article.model';
import { IYearBookState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromYearBookActions } from './year-book.actions';

export const initialState = MediaArticleState.create<IYearBook, IYearBookState>(
	{
		skip: 0,
		top: 10,
	},
	{
		current: 3650,
		min: 3650,
		max: 3650,
	}
);

export const yearBookReducer = EntityReducer.create<IYearBook, IYearBookState>(initialState, fromYearBookActions);

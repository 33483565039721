import { ICd } from '../../models/article.model';
import { ICdState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCdActions } from './cd.actions';

export const initialState = MediaArticleState.create<ICd, ICdState>(
	{
		skip: 0,
		top: 10,
	},
	{
		current: 3650,
		min: 3650,
		max: 3650,
	}
);

export const cdReducer = EntityReducer.create<ICd, ICdState>(initialState, fromCdActions);

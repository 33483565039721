import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'shop-anniversary-header',
	templateUrl: './anniversary-header.component.html',
	styleUrls: ['./anniversary-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnniversaryHeaderComponent implements OnDestroy {
	private destroyed = new Subject<void>();

	constructor(private calendar: MatCalendar<moment.Moment>, private dateAdapter: DateAdapter<moment.Moment>, @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, changeDetectorRef: ChangeDetectorRef) {
		calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => changeDetectorRef.markForCheck());
	}

	public ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.complete();
	}

	public get periodLabel(): string {
		return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
	}

	public previousClicked(mode: 'decade' | 'year'): void {
		this.calendar.activeDate = mode === 'decade' ? this.dateAdapter.addCalendarYears(this.calendar.activeDate, -10) : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
	}

	public nextClicked(mode: 'decade' | 'year'): void {
		this.calendar.activeDate = mode === 'decade' ? this.dateAdapter.addCalendarYears(this.calendar.activeDate, 10) : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
	}

	public goTo(years: number): void {
		this.calendar.activeDate = this.dateAdapter.addCalendarYears(moment(this.calendar.activeDate).year(moment().year()), -years);
		this.calendar.currentView = 'year';
	}
}

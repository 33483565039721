import { IEnvironment } from 'src/models/environment.model';

export const environment: IEnvironment = {
	hostname: 'https://test.geschenkzeitung.de',
	production: false,
	serviceWorker: true,
	googleMapsApiKey: 'XXX',
	googleTagManagerId: 'GTM-52Z74GP',
	shopvoteToken: 'XXX',
	shopvoteShopId: 17463,
	ipInfoToken: '00bae086e72ac2',
	serverUrl: 'https://api.test.geschenkzeitung.de',
	cloudImageToken: 'aookdwenar',
	serverPort: 443,
	apiUrl: 'api/v1',
	projectId: 'pr45',
};

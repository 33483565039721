import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IHeroTeaser } from 'src/models/hero-teaser.model';
import { AppState, IHeroTeaserState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromHeroTeaserActions } from './hero-teaser.actions';
import { HeroTeaserSelector } from './hero-teaser.selectors';

@Injectable()
export class HeroTeaserFacade extends EntityFacade<IHeroTeaser, IHeroTeaserState> {
	constructor(store: Store<AppState>, private heroTeaserSelector: HeroTeaserSelector) {
		super(store, heroTeaserSelector);
	}

	protected actions = fromHeroTeaserActions;
}

<ng-container *ngIf="(magazineFacade.isLoading$ | async) || ((magazineFacade.list$ | async).length == 0 && (magazineFacade.isExtending$ | async))">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Zeitschriften</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(magazineFacade.isLoading$ | async) && (magazineFacade.list$ | async).length">
	<ng-container *ngIf="magazineFacade.ratings$ | async as ratings">
		<div class="search-results" [@crossFade]>
			<div class="search-results--tables">
				<div class="search-results--table">
					<div class="search-results--table__header">
						<p><strong>Zeitschriften</strong></p>
					</div>
					<mat-card class="mat-elevation-z1">
						<div *ngFor="let magazine of magazineFacade.list$ | async; trackBy: articleById" class="search-results--table__row">
							<div class="search-results--table__column search-results--table__column-image">
								<shop-picture *ngIf="magazine.product.image != null" [image]="magazine.product.image" [width]="130" [height]="130" (click)="onSelect(magazine)"></shop-picture>
								<shop-picture
									*ngIf="magazine.product.image == null && magazine.product.mediaBrand.image != null"
									[image]="magazine.product.mediaBrand.image"
									[width]="130"
									[height]="130"
									(click)="onSelect(magazine)"></shop-picture>
								<shop-picture
									*ngIf="magazine.product.image == null && magazine.product.mediaBrand.image == null"
									[image]="imageFacade.loadSingleById('62d43f5ad17e089e55372fe8') | async"
									[width]="130"
									[height]="130"
									(click)="onSelect(magazine)"></shop-picture>
							</div>
							<div class="search-results--table__column search-results--table__column-logo">
								<shop-picture *ngIf="magazine.product.logo != null" [image]="magazine.product.logo" [width]="200" [height]="40"></shop-picture>
								<shop-picture *ngIf="magazine.product.logo == null && magazine.product.mediaBrand.logo != null" [image]="magazine.product.mediaBrand.logo" [width]="200" [height]="40"></shop-picture>
								<div>
									<b>{{ magazine.product.name }}</b> vom
									<span class="{{ ratings[magazine._id] != null ? (ratings[magazine._id].isPerfectMatch ? 'text-success' : 'text-warning text-bold') : 'text-bold' }}">
										{{ magazine.publicationDate | amLocal | amDateFormat: 'dddd, DD.MM.YYYY' }}
									</span>
								</div>
								<div *ngIf="magazine.product.publicationCycle" class="search-results--table__column-publication-cycle">Erscheint {{ magazine.product.publicationCycle.name }}</div>
							</div>
							<div class="search-results--table__column search-results--table__column-description">
								<div *ngIf="magazine.product.shortDescription">{{ magazine?.product?.shortDescription }}</div>
								<div *ngIf="magazine.product.shortDescription == null && magazine.product.mediaBrand.shortDescription">{{ magazine?.product?.mediaBrand.shortDescription }}</div>
							</div>
							<div class="search-results--table__column search-results--table__column-region">
								<div class="location">{{ magazine?.product?.productionRegion?.name }}</div>
								<div class="map map-{{ slugify(magazine?.product?.graphicalRegion?.name) }}"></div>
								<div class="region">{{ magazine?.product?.graphicalRegion.name }}</div>
							</div>
							<div class="search-results--table__column search-results--table__column-price">
								<div class="availability"><b>&bull;</b> verfügbar</div>
								<ng-container *ngIf="pageFacade.isConfig$ | async; else noConfig">
									<ng-container *ngIf="bundleFacade.getDiscount(ArticleKinds.Magazine, magazine.product.price) | async as discount; else noReducedPrice">
										<div class="price price--strikethrough">{{ magazine.product.price | currency: 'EUR' }}</div>
										<div class="price">{{ discount.price | currency: 'EUR' }}</div>
									</ng-container>
									<ng-template #noReducedPrice>
										<div class="price">{{ magazine.product.price | currency: 'EUR' }}</div>
									</ng-template>
								</ng-container>
								<ng-template #noConfig>
									<div class="price">{{ magazine.product.price | currency: 'EUR' }}</div>
								</ng-template>
							</div>
							<div class="search-results--table__column search-results--table__column-button">
								<button mat-flat-button color="accent" (click)="onSelect(magazine)">
									<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
									<span>Auswählen</span>
								</button>
							</div>
						</div>
						<div *ngIf="(magazineFacade.list$ | async).length > 0 && (magazineFacade.isExtending$ | async)" class="more-results more-results__spinner">
							<mat-spinner [diameter]="25"></mat-spinner> Weitere Zeitschriften werden gesucht...
						</div>
						<div *ngIf="!(magazineFacade.isExtending$ | async)" class="more-results">
							<a (click)="magazineFacade.extend()">Weitere Zeitschriften finden</a>
						</div>
					</mat-card>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

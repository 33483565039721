import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { bufferTime, catchError, filter, map, mergeMap, of, switchMap } from 'rxjs';
import { IConfiguration } from '../../models/configuration.model';
import { ConfigurationApiService } from '../../services/api/configuration.service';
import { IErrorResult } from '../../services/dto.service';
import { AppState, IConfigurationState } from '../app.state';
import { CartSelector } from '../cart/cart.selectors';
import { EntityEffects } from '../entity.effects';
import { RouterSelector } from '../router/router.selectors';
import { fromConfigurationActions } from './configuration.actions';
import { ConfigurationSelector } from './configuration.selectors';

@Injectable()
export class ConfigurationEffects extends EntityEffects<IConfiguration, IConfigurationState> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private configurationSelector: ConfigurationSelector,
		private cartSelector: CartSelector,
		routerSelector: RouterSelector,
		private configurationService: ConfigurationApiService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		super(actions$, store, null, configurationSelector, routerSelector, fromConfigurationActions);
	}

	public onLoadShippingInformation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromConfigurationActions.loadShippingInformation),
			concatLatestFrom(() => this.store.select(this.configurationSelector.shippingInformation)),
			switchMap(() =>
				this.configurationService.getShippingInformation().pipe(
					map(shippingInformation => fromConfigurationActions.loadedShippingInformation({ shippingInformation })),
					catchError((response: IErrorResult) => of(fromConfigurationActions.failed({ message: response.error?.error })))
				)
			)
		)
	);

	public onLoadPaymentInformation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromConfigurationActions.loadPaymentInformation),
			concatLatestFrom(() => this.store.select(this.configurationSelector.paymentInformation)),
			switchMap(() =>
				this.configurationService.getPaymentInformation().pipe(
					map(paymentInformation => fromConfigurationActions.loadedPaymentInformation({ paymentInformation })),
					catchError((response: IErrorResult) => of(fromConfigurationActions.failed({ message: response.error?.error })))
				)
			)
		)
	);

	public onLoadEstimatedDeliveryDates$ = isPlatformBrowser(this.platformId)
		? createEffect(() =>
				this.actions$.pipe(
					ofType(fromConfigurationActions.loadEstimatedDelivery),
					map(({ shippingKind }) => shippingKind),
					bufferTime(500),
					filter(shippingKinds => shippingKinds.length > 0),
					concatLatestFrom(() => this.store.select(this.configurationSelector.estimatedDeliveries)),
					concatLatestFrom(() => this.store.select(this.cartSelector.enableSaturdayDelivery)),
					mergeMap(([[shippingKinds], enableSaturdayDelivery]) =>
						this.configurationService.getEstimatedDeliveryDates(shippingKinds, enableSaturdayDelivery).pipe(
							map(shippingRanges =>
								fromConfigurationActions.loadedEstimatedDeliveries({
									estimatedDeliveries: shippingRanges.map(shippingRange => ({
										shippingKind: shippingRange.shippingKind,
										shippingRange,
									})),
								})
							),
							catchError((response: IErrorResult) => of(fromConfigurationActions.failed({ message: response.error?.error })))
						)
					)
				)
		  )
		: null;
}

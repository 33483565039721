import { createAction, props } from '@ngrx/store';
import { IOrder } from '../../models/order.model';
import { EntityActions } from '../entity.actions';

const stateName = '[Order]';
const createTransaction = createAction(`${stateName} Create Transaction`, props<{ returnUrl: string; cancelUrl: string }>());
const createdTransaction = createAction(`${stateName} Created Transaction`, props<{ order: IOrder }>());
const captureTransaction = createAction(`${stateName} Capture Transaction`);
const capturedTransaction = createAction(`${stateName} Captured Transaction`, props<{ order: IOrder }>());
const abortTransaction = createAction(`${stateName} Abort Transaction`);

export const fromOrderActions = {
	...EntityActions.create<IOrder>(stateName),
	createTransaction,
	createdTransaction,
	captureTransaction,
	capturedTransaction,
	abortTransaction,
};

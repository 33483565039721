import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { IEntity } from 'src/models/entity.model';
import { IArticle, IMediaArticle } from '../models/article.model';
import { AppState, IArticleState, IEntityState, IMediaArticleState } from './app.state';
import { BaseSelector } from './base.selector';

@Injectable()
export abstract class EntitySelector<TEntity extends IEntity, TEntityState extends IEntityState<TEntity>> extends BaseSelector<TEntityState> {
	protected abstract stateSelector(state: AppState): TEntityState;

	public hasItem = (id: string) => createSelector(this.items, items => items[id] != undefined);
	public hasItems = (ids: string[]) => createSelector(this.items, items => ids.map(id => items[id] != undefined));
	public getItem = (id: string) => createSelector(this.items, items => items[id]);
	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public error = createSelector(this.selectState, state => state.error);
	public hasError = createSelector(this.error, error => error != null);
	public items = createSelector(this.selectState, state => state.items);
	public ratings = createSelector(this.selectState, state => state.ratings);
	public list = createSelector(this.items, items => _.values(items));
	public selected = createSelector(this.selectState, state => state.selected);
}

@Injectable()
export abstract class ArticleSelector<TArticle extends IArticle, TArticleState extends IArticleState<TArticle>> extends EntitySelector<TArticle, TArticleState> {
	protected abstract stateSelector(state: AppState): TArticleState;

	public paging = createSelector(this.selectState, state => state.paging);
}

@Injectable()
export abstract class MediaArticleSelector<TMediaArticle extends IMediaArticle, TMediaArticleState extends IMediaArticleState<TMediaArticle>> extends ArticleSelector<TMediaArticle, TMediaArticleState> {
	protected abstract stateSelector(state: AppState): TMediaArticleState;

	public dayRange = createSelector(this.selectState, state => state.dayRange);
}

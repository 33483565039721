import { IAccessoryProduct } from './accessory-product.model';
import { IEntity } from './entity.model';
import { IMediaProduct } from './media-product.model';
import { AddressForm, IProduct, OccasionType } from './product.model';
import { IRating } from './rating.model';
import { IRegion } from './region.model';

export interface IArticle extends IEntity {
	articleKind: ArticleKind;
	product: IProduct;
}

export interface IAccessoryArticle extends IArticle {
	product: IAccessoryProduct;
}

export interface IMediaArticle extends IArticle {
	publicationDate: Date;
	product: IMediaProduct;
}

export interface INewspaper extends IMediaArticle {}

export interface IMagazine extends IMediaArticle {}

export interface IFolder extends IAccessoryArticle {}

export interface ICoin extends IMediaArticle {}

export interface IBook extends IMediaArticle {}

export interface IYearBook extends IMediaArticle {}

export interface IBirthdayBook extends IMediaArticle {}

export interface IBirthdayChronicle extends IMediaArticle {}

export interface ICd extends IMediaArticle {}

export interface IDvd extends IMediaArticle {}

export interface IChronicle extends IMediaArticle {}

export interface IStick extends IAccessoryArticle {}

export interface ICertificate extends IAccessoryArticle {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressForm;
	date: Date;
	partner: string;
	partnerBirthName: string;
	giftee: string;
	gifteeBirthName: string;
	isCustomized: boolean;
	text: string;
	message: string;
}

export interface IArticleRating<TArticle extends IArticle> extends IRating, IEntity {
	article: TArticle;
	score: number;
	isPerfectMatch: boolean;
}

export interface IRatingExtract {
	articleId: string;
	score: number;
	isPerfectMatch: boolean;
}

export interface INewspaperRating extends IArticleRating<INewspaper> {}

export interface IMagazineRating extends IArticleRating<IMagazine> {}

export interface IFolderRating extends IArticleRating<IFolder> {}

export interface ICertificateRating extends IArticleRating<ICertificate> {}

export interface ICoinRating extends IArticleRating<ICoin> {}

export interface IBookRating extends IArticleRating<IBook> {}

export interface IYearBookRating extends IArticleRating<IYearBook> {}

export interface IBirthdayBookRating extends IArticleRating<IBirthdayBook> {}

export interface ICdRating extends IArticleRating<ICd> {}

export interface IDvdRating extends IArticleRating<IDvd> {}

export interface IChronicleRating extends IArticleRating<IChronicle> {}

export interface IBirthdayChronicleRating extends IArticleRating<IBirthdayChronicle> {}

export interface IStickRating extends IArticleRating<IStick> {}

export enum ArticleKind {
	BirthdayBook = 'Persönliches Geburtstagsbuch',
	BirthdayChronicle = 'Persönliche Geburtstags-Chronik',
	Book = 'Antiquarisches Buch',
	Coin = 'Historisches Zahlungsmittel',
	Cd = 'CD',
	Dvd = 'DVD',
	Folder = 'Mappe',
	Chronicle = 'Jahrgangs-Chronik',
	Certificate = 'Geschenkurkunde',
	Magazine = 'Magazin',
	Newspaper = 'Zeitung',
	Stick = 'Zeitungshalter',
	YearBook = 'Geschenkbuch',
}

export interface IArticleRequest {
	articleKind: ArticleKind;
	articleId?: IArticle['_id'];
}

export interface IAccessoryArticleRequest extends IArticleRequest {}

export interface IMediaArticleRequest extends IArticleRequest {
	date?: Date;
	dateFrom?: Date;
	dateUntil?: Date;
	quality?: number;
	region?: IRegion;
}

export interface IPaging {
	skip: number;
	top: number;
}

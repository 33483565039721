import { Pipe, PipeTransform } from '@angular/core';
import _, { List, Many } from 'lodash';

@Pipe({
	name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
	transform(collection: List<any> | null | undefined, iteratees?: Many<string>, orders?: Many<boolean | 'asc' | 'desc'>): any[] {
		return _.orderBy(collection, iteratees, orders);
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IEntity } from 'src/models/entity.model';
import { AppState, IEntityState } from './app.state';
import { BaseFacade } from './base.facade';
import { IEntityActions } from './entity.actions';
import { EntitySelector } from './entity.selector';

@Injectable()
export abstract class EntityFacade<TEntity extends IEntity, TEntityState extends IEntityState<TEntity>> extends BaseFacade {
	protected abstract actions: IEntityActions<TEntity>;

	constructor(protected store: Store<AppState>, protected entitySelector: EntitySelector<TEntity, TEntityState>) {
		super(store);
	}

	public items$ = this.createBehaviourSubject(this.store.select(this.entitySelector.items), {});
	public list$ = this.store.select(this.entitySelector.list);
	public ratings$ = this.store.select(this.entitySelector.ratings);
	public selected$ = this.createBehaviourSubject(this.store.select(this.entitySelector.selected));
	public isLoading$ = this.store.select(this.entitySelector.isLoading);
	public error$ = this.store.select(this.entitySelector.error);
	public hasError$ = this.store.select(this.entitySelector.hasError);

	public getItem(id: string): Observable<TEntity> {
		return this.store.select(this.entitySelector.getItem(id));
	}

	public hasItem(id: string): Observable<boolean> {
		return this.store.select(this.entitySelector.hasItem(id));
	}

	public load(): void {
		this.store.dispatch(this.actions.load());
	}

	public select(entity: TEntity): void {
		this.store.dispatch(this.actions.selected({ entity }));
	}

	public unselect(): void {
		this.store.dispatch(this.actions.selected({ entity: null }));
	}
}

import { on } from '@ngrx/store';
import { IFaqGroup } from 'src/models/faq.model';
import { EntityState, IFaqState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromFaqActions } from './faq.actions';

export const initialState = EntityState.create<IFaqGroup, IFaqState>();
export const faqReducer = EntityReducer.create<IFaqGroup, IFaqState>(
	initialState,
	fromFaqActions,
	null,
	on(fromFaqActions.loaded, (state, { list }) => ({
		...state,
		items: list.reduce((obj, group) => ({ ...obj, [group.headline]: group }), {}),
		isLoading: false,
		error: null,
	}))
);

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartFacade } from '../../state/cart/cart.facade';

@Component({
	selector: 'shop-cartunavilability-alert',
	templateUrl: './cartunavilability-alert.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartUnavailabilityAlertComponent {
	constructor(public cartFacade: CartFacade) {}
}

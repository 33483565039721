import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfigurationFacade } from 'src/state/configuration/configuration.facade';
import { ShippingKind } from '../../models/order.model';

@Component({
	selector: 'shop-shipping-alert-static',
	templateUrl: './shipping-alert-static.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingAlertStaticComponent {
	public ShippingKinds = ShippingKind;
	@Input() public showDhl: boolean = false;

	constructor(public configurationFacade: ConfigurationFacade) {}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromPresentationActions } from './presentation.actions';
import { PresentationSelector } from './presentation.selectors';

@Injectable()
export class PresentationFacade extends BaseFacade {
	constructor(store: Store<AppState>, private presentationSelector: PresentationSelector) {
		super(store);
	}

	public pageStyle$ = this.store.select(this.presentationSelector.pageStyle);
	public headerStyle$ = this.store.select(this.presentationSelector.headerStyle);
	public scrollTop$ = this.createBehaviourSubject(this.store.select(this.presentationSelector.scrollTop), 0);
	public testingAcknowledgedAt$ = this.store.select(this.presentationSelector.testingAcknowledgedAt);

	public changeScrollTop(scrollTop: number = 0): void {
		if (this.scrollTop$.value !== scrollTop) {
			this.store.dispatch(fromPresentationActions.scrollTopChanged({ scrollTop }));
		}
	}

	public acknowledgeTesting(): void {
		this.store.dispatch(fromPresentationActions.acknowledgedTesting());
	}
}

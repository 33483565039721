import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { LayoutService } from 'src/services/utility/layout.service';
import { UpdateService } from 'src/services/utility/update.service';
import { PageFacade } from 'src/state/page/page.facade';
import { PresentationFacade } from 'src/state/presentation/presentation.facade';
import { RouterFacade } from 'src/state/router/router.facade';
import { environment } from '../../environments/environment';
import { IGeschenkzeitungWindow, Vendors, WindowRefService } from '../../services/utility/window.service';
import { CartFacade } from '../../state/cart/cart.facade';
import { BaseComponent } from '../base.component';
import { ExternalLibrariesFacade } from 'src/state/externallibraries/externallibraries.facade';

@Component({
	selector: 'shop-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent extends BaseComponent implements AfterViewInit {
	@ViewChild(MatDrawer)
	public drawer: MatDrawer;
	@ViewChild(MatDrawerContent)
	public content: MatDrawerContent;
	public canonicalElement: HTMLLinkElement;

	constructor(
		public dialog: MatDialog,
		public metaService: Meta,
		public titleService: Title,
		public pageFacade: PageFacade,
		public presentationFacade: PresentationFacade,
		public cartFacade: CartFacade,
		public routerFacade: RouterFacade,
		public externalLibrariesFacade: ExternalLibrariesFacade,
		private updateService: UpdateService,
		private templateService: LayoutService,
		private router: Router,
		private windowService: WindowRefService,
		@Inject(DOCUMENT) document: Document,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		super();
		this.canonicalElement = document.getElementById('canonical') as HTMLLinkElement;
		this.updateService.checkForUpdates();

		this.pageFacade.selected$
			.pipe(
				filter(page => page != null),
				map(page => {
					this.titleService.setTitle(page.seo.title);
					this.metaService.updateTag({ name: 'description', content: page.seo.description });
					this.canonicalElement.href = `${environment.hostname}${page.url}`;
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.windowService.onConsentLoaded(() => {
			if (this.windowService.hasVendorConsent(Vendors.Shopvote)) {
				this.externalLibrariesFacade.loadShopvote();
			}
			if (this.windowService.hasVendorConsent(Vendors.Youtube)) {
				this.externalLibrariesFacade.loadYoutube();
			}
		});
		this.windowService.onAddEventListener(() => window.location.reload());

		this.templateService.onCloseDrawer(() => this.drawer?.close());

		this.router.events.pipe(filter(routerEvent => routerEvent instanceof NavigationEnd && !isPlatformServer(this.platformId))).subscribe(() => this.content.getElementRef().nativeElement.scrollTo(0, 0));
	}

	public get now(): moment.Moment {
		return moment();
	}

	@HostListener('window:scroll', ['$event'])
	public onScroll($event: any): void {
		this.presentationFacade.changeScrollTop($event.target.scrollingElement.scrollTop);
	}

	public showScreenAdvanced() {
		this.windowService.nativeWindow.__cmp('showScreenAdvanced');
	}

	private isMobile(): boolean {
		return this.windowService.nativeWindow.matchMedia('only screen and (max-width: 760px)').matches;
	}
}

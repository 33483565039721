import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { ShopModule } from './modules/shop.module';
import { Vendors } from './services/utility/window.service';

if (environment.production) {
	enableProdMode();
}

function bootstrap() {
	addGoogleTagManagerToDOM();
	platformBrowserDynamic()
		.bootstrapModule(ShopModule)
		.then(moduleRef => {
			const applicationRef = moduleRef.injector.get(ApplicationRef);
			const componentRef = applicationRef.components[0];

			if (!environment.production) {
				enableDebugTools(componentRef);
			}
		})
		.catch(err => console.error(err));
}

if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}

function addGoogleTagManagerToDOM(): void {
	const script = document.createElement('script');
	script.setAttribute('data-cmp-vendor', Vendors.GoogleTagManager);
	script.type = 'text/plain';
	script.innerHTML =
		"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
		environment.googleTagManagerId +
		"'); ";
	const head = document.getElementsByTagName('head')[0];
	if (head != null) {
		document.head.appendChild(script);
	}
}

<ng-container *ngIf="(newspaperFacade.isLoading$ | async) || ((newspaperFacade.list$ | async).length == 0 && (newspaperFacade.isExtending$ | async))">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Zeitungen</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(newspaperFacade.isLoading$ | async) && (newspaperFacade.list$ | async).length">
	<div class="search-results" [@crossFade]>
		<div class="search-results--tables">
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Zeitungen</strong></p>
				</div>
				<mat-card class="mat-elevation-z1">
					<ng-container *ngFor="let newspaper of newspaperFacade.list$ | async; trackBy: articleById">
						<div *ngIf="doesNotContainRegion(newspaper)" class="search-results--table__row search-results--table__row-insert">Wir haben folgende überregionale und weitere Ergebnisse zu deiner Suche gefunden:</div>
						<div class="search-results--table__row">
							<div class="search-results--table__column search-results--table__column-image">
								<shop-picture *ngIf="newspaper.product.image != null" [image]="newspaper.product.image" [width]="130" [height]="130" (click)="onSelect(newspaper)"></shop-picture>
								<shop-picture *ngIf="newspaper.product.image == null && newspaper.product.mediaBrand.image != null" [image]="newspaper.product.mediaBrand.image" [width]="130" [height]="130" (click)="onSelect(newspaper)">
								</shop-picture>
								<shop-picture
									*ngIf="newspaper.product.image == null && newspaper.product.mediaBrand.image == null"
									[image]="imageFacade.loadSingleById('62d43f5ad17e089e55372fe7') | async"
									[width]="130"
									[height]="130"
									(click)="onSelect(newspaper)">
								</shop-picture>
							</div>
							<div class="search-results--table__column search-results--table__column-logo">
								<shop-picture *ngIf="newspaper.product.logo != null" [image]="newspaper.product.logo" [width]="200" [height]="40"></shop-picture>
								<shop-picture *ngIf="newspaper.product.logo == null && newspaper.product.mediaBrand.logo != null" [image]="newspaper.product.mediaBrand.logo" [width]="200" [height]="40"></shop-picture>
								<div>
									<b>{{ newspaper.product.name }}</b> vom
									<span class="{{ (newspaperFacade.isSearchDate(newspaper.publicationDate) | async) ? 'text-success' : 'text-warning text-bold' }}">
										{{ newspaper.publicationDate | amLocal | amDateFormat: 'dddd, DD.MM.YYYY' }}
									</span>
								</div>
							</div>
							<div class="search-results--table__column search-results--table__column-description">
								<div *ngIf="newspaper.product.shortDescription">{{ newspaper?.product?.shortDescription }}</div>
								<div *ngIf="newspaper.product.shortDescription == null && newspaper.product.mediaBrand.shortDescription">{{ newspaper?.product?.mediaBrand.shortDescription }}</div>
							</div>
							<div class="search-results--table__column search-results--table__column-region">
								<div class="location">{{ newspaper?.product?.productionRegion?.name }}</div>
								<div class="map map-{{ slugify(newspaper?.product?.graphicalRegion?.name) }}"></div>
								<div class="region">{{ newspaper?.product?.graphicalRegion.name }}</div>
							</div>
							<div class="search-results--table__column search-results--table__column-price">
								<div class="availability"><b>&bull;</b> verfügbar</div>
								<ng-container *ngIf="pageFacade.isConfig$ | async; else noConfig">
									<ng-container *ngIf="bundleFacade.getDiscount(ArticleKinds.Newspaper, newspaper.product.price) | async as discount; else noReducedPrice">
										<div class="price price--strikethrough">{{ newspaper.product.price | currency: 'EUR' }}</div>
										<div class="price">{{ discount.price | currency: 'EUR' }}</div>
									</ng-container>
									<ng-template #noReducedPrice>
										<div class="price">{{ newspaper.product.price | currency: 'EUR' }}</div>
									</ng-template>
								</ng-container>
								<ng-template #noConfig>
									<div class="price">{{ newspaper.product.price | currency: 'EUR' }}</div>
								</ng-template>
							</div>
							<div class="search-results--table__column search-results--table__column-button">
								<button mat-flat-button color="accent" (click)="onSelect(newspaper)">
									<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
									<span>Auswählen</span>
								</button>
							</div>
						</div>
					</ng-container>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

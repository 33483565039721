import { createAction, props } from '@ngrx/store';
import { IArticle } from '../../models/article.model';

const stateName = '[Crossselling]';
const load = createAction(`${stateName} Load`);
const loaded = createAction(`${stateName} Loaded`, props<{ articles: IArticle[] }>());

export const fromCrosssellingActions = {
	load,
	loaded,
};

import { on } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { EntityState, IRegionState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromRegionActions } from './region.actions';

export const initialState = EntityState.create<IRegion, IRegionState>();
export const regionReducer = EntityReducer.create<IRegion, IRegionState>(
	initialState,
	fromRegionActions,
	'region',
	on(fromRegionActions.nameChanged, (state, { name }) => ({ ...state, name })),
	on(fromRegionActions.suggest, state => ({ ...state, isLoading: true }))
);

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, IYearBook } from '../../models/article.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { PageFacade } from '../../state/page/page.facade';
import { YearBookFacade } from '../../state/year-book/year-book.facade';
import { YearBookDetailDialogComponent } from '../detaildialog/yearbook-detaildialog.component';

@Component({
	selector: 'shop-searchresult-year-book',
	templateUrl: './searchresult-year-book.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultYearbookComponent {
	@Output()
	public selectedYearbook = new EventEmitter<IYearBook>();
	public ArticleKinds = ArticleKind;

	constructor(private googleService: GoogleService, public yearBookFacade: YearBookFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade, private dialog: MatDialog) {}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(yearbook: IYearBook) {
		this.yearBookFacade.select(yearbook);
		this.selectedYearbook.emit(yearbook);
		this.googleService.selectItem(yearbook);
		this.dialog.open(YearBookDetailDialogComponent, { data: { article: yearbook }, panelClass: 'xselling-dialog' });
	}
}

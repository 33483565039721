import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { IStick } from '../../models/article.model';
import { IStickState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { StickFacade } from '../../state/stick/stick.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-stick-detaildialog',
	templateUrl: 'stick-detaildialog.component.html',
	animations: [crossFade],
})
export class StickDetailDialogComponent extends DetailDialogComponent<IStick, IStickState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: IStick }, cartFacade: CartFacade, stickFacade: StickFacade) {
		super(data, cartFacade, stickFacade);
	}
}

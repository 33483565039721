import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICart } from '../../models/cart.model';
import { IOrder } from '../../models/order.model';
import { DtoApiService, IResult } from '../dto.service';

@Injectable()
export class CartApiService extends DtoApiService<ICart> {
	public urlPathName = 'carts';

	constructor(http: HttpClient) {
		super(http);
	}

	public update(dto: ICart): Observable<IResult<ICart>> {
		return this.post<IResult<ICart>, ICart>([this.urlPathName, 'public'], dto);
	}

	public checkout(dto: ICart): Observable<IResult<IOrder>> {
		return this.post<IResult<IOrder>, ICart>([this.urlPathName, 'checkout', 'public'], dto);
	}
}

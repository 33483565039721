import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IMagazine, INewspaper } from '../../models/article.model';
import { IBundle } from '../../models/bundle.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';

@Component({
	selector: 'shop-bundle-dialog',
	templateUrl: 'bundle-dialog.component.html',
})
export class BundleDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) private data: { newspaper: INewspaper; magazine: IMagazine; bundle: IBundle }, private bundleFacade: BundleFacade, private router: Router, private googleService: GoogleService) {}

	public get newspaper(): INewspaper {
		return this.data.newspaper;
	}

	public get magazine(): IMagazine {
		return this.data.magazine;
	}

	public get bundle(): IBundle {
		return this.data.bundle;
	}

	public configure(): void {
		this.bundleFacade.select(this.bundle);
		this.googleService.selectBundle(this.bundle);
		this.router.navigate(['/konfiguration']);
	}
}

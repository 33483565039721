import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { IPage } from 'src/models/page.model';
import { PageApiService } from 'src/services/api/page.service';
import { AppState, IPageState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { RouterSelector } from '../router/router.selectors';
import { fromPageActions } from './page.actions';
import { PageSelector } from './page.selectors';

@Injectable()
export class PageEffects extends EntityEffects<IPage, IPageState> {
	constructor(actions$: Actions, store: Store<AppState>, pageApiService: PageApiService, routerSelector: RouterSelector, private pageSelector: PageSelector) {
		super(actions$, store, pageApiService, pageSelector, routerSelector, fromPageActions);
	}

	public onRouterNavigatedForPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED, fromPageActions.loaded),
			concatLatestFrom(() => this.store.select(this.routerSelector.pageUrl)),
			concatLatestFrom(() => this.store.select(this.routerSelector.childUrl)),
			concatLatestFrom(() => this.store.select(this.pageSelector.list)),
			filter(([[[]], list]) => list.length > 0),
			map(([[[, pageUrl], childUrl], list]) => {
				let page = list.find(x => x.url == '/' + pageUrl);

				if (page != null && childUrl != null) {
					page = page.children.find(x => x.url == '/' + pageUrl + '/' + childUrl) || page;
				} else if (childUrl != null) {
					const allChildren = list.reduce((acc, x) => acc.concat(x.children), []);
					page = allChildren.find(x => x.url == '/' + pageUrl + '/' + childUrl) || page;
				}

				if (page == null && childUrl != null) {
					page = list.find(x => x.url == '/' + pageUrl + '/' + childUrl) || page;
				}

				return fromPageActions.selected({ entity: page });
			})
		)
	);
}

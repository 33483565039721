import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { ExternalLibrariesFacade } from 'src/state/externallibraries/externallibraries.facade';
import { filter, map } from 'rxjs';

@Component({
	selector: 'shop-shopvote-badge-50',
	templateUrl: './shopvote-badge-50.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopvoteBadge50Component {
	public isShopvoteReputationLoaded$ = this.externalLibrariesFacade.isShopvoteReputationLoaded$.pipe(map(isShopvoteReputationLoaded => isShopvoteReputationLoaded && environment.shopvoteShopId > 0));
	constructor(private externalLibrariesFacade: ExternalLibrariesFacade) {}
}

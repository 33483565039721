import { IArticle } from './article.model';
import { IBundle } from './bundle.model';
import { IEntity } from './entity.model';
import { IImage } from './image.model';
import { ShippingKind } from './order.model';

export interface IPosition extends IEntity {
	positionKind: PositionKind;
	title?: string;
	price?: number;
	image?: IImage;
	isUnavailable?: boolean;
	isPartiallyUnavailable?: boolean;
}

export enum PositionKind {
	Bundle = 'Paket',
	Article = 'Artikel',
	Shipping = 'Lieferung',
	Payment = 'Zahlung',
	Discount = 'Rabatt',
}

export interface IBundlePosition extends IPosition {
	articles: IArticle[];
	bundle?: IBundle;
	upgrades?: IBundle[];
}

export interface IArticlePosition extends IPosition {
	article: IArticle;
	upgrades?: IBundle[];
}

export interface IShippingPosition extends IPosition {
	shippingKind: ShippingKind;
}

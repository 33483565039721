import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ClearAsyncErrorAction, MarkAsTouchedAction, SetAsyncErrorAction, SetValueAction, StartAsyncValidationAction } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { catchError, debounceTime, filter, map, mergeMap, of, startWith, switchMap, tap } from 'rxjs';
import { ArticleKind } from '../../models/article.model';
import { ICart } from '../../models/cart.model';
import { PaymentKind } from '../../models/order.model';
import { PositionKind } from '../../models/position.model';
import { CartApiService } from '../../services/api/cart.service';
import { IErrorResult } from '../../services/dto.service';
import { BingService } from '../../services/utility/bing.service';
import { GoogleService } from '../../services/utility/google.service';
import { IpInfoService } from '../../services/utility/ipinfo.service';
import { fromAddressActions } from '../address/address.actions';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromBirthdayBookActions } from '../birthday-book/birthday-book.actions';
import { fromBirthdayChronicleActions } from '../birthday-chronicle/birthday-chronicle.actions';
import { fromBookActions } from '../book/book.actions';
import { BundleSelector } from '../bundle/bundle.selectors';
import { fromCdActions } from '../cd/cd.actions';
import { fromCertificateActions } from '../certificate/certificate.actions';
import { fromChronicleActions } from '../chronicle/chronicle.actions';
import { fromCoinActions } from '../coin/coin.actions';
import { fromConfigurationActions } from '../configuration/configuration.actions';
import { ConfigurationFacade } from '../configuration/configuration.facade';
import { ConfigurationSelector } from '../configuration/configuration.selectors';
import { fromFolderActions } from '../folder/folder.actions';
import { ImageSelector } from '../image/image.selectors';
import { fromMagazineActions } from '../magazine/magazine.actions';
import { fromNewspaperActions } from '../newspaper/newspaper.actions';
import { RouterSelector } from '../router/router.selectors';
import { fromStickActions } from '../stick/stick.actions';
import { fromYearBookActions } from '../year-book/year-book.actions';
import { fromCartActions } from './cart.actions';
import { CartSelector } from './cart.selectors';

@Injectable()
export class CartEffects extends BaseEffects {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private routerSelector: RouterSelector,
		private bundleSelector: BundleSelector,
		private configurationSelector: ConfigurationSelector,
		private cartSelector: CartSelector,
		private imageSelector: ImageSelector,
		private cartService: CartApiService,
		private googleService: GoogleService,
		private bingService: BingService,
		private ipInfoService: IpInfoService,
		private configurationFacade: ConfigurationFacade,
		private router: Router
	) {
		super(actions$, store);
	}

	public onInit$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROOT_EFFECTS_INIT),
			map(() => fromCartActions.reloaded())
		)
	);

	public onPositionAdded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.positionAdded),
			concatLatestFrom(() => this.store.select(this.routerSelector.positionIndex)),
			concatLatestFrom(() => this.store.select(this.bundleSelector.selected)),
			concatLatestFrom(() => this.store.select(this.imageSelector.list)),
			map(([[[{ articles }, replacementIndex], bundle], images]) => {
				const isBundle = bundle != null && bundle.components.every(component => articles.find(x => x.articleKind == component.articleKind));
				if (!isBundle) {
					const [article] = articles;
					let image = article.product.image || article.product.mediaBrand.image;

					if (image == null) {
						switch (article.articleKind) {
							case ArticleKind.Book:
								image = images.find(image => image._id == '62d43f5ad17e089e55373f06');
								break;
							case ArticleKind.Cd:
								image = images.find(image => image._id == '62d43f5ad17e089e55373f04');
								break;
							case ArticleKind.Dvd:
								image = images.find(image => image._id == '62d43f5ad17e089e55373f05');
								break;
							case ArticleKind.Certificate:
								image = images.find(image => image._id == '62d43f5ad17e089e55373f03');
								break;
							case ArticleKind.Magazine:
								image = images.find(image => image._id == '62d43f5ad17e089e55372fe8');
								break;
							case ArticleKind.Newspaper:
								image = images.find(image => image._id == '62d43f5ad17e089e55372fe7');
								break;
							case ArticleKind.Stick:
								image = images.find(image => image._id == '62d43f5ad17e089e55373f07');
								break;
						}
					}

					return fromCartActions.articleAdded({
						position: {
							title: article.product.name,
							image: image,
							positionKind: PositionKind.Article,
							article,
						},
						replacementIndex,
					});
				} else {
					return fromCartActions.bundleAdded({
						position: {
							title: bundle.name,
							image: bundle.image,
							positionKind: PositionKind.Bundle,
							articles: bundle.components.map(component => articles.find(x => x.articleKind == component.articleKind)),
							bundle,
						},
						replacementIndex,
					});
				}
			})
		)
	);

	public onArticleAddedForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.articleAdded),
				mergeMap(({ position }) => [this.googleService.addToCart(position)])
			),
		{ dispatch: false }
	);

	public onBundleAddedForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.bundleAdded),
				mergeMap(({ position }) => [this.googleService.addToCart(position)])
			),
		{ dispatch: false }
	);

	public onPositionRemovedForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.positionRemoved),
				mergeMap(({ position }) => [this.googleService.removeFromCart(position)])
			),
		{ dispatch: false }
	);

	public onArticleOrBundleAdded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.articleAdded, fromCartActions.bundleAdded),
			switchMap(() => [
				fromBookActions.selected({ entity: null }),
				fromBirthdayBookActions.selected({ entity: null }),
				fromYearBookActions.selected({ entity: null }),
				fromCdActions.selected({ entity: null }),
				fromNewspaperActions.selected({ entity: null }),
				fromMagazineActions.selected({ entity: null }),
				fromFolderActions.selected({ entity: null }),
				fromCoinActions.selected({ entity: null }),
				fromCertificateActions.selected({ entity: null }),
				fromStickActions.selected({ entity: null }),
				fromChronicleActions.selected({ entity: null }),
				fromBirthdayChronicleActions.selected({ entity: null }),
			])
		)
	);

	public onPositionChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fromCartActions.articleAdded,
				fromCartActions.bundleAdded,
				fromCartActions.positionRemoved,
				fromCartActions.unavailablePositionsRemoved,
				fromCartActions.paymentKindChanged,
				fromCartActions.shippingKindChanged,
				fromCartActions.receiverChanged
			),
			map(() => fromCartActions.update())
		)
	);

	public onSaturdayDeliveryChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.enableSaturdayDeliveryChanged),
			concatLatestFrom(() => this.store.select(this.cartSelector.enableSaturdayDelivery)),
			filter(([{ enableSaturdayDelivery }, previousEnableSaturdayDelivery]) => enableSaturdayDelivery != previousEnableSaturdayDelivery),
			map(() => fromCartActions.update())
		)
	);

	public onUpdate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.update),
			debounceTime(50),
			concatLatestFrom(() => this.store.select(this.cartSelector.cartState)),
			switchMap(([, cartState]) => {
				const cart: ICart = {
					shippingWithoutInvoice: cartState.shippingWithoutInvoice,
					consentEmailTransfer: cartState.consentEmailTransfer,
					enableSaturdayDelivery: cartState.enableSaturdayDelivery,
					positions: cartState.positions,
					shippingKind: cartState.shippingKind,
					allowedShippingKinds: cartState.allowedShippingKinds,
					allowShippingAddress: cartState.allowShippingAddress,
					allowPackstation: cartState.allowPackstation,
					ipInfo: cartState.ipInfo,
					paymentKind: cartState.paymentKind,
					totalPrice: cartState.totalPrice,
					customer: cartState.customer,
					receiver: cartState.receiver,
					payer: cartState.payer,
					taxes: cartState.taxes,
					orderId: cartState.orderId,
				};

				return this.cartService.update(cart).pipe(
					map(response => fromCartActions.updated({ cart: response.data })),
					catchError((response: IErrorResult) => of(fromCartActions.failed({ message: response.error?.error || response.error?.message })))
				);
			})
		)
	);

	public onShippingKindChangedForSaturdayDelivery$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.shippingKindChanged, fromConfigurationActions.loadedShippingInformation),
			concatLatestFrom(() => this.store.select(this.configurationSelector.shippingInformation)),
			concatLatestFrom(() => this.store.select(this.cartSelector.shippingKind)),
			concatLatestFrom(() => this.store.select(this.cartSelector.enableSaturdayDelivery)),
			map(([[[, shippingInformation], shippingKind], enableSaturdayDelivery]) => ({
				shippingType: shippingInformation.shippingTypes.find(x => x.shippingKind == shippingKind),
				enableSaturdayDelivery,
				isSaturdayDeliveryEnabled: shippingInformation.shippingTypes.some(x => x.priceSaturdayDelivery != null),
			})),
			map(({ isSaturdayDeliveryEnabled }) => fromCartActions.enableSaturdayDeliveryChanged({ enableSaturdayDelivery: isSaturdayDeliveryEnabled }))
		)
	);

	public onChangeEnableSaturdayDelivery$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.enableSaturdayDeliveryChanged, fromCartActions.shippingKindChanged),
			concatLatestFrom(() => this.store.select(this.cartSelector.shippingKind)),
			map(([, shippingKind]) => fromConfigurationActions.loadEstimatedDelivery({ shippingKind }))
		)
	);

	public onCheckout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.checkout),
			concatLatestFrom(() => this.store.select(this.cartSelector.cartState)),
			switchMap(([, cart]) =>
				this.cartService.checkout({ ...cart, receiver: cart.isReceiverDiffering ? cart.receiver : cart.payer }).pipe(
					map(response => fromCartActions.checkedOut({ order: response.data, taxes: cart.taxes })),
					catchError((response: IErrorResult) => {
						this.router.navigate(['/warenkorb']);
						return of(fromCartActions.failed({ message: response.error?.error || response.error?.message }));
					})
				)
			)
		)
	);

	public onCheckedOut$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.checkedOut),
				tap(({ order }) => this.router.navigate(['/bestellung', order._id], { queryParams: { redirect: true } }))
			),
		{ dispatch: false }
	);

	public onCheckedOutForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.checkedOut),
				mergeMap(({ order, taxes }) => [this.googleService.purchase(order, taxes), this.bingService.purchase(order)])
			),
		{ dispatch: false }
	);

	public onPayerChangedForIpInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.payerChanged),
			map(() => fromCartActions.loadIpInfo())
		)
	);

	public onPayerAddressChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.changedPayerAddress),
			mergeMap(({ address }) => {
				const result: Action[] = [fromAddressActions.cleared()];

				if (address.street != null) {
					result.push(new SetValueAction('PAYER.address.street', address.street));
				}

				if (address.streetNumber != null) {
					result.push(new SetValueAction('PAYER.address.streetNumber', address.streetNumber));
				}

				if (address.postCode != null) {
					result.push(new SetValueAction('PAYER.address.postCode', address.postCode));
				}

				if (address.city != null) {
					result.push(new SetValueAction('PAYER.address.city', address.city));
				}

				if (address.country != null) {
					result.push(new SetValueAction('PAYER.address.country', address.country));
				}

				return result;
			})
		)
	);

	public onReceiverAddressChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.changedReceiverAddress),
			mergeMap(({ address }) => {
				const result: Action[] = [fromAddressActions.cleared()];

				if (address.street != null) {
					result.push(new SetValueAction('RECEIVER.address.street', address.street));
				}

				if (address.streetNumber != null) {
					result.push(new SetValueAction('RECEIVER.address.streetNumber', address.streetNumber));
				}

				if (address.postCode != null) {
					result.push(new SetValueAction('RECEIVER.address.postCode', address.postCode));
				}

				if (address.city != null) {
					result.push(new SetValueAction('RECEIVER.address.city', address.city));
				}

				if (address.country != null) {
					result.push(new SetValueAction('RECEIVER.address.country', address.country));
				}

				return result;
			})
		)
	);

	public onPaymentKindDirectDebitForIbanRequired$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.paymentKindChanged, SetValueAction.TYPE),
			debounceTime(100),
			concatLatestFrom(() => this.store.select(this.cartSelector.paymentKind)),
			concatLatestFrom(() => this.store.select(this.cartSelector.payerForm)),
			switchMap(([[, paymentKind], payerForm]) => {
				const clearRequiredError = new ClearAsyncErrorAction(`PAYER.iban`, 'required');

				if (paymentKind != PaymentKind.DirectDebit) {
					return of(clearRequiredError);
				}

				const setRequiredError = new SetAsyncErrorAction(`PAYER.iban`, 'required', 'Dies ist ein Pflichtfeld');
				const validateRequiredError = new StartAsyncValidationAction(`PAYER.iban`, 'required');

				return of(required(payerForm.value['iban'])).pipe(
					map(error => (error.required ? setRequiredError : clearRequiredError)),
					startWith(validateRequiredError)
				);
			})
		)
	);

	public onPaymentKindDirectDebitForIbanValidation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.paymentKindChanged),
			switchMap(() => [new SetValueAction('PAYER.iban', null), new SetValueAction('PAYER.accountOwner', null)])
		)
	);

	public onPaymentKindDirectDebitForAccountOwner$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.paymentKindChanged, SetValueAction.TYPE),
			debounceTime(100),
			concatLatestFrom(() => this.store.select(this.cartSelector.paymentKind)),
			concatLatestFrom(() => this.store.select(this.cartSelector.payerForm)),
			switchMap(([[, paymentKind], payerForm]) => {
				const clearRequiredError = new ClearAsyncErrorAction(`PAYER.accountOwner`, 'required');

				if (paymentKind != PaymentKind.DirectDebit) {
					return of(clearRequiredError);
				}

				const setRequiredError = new SetAsyncErrorAction(`PAYER.accountOwner`, 'required', 'Dies ist ein Pflichtfeld');
				const validateRequiredError = new StartAsyncValidationAction(`PAYER.accountOwner`, 'required');

				return of(required(payerForm.value['accountOwner'])).pipe(
					map(error => (error.required ? setRequiredError : clearRequiredError)),
					startWith(validateRequiredError)
				);
			})
		)
	);

	public onMarkedAllAsTouched$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.markedAllAsTouched),
			switchMap(() => [new MarkAsTouchedAction('PAYER'), new MarkAsTouchedAction('RECEIVER'), new MarkAsTouchedAction('CUSTOMER')])
		)
	);

	public onLoadIpInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.loadIpInfo),
			switchMap(() => this.ipInfoService.get().pipe(map(ipInfo => fromCartActions.loadedIpInfo({ ipInfo }))))
		)
	);

	public onPaymentKindChangedForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.paymentKindChanged),
				mergeMap(({ paymentKind }) => [this.googleService.changePaymentKind(paymentKind)])
			),
		{ dispatch: false }
	);

	public onShippingKindChangedForAnalysis$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCartActions.shippingKindChanged),
				mergeMap(({ shippingKind }) => [this.googleService.changeShippingKind(shippingKind)])
			),
		{ dispatch: false }
	);
}

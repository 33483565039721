<div class="container">
	<mat-accordion>
		<mat-expansion-panel *ngFor="let element of params.elements">
			<mat-expansion-panel-header>
				<mat-panel-title>{{ element.title }}</mat-panel-title>
				<mat-panel-description></mat-panel-description>
			</mat-expansion-panel-header>
			<div [innerHtml]="element.content | safe"></div>
		</mat-expansion-panel>
	</mat-accordion>
</div>

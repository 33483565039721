import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromExternalLibrariesActions } from './externallibraries.actions';
import { ExternalLibrariesSelector } from './externallibraries.selectors';

@Injectable()
export class ExternalLibrariesEffects extends BaseEffects {
	constructor(actions$: Actions, store: Store<AppState>, private httpClient: HttpClient, private externalLibrariesSelector: ExternalLibrariesSelector, private googleTagManagerService: GoogleTagManagerService) {
		super(actions$, store);
	}

	public onYouTubeLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromExternalLibrariesActions.youtubeLoad),
			concatLatestFrom(() => this.store.select(this.externalLibrariesSelector.isYoutubeLoaded)),
			filter(([, isYouTubeLoaded]) => !isYouTubeLoaded),
			switchMap(() =>
				this.httpClient.jsonp(`https://www.youtube.com/iframe_api`, 'callback').pipe(
					map(() => fromExternalLibrariesActions.youtubeLoaded()),
					catchError(error => of(fromExternalLibrariesActions.youtubeLoaded()))
				)
			)
		)
	);

	public onShopvoteReputationLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromExternalLibrariesActions.shopvoteReputationLoad),
			concatLatestFrom(() => this.store.select(this.externalLibrariesSelector.isShopvoteReputationLoaded)),
			filter(([, isShopvoteReputationLoaded]) => !isShopvoteReputationLoaded),
			switchMap(() =>
				this.httpClient.jsonp(`https://widgets.shopvote.de/js/reputation-badge-v2.min.js`, 'callback').pipe(
					map(() => fromExternalLibrariesActions.shopvoteReputationLoaded()),
					catchError(() => of(fromExternalLibrariesActions.shopvoteReputationLoaded()))
				)
			)
		)
	);

	public onShopvoteReviewsLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromExternalLibrariesActions.shopvoteReviewsLoad),
			concatLatestFrom(() => this.store.select(this.externalLibrariesSelector.isShopvoteReviewsLoaded)),
			filter(([, isShopvoteReviewsLoaded]) => !isShopvoteReviewsLoaded),
			switchMap(() =>
				this.httpClient.jsonp(`https://feedback.shopvote.de/srt-v4.min.js`, 'callback').pipe(
					map(() => fromExternalLibrariesActions.shopvoteReviewsLoaded()),
					catchError(() => of(fromExternalLibrariesActions.shopvoteReviewsLoaded()))
				)
			)
		)
	);
}

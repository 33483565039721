import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, ICd } from '../../models/article.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { CdFacade } from '../../state/cd/cd.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { PageFacade } from '../../state/page/page.facade';
import { CdDetailDialogComponent } from '../detaildialog/cd-detaildialog.component';

@Component({
	selector: 'shop-searchresult-cd',
	templateUrl: './searchresult-cd.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultCdComponent {
	@Output()
	public selectedCd = new EventEmitter<ICd>();
	public ArticleKinds = ArticleKind;

	constructor(private googleService: GoogleService, public cdFacade: CdFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade, private dialog: MatDialog) {}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(cd: ICd) {
		this.cdFacade.select(cd);
		this.selectedCd.emit(cd);
		this.googleService.selectItem(cd);
		this.dialog.open(CdDetailDialogComponent, { data: { article: cd }, panelClass: 'xselling-dialog' });
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICoin } from '../../models/article.model';
import { AppState, ICoinState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCoinActions } from './coin.actions';
import { CoinSelector } from './coin.selectors';

@Injectable()
export class CoinFacade extends EntityFacade<ICoin, ICoinState> {
	constructor(store: Store<AppState>, coinSelector: CoinSelector) {
		super(store, coinSelector);
	}

	protected actions = fromCoinActions;
}

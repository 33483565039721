import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ArticleKind, IArticle, ICoin } from '../../models/article.model';
import { GoogleService } from '../../services/utility/google.service';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { CoinFacade } from '../../state/coin/coin.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { PageFacade } from '../../state/page/page.facade';
import { CoinDetailDialogComponent } from '../detaildialog/coin-detaildialog.component';

@Component({
	selector: 'shop-searchresult-coin',
	templateUrl: './searchresult-coin.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [crossFade],
})
export class SearchresultCoinComponent {
	@Output()
	public selectedCoin = new EventEmitter<ICoin>();
	public ArticleKinds = ArticleKind;

	constructor(private googleService: GoogleService, public coinFacade: CoinFacade, public imageFacade: ImageFacade, public bundleFacade: BundleFacade, public pageFacade: PageFacade, private dialog: MatDialog) {}

	public articleById(index: number, article: IArticle) {
		return article._id;
	}

	public onSelect(coin: ICoin) {
		this.coinFacade.select(coin);
		this.selectedCoin.emit(coin);
		this.googleService.selectItem(coin);
		this.dialog.open(CoinDetailDialogComponent, { data: { article: coin }, panelClass: 'xselling-dialog' });
	}
}

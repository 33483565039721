<ng-container *ngIf="!(coinFacade.isLoading$ | async) && !(coinFacade.list$ | async).length"> </ng-container>

<ng-container *ngIf="coinFacade.isLoading$ | async">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Historische Zahlungsmittel</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(coinFacade.isLoading$ | async) && (coinFacade.list$ | async).length">
	<div class="search-results" [@crossFade]>
		<div class="search-results--tables">
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Historische Zahlungsmittel</strong></p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let coin of coinFacade.list$ | async; trackBy: articleById" class="search-results--table__row">
						<div class="search-results--table__column search-results--table__column-image">
							<shop-picture *ngIf="coin.product.image != null" [image]="coin.product.image" [width]="130" [height]="130" (click)="onSelect(coin)"></shop-picture>
							<shop-picture *ngIf="coin.product.image == null && coin.product.mediaBrand.image != null" [image]="coin.product.mediaBrand.image" [width]="130" [height]="130" (click)="onSelect(coin)"></shop-picture>
							<shop-picture
								*ngIf="coin.product.image == null && coin.product.mediaBrand.image == null"
								[image]="imageFacade.loadSingleById('632c3708482d64c412537d58') | async"
								[width]="130"
								[height]="130"
								(click)="onSelect(coin)"></shop-picture>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<shop-picture *ngIf="coin.product.logo != null" [image]="coin.product.logo" [width]="200" [height]="40"></shop-picture>
							<shop-picture *ngIf="coin.product.logo == null && coin.product.mediaBrand.logo != null" [image]="coin.product.mediaBrand.logo" [width]="200" [height]="40"></shop-picture>
							<div>
								<b>{{ coin.product.name }}</b>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div *ngIf="coin.product.shortDescription">{{ coin?.product?.shortDescription }}</div>
							<div *ngIf="coin.product.shortDescription == null && coin.product.mediaBrand.shortDescription">{{ coin?.product?.mediaBrand.shortDescription }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="availability"><b>&bull;</b> verfügbar</div>
							<div class="price">{{ coin.product.price | currency: 'EUR' }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<button mat-flat-button color="accent" (click)="onSelect(coin)">
								<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
								<span>Auswählen</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

import { on } from '@ngrx/store';
import moment from 'moment';
import { INewspaper } from '../../models/article.model';
import { INewspaperState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromNewspaperActions } from './newspaper.actions';

export const initialState = {
	...MediaArticleState.create<INewspaper, INewspaperState>(
		{
			skip: 0,
			top: 20,
		},
		{
			current: 2,
			min: 2,
			max: 1024,
		}
	),
	date: moment().subtract(50, 'years').add(7, 'days').toDate(),
	name: '',
};

export const newspaperReducer = EntityReducer.create<INewspaper, INewspaperState>(
	initialState,
	fromNewspaperActions,
	'newspaper',
	on(fromNewspaperActions.load, state => ({ ...state, isLoading: true, paging: { ...state.paging, top: initialState.paging.top }, dayRange: { ...state.dayRange, current: state.dayRange.min } })),
	on(fromNewspaperActions.loaded, (state, { list }) => ({ ...EntityReducer.onLoaded(state, list), isExtending: false })),
	on(fromNewspaperActions.extended, state => ({ ...state, isExtending: false })),
	on(fromNewspaperActions.dateChanged, (state, { date }) => ({ ...state, date: date || state.date })),
	on(fromNewspaperActions.pagingChanged, (state, { paging }) => ({ ...state, paging, isExtending: true })),
	on(fromNewspaperActions.dayRangeChanged, (state, { dayRange }) => ({ ...state, dayRange, isExtending: true })),
	on(fromNewspaperActions.isFilterVisibleChanged, (state, { isFilterVisible }) => ({ ...state, isFilterVisible })),
	on(fromNewspaperActions.nameChanged, (state, { name }) => ({ ...state, name })),
	on(fromNewspaperActions.loadNewspaper, state => ({ ...state, isLoading: true })),
	on(fromNewspaperActions.loadedNewspaper, (state, { newspaper }) => ({ ...state, isLoading: false, selected: newspaper }))
);

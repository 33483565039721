import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, first, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { Vendors, WindowRefService } from '../../services/utility/window.service';
import { ExternalLibrariesFacade } from '../../state/externallibraries/externallibraries.facade';

@Component({
	selector: 'shop-shopvote-badge-allvotes',
	templateUrl: './shopvote-badge-allvotes.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopvoteBadgeAllVotesComponent implements AfterViewInit {
	public Vendors = Vendors;
	constructor(private externalLibrariesFacade: ExternalLibrariesFacade, private windowRefService: WindowRefService) {}

	public ngAfterViewInit(): void {
		this.externalLibrariesFacade.isShopvoteReputationLoaded$
			.pipe(
				filter(isShopvoteReputationLoaded => isShopvoteReputationLoaded && environment.shopvoteShopId > 0),
				first(),
				map(() => this.windowRefService.nativeWindow.createRBadge(environment.shopvoteShopId, 4, 'https'))
			)
			.subscribe();
	}
}

import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { concat } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { WindowRefService } from './window.service';

@Injectable()
export class UpdateService {
	constructor(private appRef: ApplicationRef, private windowService: WindowRefService, private serviceWorker: SwUpdate, private snackBar: MatSnackBar) {}

	public checkForUpdates(): void {
		if (!this.serviceWorker.isEnabled) {
			return;
		}

		this.appRef.isStable.pipe(first(isStable => isStable === true)).subscribe(() => this.checkForUpdate());
		this.serviceWorker.versionUpdates.pipe(filter(version => version.type == 'VERSION_DETECTED')).subscribe(() => this.onVersionDetected());
		this.serviceWorker.versionUpdates.pipe(filter(version => version.type == 'VERSION_READY')).subscribe(() => this.onVersionReady());
	}

	private checkForUpdate(): void {
		this.serviceWorker.checkForUpdate();
	}

	private onVersionDetected(): void {
		this.serviceWorker.activateUpdate();
	}

	private onVersionReady(): void {
		const snackBarRef = this.snackBar.open(`Ein Update wird in Kürze angewendet...`, 'Jetzt aktualisieren', {
			duration: 5000,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		});

		concat(snackBarRef.onAction(), snackBarRef.afterDismissed()).subscribe(() => {
			this.windowService.nativeWindow.localStorage.clear();
			this.windowService.nativeWindow.location.reload();
		});
	}
}

import { ICoin } from '../../models/article.model';
import { ICoinState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCoinActions } from './coin.actions';

export const initialState = MediaArticleState.create<ICoin, ICoinState>(
	{
		skip: 0,
		top: 10,
	},
	{
		current: 3650,
		min: 3650,
		max: 3650,
	}
);

export const coinReducer = EntityReducer.create<ICoin, ICoinState>(initialState, fromCoinActions);

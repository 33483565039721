import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICounter } from '../../models/counter.model';
import { IEmptyResult } from '../dto.service';
import { EntityService } from '../entity.service';

@Injectable()
export class CounterApiService extends EntityService<ICounter> {
	public entityName = 'counters';

	constructor(http: HttpClient) {
		super(http);
	}

	public visits(): Observable<IEmptyResult> {
		return this.post<IEmptyResult, unknown>([this.entityName, 'public', 'visits'], null);
	}

	public essential(): Observable<IEmptyResult> {
		return this.post<IEmptyResult, unknown>([this.entityName, 'public', 'essential'], null);
	}

	public essentialAnalysis(): Observable<IEmptyResult> {
		return this.post<IEmptyResult, unknown>([this.entityName, 'public', 'essential-analysis'], null);
	}

	public essentialKomfort(): Observable<IEmptyResult> {
		return this.post<IEmptyResult, unknown>([this.entityName, 'public', 'essential-komfort'], null);
	}

	public essentialKomfortAnalysis(): Observable<IEmptyResult> {
		return this.post<IEmptyResult, unknown>([this.entityName, 'public', 'essential-komfort-analysis'], null);
	}
}

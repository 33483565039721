import { Injectable } from '@angular/core';

export interface IGeschenkzeitungWindow extends Window {
	uetq: unknown[];
	createRBadge(shopId: number, badgeType: number, protocol: string): void;
	loadSRT(myToken: string, mySrc: string): void;
	__cmp(command: string, parameter?: [string, () => void, boolean], callback?: Function): any;
}

function getWindow(): any {
	return window;
}

@Injectable()
export class WindowRefService {
	get nativeWindow(): IGeschenkzeitungWindow {
		return getWindow();
	}

	get location(): string {
		return this.nativeWindow.location.href.split('?')[0];
	}

	public hasVendorConsent(vendor: Vendors): boolean {
		return this.nativeWindow.__cmp('getCMPData')['vendorConsents'][vendor];
	}

	public onConsentLoaded(callback: () => void): boolean {
		return this.nativeWindow.__cmp('consent', null, callback);
	}

	public onAddEventListener(callback: () => void): boolean {
		return this.nativeWindow.__cmp('addEventListener', ['consentcustom', callback, null], null);
	}
}

export enum Vendors {
	GoogleAnalytics = 's26',
	Bing = 's11',
	Shopvote = 's2439',
	Youtube = 's30',
	Wuppertal360 = 'c51837',
	GoogleTagManager = 's905',
}

import { on } from '@ngrx/store';
import { ICertificate } from '../../models/article.model';
import { ArticleState, ICertificateState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCertificateActions } from './certificate.actions';

export const initialState = {
	...ArticleState.create<ICertificate, ICertificateState>({
		skip: 0,
		top: 100,
	}),
	customMessage: '',
};

export const certificateReducer = EntityReducer.create<ICertificate, ICertificateState>(
	initialState,
	fromCertificateActions,
	'certificate',
	on(fromCertificateActions.addressFormChanged, (state, { addressForm }) => ({ ...state, selected: { ...state.selected, addressForm } })),
	on(fromCertificateActions.customOccasionChanged, (state, { customOccasion }) => ({ ...state, selected: { ...state.selected, customOccasion } })),
	on(fromCertificateActions.dateChanged, (state, { date }) => ({ ...state, selected: { ...state.selected, date } })),
	on(fromCertificateActions.gifteeChanged, (state, { giftee }) => ({ ...state, selected: { ...state.selected, giftee } })),
	on(fromCertificateActions.gifteeBirthNameChanged, (state, { gifteeBirthName }) => ({ ...state, selected: { ...state.selected, gifteeBirthName } })),
	on(fromCertificateActions.isCustomizedChanged, (state, { isCustomized }) => ({ ...state, selected: { ...state.selected, isCustomized } })),
	on(fromCertificateActions.messageChanged, (state, { message }) => ({ ...state, selected: { ...state.selected, message } })),
	on(fromCertificateActions.customMessageChanged, (state, { customMessage }) => ({ ...state, selected: { ...state.selected, message: customMessage }, customMessage })),
	on(fromCertificateActions.originalMessageChanged, (state, { originalMessage }) => ({ ...state, originalMessage })),
	on(fromCertificateActions.occasionChanged, (state, { occasion }) => ({ ...state, selected: { ...state.selected, occasion } })),
	on(fromCertificateActions.partnerChanged, (state, { partner }) => ({ ...state, selected: { ...state.selected, partner } })),
	on(fromCertificateActions.partnerBirthNameChanged, (state, { partnerBirthName }) => ({ ...state, selected: { ...state.selected, partnerBirthName } })),
	on(fromCertificateActions.textChanged, (state, { text }) => ({ ...state, selected: { ...state.selected, text } })),
	on(fromCertificateActions.anniversaryChanged, (state, { anniversary }) => ({ ...state, selected: { ...state.selected, anniversary } }))
);

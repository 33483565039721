import { Pipe, PipeTransform } from '@angular/core';
import { ArticleKind, IAccessoryArticle, IArticle, ICertificate, IMediaArticle } from '../models/article.model';
import { IArticlePosition, IBundlePosition, IPosition, PositionKind } from '../models/position.model';

@Pipe({
	name: 'asMediaArticle',
})
export class CastMediaArticlePipe implements PipeTransform {
	transform(article: IArticle): any {
		return article.articleKind == ArticleKind.Book || article.articleKind == ArticleKind.Cd || article.articleKind == ArticleKind.Coin || article.articleKind == ArticleKind.Magazine || article.articleKind == ArticleKind.Newspaper
			? (article as IMediaArticle)
			: null;
	}
}

@Pipe({
	name: 'asAccessoryArticle',
})
export class CastAccessoryArticlePipe implements PipeTransform {
	transform(article: IArticle): any {
		return article.articleKind == ArticleKind.Certificate || article.articleKind == ArticleKind.Chronicle || article.articleKind == ArticleKind.Folder || article.articleKind == ArticleKind.Stick ? (article as IAccessoryArticle) : null;
	}
}

@Pipe({
	name: 'asCertificate',
})
export class CastCertificatePipe implements PipeTransform {
	transform(article: IArticle): any {
		return article.articleKind == ArticleKind.Certificate ? (article as ICertificate) : null;
	}
}

@Pipe({
	name: 'asArticlePosition',
})
export class CastArticlePositionPipe implements PipeTransform {
	transform(position: IPosition): any {
		return position.positionKind == PositionKind.Article ? (position as IArticlePosition) : null;
	}
}

@Pipe({
	name: 'asBundlePosition',
})
export class CastBundlePositionPipe implements PipeTransform {
	transform(position: IPosition): any {
		return position.positionKind == PositionKind.Bundle ? (position as IBundlePosition) : null;
	}
}

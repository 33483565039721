import { IBirthdayBook } from '../../models/article.model';
import { IBirthdayBookState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromBirthdayBookActions } from './birthday-book.actions';

export const initialState = MediaArticleState.create<IBirthdayBook, IBirthdayBookState>(
	{
		skip: 0,
		top: 1,
	},
	{
		current: 365,
		min: 365,
		max: 365,
	}
);

export const birthdayBookReducer = EntityReducer.create<IBirthdayBook, IBirthdayBookState>(initialState, fromBirthdayBookActions);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IImage } from 'src/models/image.model';
import { EntityService, IEntityListResult } from '../entity.service';

@Injectable()
export class ImageApiService extends EntityService<IImage> {
	protected entityName = 'images';

	constructor(http: HttpClient) {
		super(http);
	}

	public getMany(ids: string[]): Observable<IImage[]> {
		return this.post<IEntityListResult<IImage>, string[]>([this.entityName, 'public'], ids).pipe(map(result => result.data));
	}
}

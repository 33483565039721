import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFolder } from '../../models/article.model';
import { AppState, IFolderState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromFolderActions } from './folder.actions';
import { FolderSelector } from './folder.selectors';

@Injectable()
export class FolderFacade extends EntityFacade<IFolder, IFolderState> {
	constructor(store: Store<AppState>, private folderSelector: FolderSelector) {
		super(store, folderSelector);
	}

	public preselected$ = this.createBehaviourSubject(this.store.select(this.folderSelector.preselected));

	public selectById(id: string): void {
		this.select(this.items$.value[id]);
	}

	public preselectById(id: string): void {
		this.store.dispatch(fromFolderActions.preselected({ entity: this.items$.value[id] }));
	}

	protected actions = fromFolderActions;
}

import { Injectable } from '@angular/core';
import * as handlebars from 'handlebars/dist/cjs/handlebars';

@Injectable()
export class TemplateService {
	public compile(template: string, data: any): string {
		if (typeof template !== 'string') {
			return template;
		}

		try {
			return handlebars.compile(template)(data);
		} catch (error: any) {
			return error.message;
		}
	}
}

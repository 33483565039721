import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IOrder, IPaypalPayment, PaymentKind } from '../../models/order.model';
import { PositionKind } from '../../models/position.model';
import { AppState, IOrderState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable()
export class OrderSelector extends EntitySelector<IOrder, IOrderState> {
	protected stateSelector = (state: AppState): IOrderState => state.order;

	public productPositions = createSelector(this.selected, order => order.positions.filter(position => position.positionKind == PositionKind.Article || position.positionKind == PositionKind.Bundle));
	public shippingPosition = createSelector(this.selected, order => order.positions.find(position => position.positionKind == PositionKind.Shipping));
	public paymentPosition = createSelector(this.selected, order => order.positions.find(position => position.positionKind == PositionKind.Payment));
	public shipping = createSelector(this.selected, order => (order.shippings.length > 0 ? order.shippings[0] : null));
	public receipt = createSelector(this.selected, order => (order.primaryReceipt != null ? order.primaryReceipt : null));
	public payment = createSelector(this.receipt, receipt => (receipt.payments.length > 0 ? receipt.payments[0] : null));
	public paymentKind = createSelector(this.payment, payment => payment.paymentKind);
	public paymentPaypal = createSelector(this.payment, this.paymentKind, (payment, paymentKind) => (paymentKind == PaymentKind.Paypal ? (payment as IPaypalPayment) : null));
	public isPayNow = createSelector(this.paymentKind, paymentKind => [PaymentKind.Paypal, PaymentKind.Barzahlen].indexOf(paymentKind) > -1);
	public isPaymentUnpaid = createSelector(this.payment, this.isPayNow, (payment, isPayNow) => (isPayNow ? payment.processInstance.state.name == 'PaymentAssigned' : false));
}

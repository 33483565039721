import { createAction, props } from '@ngrx/store';
import { IConfiguration, IPaymentInformation, IShippingInformation } from '../../models/configuration.model';
import { ShippingKind } from '../../models/order.model';
import { IEstimatedDelivery } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromConfigurationActions = {
	...EntityActions.create<IConfiguration>('[Configuration]'),
	loadShippingInformation: createAction(`[Configuration] Load Shipping Information`),
	loadedShippingInformation: createAction(`[Configuration] Loaded Shipping Information`, props<{ shippingInformation: IShippingInformation }>()),
	loadPaymentInformation: createAction(`[Configuration] Load Payment Information`),
	loadedPaymentInformation: createAction(`[Configuration] Loaded Payment Information`, props<{ paymentInformation: IPaymentInformation }>()),
	loadEstimatedDelivery: createAction(`[Configuration] Load Estimated Delivery`, props<{ shippingKind: ShippingKind }>()),
	loadedEstimatedDeliveries: createAction(`[Configuration] Loaded Estimated Deliveries`, props<{ estimatedDeliveries: IEstimatedDelivery[] }>()),
	failed: createAction(`[Configuration] Failed`, props<{ message: string }>()),
};

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { ICertificate } from '../../models/article.model';
import { AppState, ICertificateState } from '../app.state';
import { ArticleSelector } from '../entity.selector';

@Injectable()
export class CertificateSelector extends ArticleSelector<ICertificate, ICertificateState> {
	protected stateSelector = (state: AppState): ICertificateState => state.certificate;

	public anniversary = createSelector(this.selectState, state => state.selected?.anniversary);
	public text = createSelector(this.selectState, state => state.selected?.text);
	public customMessage = createSelector(this.selectState, state => state.customMessage);
	public originalMessage = createSelector(this.selectState, state => state.originalMessage);
	public isComplete = createSelector(this.selected, certificate => certificate != null && certificate.date != null && certificate.occasion && certificate.giftee && certificate.message);
}

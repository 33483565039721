import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICd } from '../../models/article.model';
import { AppState, ICdState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCdActions } from './cd.actions';
import { CdSelector } from './cd.selectors';

@Injectable()
export class CdFacade extends EntityFacade<ICd, ICdState> {
	constructor(store: Store<AppState>, cdSelector: CdSelector) {
		super(store, cdSelector);
	}

	protected actions = fromCdActions;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IConfiguration, IPaymentInformation, IShippingInformation } from '../../models/configuration.model';
import { ShippingKind } from '../../models/order.model';
import { DtoApiService, IResult } from '../dto.service';
import {} from '../entity.service';

export interface IShippingRange {
	shippingKind: ShippingKind;
	from: Date;
	until: Date;
}

@Injectable()
export class ConfigurationApiService extends DtoApiService<IConfiguration> {
	protected urlPathName = 'configurations';

	constructor(http: HttpClient) {
		super(http);
	}

	public getShippingInformation(): Observable<IShippingInformation> {
		return this.get<IResult<IShippingInformation>>([this.urlPathName, 'shipping-information'], null).pipe(map(result => result.data));
	}

	public getPaymentInformation(): Observable<IPaymentInformation> {
		return this.get<IResult<IPaymentInformation>>([this.urlPathName, 'payment-information'], null).pipe(map(result => result.data));
	}

	public getEstimatedDeliveryDates(shippingKinds: ShippingKind[], enableSaturdayDelivery: boolean): Observable<IShippingRange[]> {
		return this.post<IResult<IShippingRange[]>, { shippingKinds: ShippingKind[]; enableSaturdayDelivery: boolean }>([this.urlPathName, 'shipping-information', 'estimate'], { shippingKinds, enableSaturdayDelivery }).pipe(
			map(result => result.data)
		);
	}
}

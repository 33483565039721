import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { filter, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../app.state';
import { PresentationFacade } from './presentation.facade';
import { PresentationSelector } from './presentation.selectors';

@Injectable()
export class PresentationEffects {
	constructor(protected actions$: Actions, protected store: Store<AppState>, private presentationSelector: PresentationSelector, private presentationFacade: PresentationFacade, private snackBar: MatSnackBar) {}

	public onInit$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ROOT_EFFECTS_INIT),
				filter(() => !environment.production),
				concatLatestFrom(() => this.store.select(this.presentationSelector.testingAcknowledgedAt)),
				filter(([, testingAcknowledgedAt]) => testingAcknowledgedAt == null || moment(testingAcknowledgedAt).isBefore(moment().subtract(1, 'day'))),
				switchMap(() =>
					this.snackBar
						.open(`Du befindest dich auf der Testumgebung`, 'Okay', {
							horizontalPosition: 'center',
							verticalPosition: 'bottom',
						})
						.afterDismissed()
						.pipe(tap(() => this.presentationFacade.acknowledgeTesting()))
				)
			),
		{ dispatch: false }
	);
}

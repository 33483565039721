import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { IFolder } from '../../models/article.model';
import { IFolderState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { FolderFacade } from '../../state/folder/folder.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-folder-detaildialog',
	templateUrl: 'folder-detaildialog.component.html',
	animations: [crossFade],
})
export class FolderDetailDialogComponent extends DetailDialogComponent<IFolder, IFolderState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: IFolder }, cartFacade: CartFacade, folderFacade: FolderFacade) {
		super(data, cartFacade, folderFacade);
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IOrder } from '../../models/order.model';
import { AppState, IOrderState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromOrderActions } from './order.actions';
import { OrderSelector } from './order.selectors';

@Injectable()
export class OrderFacade extends EntityFacade<IOrder, IOrderState> {
	constructor(store: Store<AppState>, private orderSelector: OrderSelector) {
		super(store, orderSelector);
	}

	public productPositions$ = this.store.select(this.orderSelector.productPositions);
	public shippingPosition$ = this.store.select(this.orderSelector.shippingPosition);
	public paymentPosition$ = this.store.select(this.orderSelector.paymentPosition);
	public shipping$ = this.store.select(this.orderSelector.shipping);
	public receipt$ = this.store.select(this.orderSelector.receipt);
	public payment$ = this.store.select(this.orderSelector.payment);
	public isPaymentUnpaid$ = this.store.select(this.orderSelector.isPaymentUnpaid);

	public createTransaction(returnUrl: string, cancelUrl: string): void {
		this.store.dispatch(fromOrderActions.createTransaction({ returnUrl, cancelUrl }));
	}

	protected actions = fromOrderActions;
}

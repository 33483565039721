import { ChangeDetectorRef, Component } from '@angular/core';
import { PageFacade } from 'src/state/page/page.facade';
import { RouterFacade } from 'src/state/router/router.facade';
import { TemplateService } from '../../services/utility/template.service';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { ContentBlockComponent } from './contentblocks.component';

@Component({
	selector: 'shop-newsletter',
	templateUrl: './newsletter.component.html',
	styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent extends ContentBlockComponent {
	constructor(changeDetector: ChangeDetectorRef, public pageFacade: PageFacade, routerFacade: RouterFacade, newspaperFacade: NewspaperFacade, templateService: TemplateService) {
		super(changeDetector, routerFacade, newspaperFacade, templateService);
	}
}

import { Injectable } from '@angular/core';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { IEntity } from 'src/models/entity.model';
import { IRatingExtract } from '../models/article.model';

export interface IEntityActions<TEntity extends IEntity> {
	load: ActionCreator<`${string} Load`, () => TypedAction<`${string} Load`>>;
	loaded: ActionCreator<
		`${string} Loaded`,
		(props: { list: TEntity[]; ratings?: IRatingExtract[] }) => {
			list: TEntity[];
			ratings?: IRatingExtract[];
		} & TypedAction<`${string} Loaded`>
	>;
	failed: ActionCreator<
		`${string} Failed`,
		(props: { message: string }) => {
			message: string;
		} & TypedAction<`${string} Failed`>
	>;
	selected: ActionCreator<
		`${string} Selected`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Selected`>
	>;
}

@Injectable()
export class EntityActions {
	public static create<TEntity extends IEntity>(stateName: string): IEntityActions<TEntity> {
		const load = createAction(`${stateName} Load`);
		const loaded = createAction(`${stateName} Loaded`, props<{ list: TEntity[]; ratings?: IRatingExtract[] }>());
		const failed = createAction(`${stateName} Failed`, props<{ message: string }>());
		const selected = createAction(`${stateName} Selected`, props<{ entity: TEntity }>());

		return {
			load,
			loaded,
			failed,
			selected,
		};
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPage } from 'src/models/page.model';
import { AppState, IPageState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPageActions } from './page.actions';
import { PageSelector } from './page.selectors';

@Injectable()
export class PageFacade extends EntityFacade<IPage, IPageState> {
	constructor(store: Store<AppState>, private pageSelector: PageSelector) {
		super(store, pageSelector);
	}

	public listHeader$ = this.store.select(this.pageSelector.listHeader);
	public listFooter$ = this.store.select(this.pageSelector.listFooter);
	public listMobile$ = this.store.select(this.pageSelector.listMobile);
	public listCheckout$ = this.store.select(this.pageSelector.listCheckout);
	public isCheckout$ = this.store.select(this.pageSelector.isCheckout);
	public isConfig$ = this.store.select(this.pageSelector.isConfig);
	public isSelected$ = this.store.select(this.pageSelector.isSelected);

	protected actions = fromPageActions;
}

import { createAction, props } from '@ngrx/store';

const stateName = '[Presentation]';
const acknowledgedTesting = createAction(`${stateName} Acknowledged Testing`);
const pageStyleChanged = createAction(`${stateName} Page Style Changed`, props<{ pageStyle: string }>());
const headerStyleChanged = createAction(`${stateName} Header Style Changed`, props<{ headerStyle: string }>());
const scrollTopChanged = createAction(`${stateName} Scroll Top Changed`, props<{ scrollTop: number }>());

export const fromPresentationActions = {
	acknowledgedTesting,
	pageStyleChanged,
	headerStyleChanged,
	scrollTopChanged,
};

import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IOrder } from '../../models/order.model';
import { Vendors, WindowRefService } from './window.service';

@Injectable()
export class BingService {
	constructor(private windowService: WindowRefService) {}

	public purchase(order: IOrder): Observable<(void | number)[]> {
		return forkJoin([
			this.emit('purchase', {
				revenue_value: order.primaryReceipt.totalPrice,
				currency: 'EUR',
			}),
		]);
	}

	private emit(event: string, options?: any): Observable<void | number> {
		if (this.windowService.hasVendorConsent(Vendors.Bing)) {
			if (!environment.production && event != null) {
				console.log('Bing Event:', event, options);
			}
			return of(this.windowService.nativeWindow.uetq.push('event', event, options));
		}
		return of(null);
	}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ArticleKind } from '../../models/article.model';
import { IBundle } from '../../models/bundle.model';
import { AppState, IBundleState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromBundleActions } from './bundle.actions';
import { BundleSelector } from './bundle.selectors';

@Injectable()
export class BundleFacade extends EntityFacade<IBundle, IBundleState> {
	constructor(store: Store<AppState>, private bundleSelector: BundleSelector) {
		super(store, bundleSelector);
	}

	public containsNewspaper$ = this.createBehaviourSubject(this.store.select(this.bundleSelector.containsNewspaper));
	public containsMagazine$ = this.createBehaviourSubject(this.store.select(this.bundleSelector.containsMagazine));

	public forArticleKind(articleKind: ArticleKind) {
		return this.store.select(this.bundleSelector.forArticleKind(articleKind));
	}

	public getDowngrades(bundle: IBundle) {
		return this.store.select(this.bundleSelector.getDowngrades(bundle));
	}

	public getDowngradeForArticleKind(bundle: IBundle, articleKind: ArticleKind) {
		return this.store.select(this.bundleSelector.getDowngradeForArticleKind(bundle, articleKind));
	}

	public getDiscount(articleKind: ArticleKind, price: number) {
		return this.store.select(this.bundleSelector.getDiscount(articleKind, price));
	}

	public select(entity: IBundle): void {
		if (entity == null || entity.isActive) {
			this.store.dispatch(this.actions.selected({ entity }));
		}
	}

	protected actions = fromBundleActions;
}

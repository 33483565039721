<ng-container *ngIf="!(folderFacade.isLoading$ | async) && !(folderFacade.list$ | async).length"> </ng-container>

<ng-container *ngIf="folderFacade.isLoading$ | async">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Mappen</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(folderFacade.isLoading$ | async) && (folderFacade.list$ | async).length">
	<div class="search-results" [@crossFade]>
		<div class="search-results--tables">
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Mappen</strong></p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let folder of folderFacade.list$ | async; trackBy: articleById" class="search-results--table__row">
						<div class="search-results--table__column search-results--table__column-image">
							<shop-picture *ngIf="folder.product.image != null" [image]="folder.product.image" [width]="130" [height]="130" (click)="onSelect(folder)"></shop-picture>
							<shop-picture *ngIf="folder.product.image == null && folder.product.mediaBrand.image != null" [image]="folder.product.mediaBrand.image" [width]="130" [height]="130" (click)="onSelect(folder)"></shop-picture>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div>
								<b>{{ folder.product.name }}</b>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div *ngIf="folder.product.shortDescription">{{ folder?.product?.shortDescription }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="availability"><b>&bull;</b> verfügbar</div>
							<ng-container *ngIf="pageFacade.isConfig$ | async; else noConfig">
								<ng-container *ngIf="bundleFacade.getDiscount(ArticleKinds.Folder, folder.product.price) | async as discount; else noReducedPrice">
									<div class="price price--strikethrough">{{ folder.product.price | currency: 'EUR' }}</div>
									<div class="price">{{ discount.price | currency: 'EUR' }}</div>
								</ng-container>
								<ng-template #noReducedPrice>
									<div class="price">{{ folder.product.price | currency: 'EUR' }}</div>
								</ng-template>
							</ng-container>
							<ng-template #noConfig>
								<div class="price">{{ folder.product.price | currency: 'EUR' }}</div>
							</ng-template>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<button mat-flat-button color="accent" (click)="onSelect(folder)">
								<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
								<span>Auswählen</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import _ from 'lodash';
import moment from 'moment';
import { forkJoin, from, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ArticleKind, IAccessoryArticle, IArticle, IFolder, IMagazine, IMediaArticle, INewspaper } from '../../models/article.model';
import { IBundle } from '../../models/bundle.model';
import { ICart, ICartTax } from '../../models/cart.model';
import { IOrder, PaymentKind, ShippingKind } from '../../models/order.model';
import { IArticlePosition, IBundlePosition, IPosition, PositionKind } from '../../models/position.model';
import { AddressForm, OccasionType } from '../../models/product.model';
import { IRegion } from '../../models/region.model';
import { Vendors, WindowRefService } from './window.service';

@Injectable()
export class GoogleService {
	constructor(private googleTagManagerService: GoogleTagManagerService, private windowService: WindowRefService) {}

	/*
	 * Google Analytics Custom Event Tracking
	 */

	public changeDate(date: Date): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_date', {
				custom: {
					date,
				},
			}),
		]);
	}

	public changeRegion(region: IRegion): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_region', {
				custom: {
					region,
				},
			}),
		]);
	}

	public viewBundle(bundle: IBundle): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('view_bundle', {
				custom: {
					bundle,
				},
			}),
		]);
	}

	public selectBundle(bundle: IBundle): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('select_bundle', {
				custom: {
					bundle,
				},
			}),
		]);
	}

	public startConfig(): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('start_config', {
				custom: {},
			}),
		]);
	}

	public changeFolder(folder: IFolder): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_folder', {
				custom: {
					folder,
				},
			}),
		]);
	}

	public selectNewspaper(newspaper: INewspaper): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('select_newspaper', {
				custom: {
					newspaper,
				},
			}),
		]);
	}

	public selectMagazine(magazine: IMagazine): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('select_magazine', {
				custom: {
					magazine,
				},
			}),
		]);
	}

	public changeOccasion(occasion: OccasionType): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_occasion', {
				custom: {
					occasion,
				},
			}),
		]);
	}

	public changeGiftee(giftee: string): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_giftee', {
				custom: {
					giftee,
				},
			}),
		]);
	}

	public changeGifteeBirthName(gifteeBirthName: string): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_giftee_birth_name', {
				custom: {
					gifteeBirthName,
				},
			}),
		]);
	}

	public changePartner(partner: string): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_partner', {
				custom: {
					partner,
				},
			}),
		]);
	}

	public changePartnerBirthName(partnerBirthName: string): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_partner_birth_name', {
				custom: {
					partnerBirthName,
				},
			}),
		]);
	}

	public changeAddressForm(addressForm: AddressForm): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_address_form', {
				custom: {
					addressForm,
				},
			}),
		]);
	}

	public changeCustomMessage(customMessage: string, originalMessage: string): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_custom_message', {
				custom: {
					customMessage,
					originalMessage,
				},
			}),
		]);
	}

	public changePaymentKind(paymentKind: PaymentKind): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_payment_kind', {
				custom: {
					paymentKind,
				},
			}),
		]);
	}

	public changeShippingKind(shippingKind: ShippingKind): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				custom: null,
			}),
			this.emit('change_shipping_kind', {
				custom: {
					shippingKind,
				},
			}),
		]);
	}

	/*
	 * Google Analytics Ecommerce Tracking
	 */

	public selectItem(article: IArticle): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('select_item', {
				ecommerce: {
					items: [this.articleToItem(article)],
				},
			}),
		]);
	}

	public viewItemList(articles: IArticle[]): Observable<void[]> {
		if (articles.length == 0) {
			return of([]);
		}

		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('view_item_list', {
				ecommerce: {
					items: _.flatten(articles.map(article => this.articleToItem(article))),
				},
			}),
		]);
	}

	public addPaymentInfo(cart: ICart): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('add_payment_info', {
				ecommerce: {
					currency: 'EUR',
					items: _.flatten(cart.positions.map(position => this.positionToItem(position))),
					payment_type: cart.paymentKind,
					value: cart.totalPrice,
				},
			}),
		]);
	}

	public addShippingInfo(cart: ICart): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('add_shipping_info', {
				ecommerce: {
					currency: 'EUR',
					items: _.flatten(cart.positions.map(position => this.positionToItem(position))),
					shipping_tier: cart.shippingKind,
					value: cart.totalPrice,
				},
			}),
		]);
	}

	public addToCart(position: IPosition): Observable<void[]> {
		const items = this.positionToItem(position);
		const value = _.sumBy(items, item => item.price);

		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('add_to_cart', {
				ecommerce: {
					currency: 'EUR',
					value,
					items,
				},
			}),
		]);
	}

	public removeFromCart(position: IPosition): Observable<void[]> {
		const items = this.positionToItem(position);
		const value = _.sumBy(items, item => item.price);

		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('remove_from_cart', {
				ecommerce: {
					currency: 'EUR',
					value,
					items,
				},
			}),
		]);
	}

	public viewItem(article: IArticle): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('view_item', {
				ecommerce: {
					currency: 'EUR',
					value: article.product.price,
					items: [this.articleToItem(article)],
				},
			}),
		]);
	}

	public viewCart(cart: ICart): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('view_cart', {
				ecommerce: {
					currency: 'EUR',
					value: cart.totalPrice,
					items: _.flatten(cart.positions.map(position => this.positionToItem(position))),
				},
			}),
		]);
	}

	public beginCheckout(cart: ICart): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('begin_checkout', {
				ecommerce: {
					currency: 'EUR',
					value: cart.totalPrice,
					items: _.flatten(cart.positions.map(position => this.positionToItem(position))),
				},
			}),
		]);
	}

	public purchase(order: IOrder, taxes: ICartTax[]): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('purchase', {
				ecommerce: {
					purchase: {
						transaction_id: order.number,
						value: order.primaryReceipt.totalPrice,
						shipping: this.getShippingValue(order.positions),
						tax: this.getTaxValue(taxes),
						currency: 'EUR',
						items: _.flatten(order.positions.map(position => this.positionToItem(position))),
					},
				},
			}),
		]);
	}

	public refund(order: IOrder): Observable<void[]> {
		return forkJoin([
			this.emit(null, {
				ecommerce: null,
			}),
			this.emit('refund', {
				ecommerce: {
					transaction_id: order.number,
					value: order.primaryReceipt.totalPrice,
					currency: 'EUR',
				},
			}),
		]);
	}

	private emit(event: string, options?: any): Observable<void> {
		if (this.windowService.hasVendorConsent(Vendors.GoogleAnalytics)) {
			if (!environment.production && event != null) {
				console.log('Google Event:', event, options);
			}

			let tag = options;

			if (tag == null) {
				tag = { event };
			} else if (event) {
				tag.event = event;
			}

			return from(this.googleTagManagerService.pushTag(tag));
		}
		return of(null);
	}

	private positionToItem(position: IPosition): any[] {
		switch (position.positionKind) {
			case PositionKind.Article:
				const articlePosition = position as IArticlePosition;
				return [this.articleToItem(articlePosition.article)];
			case PositionKind.Bundle:
				const bundlePosition = position as IBundlePosition;
				return bundlePosition.articles.map(article => this.articleToItem(article));
			default:
				return [];
		}
	}

	private articleToItem(article: IArticle): any {
		return {
			item_id: article._id,
			item_name: this.getArticleName(article),
			item_category: article.articleKind,
			price: article.product.price,
			quantity: 1,
			currency: 'EUR',
		};
	}

	private getArticleName(article: IArticle): string {
		switch (article.articleKind) {
			case ArticleKind.Stick:
			case ArticleKind.Certificate:
			case ArticleKind.Folder:
				const accessoryArticle = article as IAccessoryArticle;
				return accessoryArticle.product.name;
			default:
				const mediaArticle = article as IMediaArticle;
				return `${mediaArticle.product.name} vom ${moment(mediaArticle.publicationDate).format('DD.MM.YYYY')}`;
		}
	}

	private getShippingValue(positions: IPosition[]): number {
		const shippingPosition = positions.find(position => position.positionKind === PositionKind.Shipping);
		return shippingPosition ? shippingPosition.price : 0;
	}

	private getTaxValue(taxes: ICartTax[]): number {
		return taxes.reduce((total, tax) => total + tax.totalTax, 0);
	}
}

import { IBirthdayChronicle } from '../../models/article.model';
import { IBirthdayChronicleState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromBirthdayChronicleActions } from './birthday-chronicle.actions';

export const initialState = MediaArticleState.create<IBirthdayChronicle, IBirthdayChronicleState>(
	{
		skip: 0,
		top: 1,
	},
	{
		current: 365,
		min: 365,
		max: 365,
	}
);

export const birthdayChronicleReducer = EntityReducer.create<IBirthdayChronicle, IBirthdayChronicleState>(initialState, fromBirthdayChronicleActions);

import { isPlatformBrowser } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxUniversalRehydrateBrowserModule } from '@trellisorg/ngrx-universal-rehydrate';
import { MomentModule } from 'ngx-moment';
import { timer } from 'rxjs';
import { MainComponent } from 'src/components/main/main.component';
import { PageFacade } from 'src/state/page/page.facade';
import { environment } from '../environments/environment';
import { CartApiService } from '../services/api/cart.service';
import { ConfigurationApiService } from '../services/api/configuration.service';
import { CounterApiService } from '../services/api/counter.service';
import { ImageApiService } from '../services/api/image.service';
import { PageApiService } from '../services/api/page.service';
import { BingService } from '../services/utility/bing.service';
import { DocumentRefService } from '../services/utility/document.service';
import { GoogleService } from '../services/utility/google.service';
import { IpInfoService } from '../services/utility/ipinfo.service';
import { LayoutService } from '../services/utility/layout.service';
import { TemplateService } from '../services/utility/template.service';
import { UpdateService } from '../services/utility/update.service';
import { WindowRefService } from '../services/utility/window.service';
import { BookFacade } from '../state/book/book.facade';
import { BookSelector } from '../state/book/book.selectors';
import { BundleFacade } from '../state/bundle/bundle.facade';
import { BundleSelector } from '../state/bundle/bundle.selectors';
import { CartEffects } from '../state/cart/cart.effects';
import { CartFacade } from '../state/cart/cart.facade';
import { CartSelector } from '../state/cart/cart.selectors';
import { CdFacade } from '../state/cd/cd.facade';
import { CdSelector } from '../state/cd/cd.selectors';
import { CertificateFacade } from '../state/certificate/certificate.facade';
import { CertificateSelector } from '../state/certificate/certificate.selectors';
import { ChronicleFacade } from '../state/chronicle/chronicle.facade';
import { ChronicleSelector } from '../state/chronicle/chronicle.selectors';
import { CoinFacade } from '../state/coin/coin.facade';
import { CoinSelector } from '../state/coin/coin.selectors';
import { ConfigurationEffects } from '../state/configuration/configuration.effects';
import { ConfigurationFacade } from '../state/configuration/configuration.facade';
import { ConfigurationSelector } from '../state/configuration/configuration.selectors';
import { CrosssellingEffects } from '../state/crossselling/crossselling.effects';
import { ExternalLibrariesEffects } from '../state/externallibraries/externallibraries.effects';
import { ExternalLibrariesFacade } from '../state/externallibraries/externallibraries.facade';
import { ExternalLibrariesSelector } from '../state/externallibraries/externallibraries.selectors';
import { FolderFacade } from '../state/folder/folder.facade';
import { FolderSelector } from '../state/folder/folder.selectors';
import { ImageEffects } from '../state/image/image.effects';
import { ImageFacade } from '../state/image/image.facade';
import { ImageSelector } from '../state/image/image.selectors';
import { MagazineFacade } from '../state/magazine/magazine.facade';
import { MagazineSelector } from '../state/magazine/magazine.selectors';
import { NewspaperFacade } from '../state/newspaper/newspaper.facade';
import { NewspaperSelector } from '../state/newspaper/newspaper.selectors';
import { PageEffects } from '../state/page/page.effects';
import { PageSelector } from '../state/page/page.selectors';
import { PresentationEffects } from '../state/presentation/presentation.effects';
import { PresentationFacade } from '../state/presentation/presentation.facade';
import { PresentationSelector } from '../state/presentation/presentation.selectors';
import { actionReducerMap } from '../state/reducers';
import { RouterEffects } from '../state/router/router.effects';
import { RouterFacade } from '../state/router/router.facade';
import { RouterSelector } from '../state/router/router.selectors';
import { StickFacade } from '../state/stick/stick.facade';
import { StickSelector } from '../state/stick/stick.selectors';
import { SharedModule } from './shared.module';

@NgModule({
	declarations: [MainComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'shop' }),
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FormsModule,
		MatIconModule,
		MatFormFieldModule,
		MatSidenavModule,
		MatButtonModule,
		MatListModule,
		MatToolbarModule,
		MatInputModule,
		MomentModule,
		EffectsModule.forRoot([RouterEffects, PresentationEffects, ImageEffects, ConfigurationEffects, ExternalLibrariesEffects, PageEffects, CartEffects, CrosssellingEffects]),
		StoreModule.forRoot(actionReducerMap),
		NgrxUniversalRehydrateBrowserModule.forRoot({
			stores: [],
		}),
		StoreDevtoolsModule.instrument(),
		StoreRouterConnectingModule.forRoot(),
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: environment.serviceWorker,
			registrationStrategy: 'registerWhenStable:30000',
		}),
		BrowserTransferStateModule,
		RouterModule.forRoot(
			[
				{
					path: '',
					loadChildren: () => import('./startpage.module').then(m => m.StartpageModule),
					data: {
						pageStyle: 'startpage',
						headerStyle: 'header--has-bg',
					},
				},
				{
					path: 'suche',
					loadChildren: () => import('./searchpage.module').then(m => m.SearchpageModule),
					data: {
						pageStyle: 'searchpage',
						headerStyle: 'header--has-bg',
					},
				},
				{
					path: 'suche/:searchDate',
					loadChildren: () => import('./searchpage.module').then(m => m.SearchpageModule),
					data: {
						pageStyle: 'searchpage',
						headerStyle: 'header--has-bg',
					},
				},
				{
					path: 'geschenkideen/:articleKind',
					loadChildren: () => import('./giftpage.module').then(m => m.GiftpageModule),
					data: {
						pageStyle: 'giftpage',
						headerStyle: null,
					},
				},
				{
					path: 'zeitung',
					loadChildren: () => import('./newspaperpage.module').then(m => m.NewspaperPageModule),
					data: {
						pageStyle: 'articlepage',
						headerStyle: null,
					},
				},
				{
					path: 'magazin',
					loadChildren: () => import('./magazinepage.module').then(m => m.MagazinePageModule),
					data: {
						pageStyle: 'articlepage',
						headerStyle: null,
					},
				},
				{
					path: 'kundenservice/hilfe',
					loadChildren: () => import('./faqpage.module').then(m => m.FaqpageModule),
					data: {
						pageStyle: 'faqpage',
						headerStyle: null,
					},
				},
				{
					path: 'rechtliches/datenschutz',
					loadChildren: () => import('./dataprotectionpage.module').then(m => m.DataprotectionpageModule),
					data: {
						pageStyle: 'dataprotectionpage',
						headerStyle: null,
					},
				},
				{
					path: 'konfiguration',
					loadChildren: () => import('./configpage.module').then(m => m.ConfigPageModule),
					data: {
						pageStyle: 'configpage',
						headerStyle: null,
					},
				},
				{
					path: 'warenkorb',
					loadChildren: () => import('./cartpage.module').then(m => m.CartPageModule),
					data: {
						pageStyle: 'cartpage',
						headerStyle: null,
					},
				},
				{
					path: 'kasse',
					loadChildren: () => import('./checkoutpage.module').then(m => m.CheckoutPageModule),
					data: {
						pageStyle: 'checkoutpage',
						headerStyle: null,
					},
				},
				{
					path: 'bestellung',
					loadChildren: () => import('./orderpage.module').then(m => m.OrderPageModule),
					data: {
						pageStyle: 'checkoutpage',
						headerStyle: null,
					},
				},

				// SEO-ROUTES
				{
					path: 'shop',
					redirectTo: 'suche',
				},
				{
					path: 'shop/:date',
					redirectTo: 'suche/:date',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-40-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-50-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-60-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-65-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-70-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-75-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-80-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-85-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-90-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},
				{
					path: 'seo/geschenk-zum-geburtstag/zeitung-als-geschenk-zum-100-geburtstag.php',
					redirectTo: 'geschenkanlass/geburtstag',
				},

				{
					path: 'seo/geschenk-zum-25-hochzeitstag/zeitung-als-geschenk-zur-silberhochzeit.php',
					redirectTo: 'geschenkanlass/hochzeitstag',
				},
				{
					path: 'seo/geschenk-zum-40-hochzeitstag/zeitung-als-geschenk-zur-rubinhochzeit.php',
					redirectTo: 'geschenkanlass/hochzeitstag',
				},
				{
					path: 'seo/geschenk-zur-goldhochzeit/zeitung-als-geschenk-zur-goldenen-hochzeit.php',
					redirectTo: 'geschenkanlass/hochzeitstag',
				},
				{
					path: 'seo/geschenk-zur-diamanthochzeit/zeitung-als-geschenk-zur-diamantenen-hochzeit.php',
					redirectTo: 'geschenkanlass/hochzeitstag',
				},

				// CATCH-ALL
				{
					path: ':pageUrl',
					loadChildren: () => import('./textpage.module').then(m => m.TextpageModule),
					data: {
						pageStyle: 'textpage',
						headerStyle: null,
					},
				},
			],
			{
				initialNavigation: 'enabledBlocking',
				scrollPositionRestoration: 'enabled',
			}
		),
		SharedModule,
	],
	providers: [
		IpInfoService,
		UpdateService,
		LayoutService,
		WindowRefService,
		DocumentRefService,
		GoogleService,
		BingService,
		TemplateService,
		CounterApiService,

		RouterSelector,
		RouterFacade,

		PresentationSelector,
		PresentationFacade,

		ExternalLibrariesSelector,
		ExternalLibrariesFacade,

		ImageApiService,
		ImageSelector,
		ImageFacade,

		ConfigurationApiService,
		ConfigurationSelector,
		ConfigurationFacade,

		PageApiService,
		PageSelector,
		PageFacade,

		CartApiService,
		CartSelector,
		CartFacade,

		NewspaperSelector,
		NewspaperFacade,

		BundleSelector,
		BundleFacade,

		MagazineSelector,
		MagazineFacade,

		FolderSelector,
		FolderFacade,

		CertificateSelector,
		CertificateFacade,

		ChronicleSelector,
		ChronicleFacade,

		CoinSelector,
		CoinFacade,

		BookSelector,
		BookFacade,

		CdSelector,
		CdFacade,

		StickSelector,
		StickFacade,
	],
	bootstrap: [MainComponent],
})
export class ShopModule {
	constructor(pageFacade: PageFacade, configurationFacade: ConfigurationFacade, presentationFacade: PresentationFacade, @Inject(PLATFORM_ID) private platformId: Object) {
		pageFacade.load();
		presentationFacade.changeScrollTop();

		if (isPlatformBrowser(platformId)) {
			timer(0, 60000).subscribe(() => configurationFacade.loadShippingInformation());
		}
	}
}

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, IExternalLibrariesState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable()
export class ExternalLibrariesSelector extends BaseSelector<IExternalLibrariesState> {
	protected stateSelector = (state: AppState): IExternalLibrariesState => state.externalLibraries;

	public isShopvoteReputationLoaded = createSelector(this.selectState, state => state.isShopvoteReputationLoaded);
	public isShopvoteReviewsLoaded = createSelector(this.selectState, state => state.isShopvoteReviewsLoaded);
	public isYoutubeLoaded = createSelector(this.selectState, state => state.isYoutubeLoaded);
}

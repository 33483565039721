import { RouterReducerState } from '@ngrx/router-store';
import { FormGroupState } from 'ngrx-forms';
import { IArticle, IBirthdayBook, IBirthdayChronicle, IBook, ICd, ICertificate, IChronicle, ICoin, IDvd, IFolder, IMagazine, IMediaArticle, INewspaper, IPaging, IRatingExtract, IStick, IYearBook } from 'src/models/article.model';
import { IEntity } from 'src/models/entity.model';
import { IPage } from 'src/models/page.model';
import { IPublicationCycle } from 'src/models/publication-cycle.model';
import { IAddress } from '../models/address.model';
import { IBundle } from '../models/bundle.model';
import { ICart } from '../models/cart.model';
import { IConfiguration, IPaymentInformation, IShippingInformation } from '../models/configuration.model';
import { IContactInfo, SalutationKind } from '../models/contact-info.model';
import { IFaqGroup } from '../models/faq.model';
import { IHeroTeaser } from '../models/hero-teaser.model';
import { IImage } from '../models/image.model';
import { IOrder, ShippingKind } from '../models/order.model';
import { IPageTeaser } from '../models/page-teaser.model';
import { IPosition } from '../models/position.model';
import { IRegion } from '../models/region.model';
import { IShippingRange } from '../services/api/configuration.service';
export interface AppState {
	router: RouterReducerState;
	faq: IFaqState;
	page: IPageState;
	publicationCycle: IPublicationCycleState;
	externalLibraries: IExternalLibrariesState;
	heroTeaser: IHeroTeaserState;
	pageTeaser: IPageTeaserState;
	image: IImageState;
	presentation: IPresentationState;
	region: IRegionState;
	bundle: IBundleState;
	order: IOrderState;
	cart: ICartState;
	address: IAddressState;
	newspaper: INewspaperState;
	magazine: IMagazineState;
	folder: IFolderState;
	certificate: ICertificateState;
	coin: ICoinState;
	book: IBookState;
	birthdayBook: IBirthdayBookState;
	yearBook: IYearBookState;
	cd: ICdState;
	dvd: IDvdState;
	chronicle: IChronicleState;
	birthdayChronicle: IBirthdayChronicleState;
	stick: IStickState;
	configuration: IConfigurationState;
	crossselling: ICrossSellingState;
}

export interface ICrossSellingState {
	articles: IArticle[];
}

export type ICartState = ICart & {
	selected: IPosition;
	isLoading: boolean;
	isDisabled: boolean;
	error: string;
	isReceiverDiffering: boolean;
	isPackstation: boolean;
	receiverForm: FormGroupState<IContactInfo>;
	payerForm: FormGroupState<IContactInfo>;
	customerForm: FormGroupState<ICustomer>;
};

export type IAddressState = {
	paging: IPaging;
	suggestedPayerList: IAddress[];
	suggestedReceiverList: IAddress[];
	isSuggesting: boolean;
	error: string;
};

export enum CheckoutStepEnum {
	Payment = 'bezahlung',
	Shipping = 'versand',
	Confirmation = 'fertig',
}

export enum ConfigStepEnum {
	Newspaper = 'zeitung',
	Magazine = 'magazin',
	Folder = 'mappe',
	Certificate = 'urkunde',
	Preview = 'vorschau',
}

export interface ICustomer {
	salutationKind: SalutationKind;
	telephone: string;
	email: string;
}

export interface INewspaperState extends IMediaArticleState<INewspaper> {
	isExtending: boolean;
	date: Date;
	isFilterVisible: boolean;
	name: string;
	publicationCycle: string;
}

export interface IMagazineState extends IMediaArticleState<IMagazine> {
	isExtending: boolean;
}

export interface IDayRange {
	current: number;
	min: number;
	max: number;
}

export interface IRegionState extends IEntityState<IRegion> {
	name: string;
}

export interface IBundleState extends IEntityState<IBundle> {}

export interface IOrderState extends IEntityState<IOrder> {}

export interface IFolderState extends IArticleState<IFolder> {
	preselected: IFolder;
}

export interface ICertificateState extends IArticleState<ICertificate> {
	originalMessage: string;
	customMessage: string;
}

export interface ICoinState extends IMediaArticleState<ICoin> {}

export interface IBookState extends IMediaArticleState<IBook> {}

export interface IBirthdayBookState extends IMediaArticleState<IBirthdayBook> {}

export interface IYearBookState extends IMediaArticleState<IYearBook> {}

export interface ICdState extends IMediaArticleState<ICd> {}

export interface IDvdState extends IMediaArticleState<IDvd> {}

export interface IChronicleState extends IMediaArticleState<IChronicle> {}

export interface IBirthdayChronicleState extends IMediaArticleState<IBirthdayChronicle> {}

export interface IStickState extends IArticleState<IStick> {}

export interface IListableState<T> {
	items: { [key: string]: T };
	ratings: { [key: string]: IRatingExtract };
}

export interface ILoadableState {
	isLoading: boolean;
	error: string;
}

export interface ISelectableState<T> {
	selected: T;
}

export interface IEntityState<TEntity extends IEntity> extends IListableState<TEntity>, ISelectableState<TEntity>, ILoadableState {}

export interface IArticleState<TArticle extends IArticle> extends IEntityState<TArticle> {
	paging: IPaging;
}

export interface IMediaArticleState<TMediaArticle extends IMediaArticle> extends IArticleState<TMediaArticle> {
	dayRange: IDayRange;
}

export interface IPresentationState {
	pageStyle: string;
	headerStyle: string;
	scrollTop: number;
	testingAcknowledgedAt: string;
}

export interface IExternalLibrariesState {
	isShopvoteReputationLoaded: boolean;
	isShopvoteReviewsLoaded: boolean;
	isYoutubeLoaded: boolean;
}

export interface IPageState extends IEntityState<IPage> {
	isSelected: boolean;
}

export interface IFaqState extends IEntityState<IFaqGroup> {}

export interface IHeroTeaserState extends IEntityState<IHeroTeaser> {}

export interface IPageTeaserState extends IEntityState<IPageTeaser> {}

export interface IImageState extends IEntityState<IImage> {}

export interface IPublicationCycleState extends IEntityState<IPublicationCycle> {}

export interface IConfigurationState extends IEntityState<IConfiguration> {
	shippingInformation: IShippingInformation;
	paymentInformation: IPaymentInformation;
	estimatedDeliveries: IEstimatedDelivery[];
}

export interface IEstimatedDelivery {
	shippingKind: ShippingKind;
	shippingRange: IShippingRange;
}

export class EntityState {
	public static create<TEntity extends IEntity, TEntityState extends IEntityState<TEntity>>(): TEntityState {
		const state: IEntityState<TEntity> = {
			items: {},
			ratings: {},
			selected: null,
			isLoading: false,
			error: null,
		};

		return state as TEntityState;
	}
}

export class ArticleState {
	public static create<TArticle extends IArticle, TArticleState extends IArticleState<TArticle>>(paging: IPaging): TArticleState {
		const state: IArticleState<TArticle> = { ...EntityState.create<TArticle, TArticleState>(), paging };

		return state as TArticleState;
	}
}

export class MediaArticleState {
	public static create<TMediaArticle extends IMediaArticle, TMediaArticleState extends IMediaArticleState<TMediaArticle>>(paging: IPaging, dayRange: IDayRange): TMediaArticleState {
		const state: IMediaArticleState<TMediaArticle> = { ...ArticleState.create<TMediaArticle, TMediaArticleState>(paging), dayRange };

		return state as TMediaArticleState;
	}
}

import { AfterViewInit, ChangeDetectorRef, Component, Directive, Input } from '@angular/core';
import moment from 'moment';
import { first } from 'rxjs';
import { BlockKind, IPageBlock, IPageBlockParameter, IPageBlockQueryParameter } from '../../models/page.model';
import { TemplateService } from '../../services/utility/template.service';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { Vendors } from 'src/services/utility/window.service';

@Component({
	selector: 'shop-contentblocks',
	templateUrl: './contentblocks.component.html',
})
export class ContentBlocksComponent {
	@Input()
	public blocks: IPageBlock[];
	public BlockKinds = BlockKind;

	public isLast(index: number): boolean {
		return index === this.blocks.length - 1;
	}
}

@Directive()
export abstract class ContentBlockComponent implements AfterViewInit {
	@Input()
	public parameters: IPageBlockParameter[];
	@Input()
	public queryParameters: IPageBlockQueryParameter[];
	public params: { [key: string]: any } = {};
	public Vendors = Vendors;

	constructor(private changeDetector: ChangeDetectorRef, public routerFacade: RouterFacade, public newspaperFacade: NewspaperFacade, private templateService: TemplateService) {}

	public ngAfterViewInit() {
		this.parameters?.forEach(p => (this.params[p.name] = p.value));

		if (this.queryParameters != null && this.parameters != null) {
			this.routerFacade.queryParams$.pipe(first()).subscribe(queryParams => {
				for (const queryParameter of this.queryParameters) {
					this.params[queryParameter.name] = queryParams[queryParameter.name] || queryParameter.default || null;

					if (queryParameter.parseAsYear) {
						this.newspaperFacade.changeDate(this.parseAsYear(this.params[queryParameter.name]));
					} else if (queryParameter.parseAsDate) {
						this.newspaperFacade.changeDate(this.parseAsDate(this.params[queryParameter.name]));
					}
				}

				for (const parameter of this.parameters) {
					this.params[parameter.name] = this.templateService.compile(parameter.value, this.params);
				}
			});
		}

		this.changeDetector.detectChanges();
	}

	public parseAsDate(input: string): Date {
		const year = +input > 0 ? +input : null;
		return year == null
			? moment().add(-50, 'years').toDate()
			: moment()
					.year(+year)
					.toDate();
	}

	public parseAsYear(input: string): Date {
		return moment()
			.add(+input, 'years')
			.toDate();
	}
}

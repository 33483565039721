import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable, of, tap } from 'rxjs';
import { IImage } from 'src/models/image.model';
import { AppState, IImageState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromImageActions } from './image.actions';
import { ImageSelector } from './image.selectors';

@Injectable()
export class ImageFacade extends EntityFacade<IImage, IImageState> {
	constructor(store: Store<AppState>, imageSelector: ImageSelector) {
		super(store, imageSelector);
	}

	protected actions = fromImageActions;

	public loadSingleById(id: string): Observable<IImage> {
		if (id == null) {
			return of(null);
		}

		const result = this.store.select(this.entitySelector.getItem(id));

		result
			.pipe(
				filter(image => image == undefined),
				first(),
				tap(() => this.store.dispatch(this.actions.loadImage({ id })))
			)
			.subscribe();

		return result;
	}
}

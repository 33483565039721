import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromCartActions } from '../cart/cart.actions';
import { fromCrosssellingActions } from './crossselling.actions';

@Injectable()
export class CrosssellingEffects extends BaseEffects {
	constructor(actions$: Actions, store: Store<AppState>) {
		super(actions$, store);
	}

	public onCartUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.updated),
			map(() => fromCrosssellingActions.load())
		)
	);
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { IChronicle } from '../../models/article.model';
import { IChronicleState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { ChronicleFacade } from '../../state/chronicle/chronicle.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-chronicle-detaildialog',
	templateUrl: 'chronicle-detaildialog.component.html',
	animations: [crossFade],
})
export class ChronicleDetailDialogComponent extends DetailDialogComponent<IChronicle, IChronicleState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: IChronicle }, cartFacade: CartFacade, chronicleFacade: ChronicleFacade) {
		super(data, cartFacade, chronicleFacade);
	}
}

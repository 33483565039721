import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LayoutService {
	private drawerSubject$ = new Subject<void>();

	public onCloseDrawer(func: () => void): void {
		this.drawerSubject$.pipe(tap(() => func())).subscribe();
	}

	public closeDrawer(): void {
		return this.drawerSubject$.next();
	}
}

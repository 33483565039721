import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { AppState, IRegionState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromRegionActions } from './region.actions';
import { RegionSelector } from './region.selectors';

@Injectable()
export class RegionFacade extends EntityFacade<IRegion, IRegionState> {
	constructor(store: Store<AppState>, private regionSelector: RegionSelector) {
		super(store, regionSelector);
	}

	public name$ = this.store.select(this.regionSelector.name);
	public selectedAddress$ = this.store.select(this.regionSelector.selectedAddress);

	public changeName(name: string) {
		this.store.dispatch(fromRegionActions.nameChanged({ name }));
	}

	protected actions = fromRegionActions;
}

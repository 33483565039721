import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from './app.state';

@Injectable()
export abstract class BaseFacade {
	constructor(protected store: Store<AppState>) {}

	protected createBehaviourSubject<T>(obs$: Observable<T>, initialValue: T = null): BehaviorSubject<T> {
		const subject = new BehaviorSubject<T>(initialValue);
		obs$.subscribe(subject);
		return subject;
	}
}

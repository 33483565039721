<ng-container *ngIf="configurationFacade.estimatedDelivery(shippingType.shippingKind) | async as estimatedDelivery">
	<ng-container *ngIf="newspaperFacade.dateOfCurrentYear$ | async as date">
		<ng-container *ngIf="configurationFacade.suggestShippingKind(shippingType.shippingKind, date) | async as suggestedShippingKind">
			<ng-container *ngIf="shippingType.shippingDaysMax == 0"> Abholung Mo-Fr bis {{ shippingType.shippedBeforeHour }} Uhr </ng-container>
			<ng-container *ngIf="shippingType.shippingDaysMax > 0 && shippingType.shippingDaysMin < shippingType.shippingDaysMax">
				Bei Bestelleingang Mo-Fr bis {{ shippingType.shippedBeforeHour }} Uhr: {{ shippingType.shippingDaysMin }} - {{ shippingType.shippingDaysMax }} Werktag(e)
				<div>
					<ng-container *ngIf="shippingType.shippingKind != ShippingKinds.Pickup">Lieferung</ng-container><ng-container *ngIf="shippingType.shippingKind == ShippingKinds.Pickup">Abholung</ng-container> zwischen
					<u [class.text-warning]="shippingType.shippingKind != suggestedShippingKind" [class.text-success]="shippingType.shippingKind == suggestedShippingKind">
						{{ estimatedDelivery.shippingRange.from | amDateFormat: 'DD.MM.YYYY' }} und {{ estimatedDelivery.shippingRange.until | amDateFormat: 'DD.MM.YYYY' }}
					</u>
				</div>
			</ng-container>
			<ng-container *ngIf="shippingType.shippingDaysMax > 0 && shippingType.shippingDaysMin == shippingType.shippingDaysMax">
				Bei Bestelleingang Mo-Fr bis {{ shippingType.shippedBeforeHour }} Uhr: {{ shippingType.shippingDaysMin }} Werktag(e)
				<div>
					<ng-container *ngIf="shippingType.shippingKind != ShippingKinds.Pickup">Lieferung</ng-container><ng-container *ngIf="shippingType.shippingKind == ShippingKinds.Pickup">Abholung</ng-container> am
					<u [class.text-warning]="shippingType.shippingKind != suggestedShippingKind" [class.text-success]="shippingType.shippingKind == suggestedShippingKind">
						{{ estimatedDelivery.shippingRange.from | amDateFormat: 'DD.MM.YYYY' }}
					</u>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>

<section class="usps">
	<div class="container">
		<div class="usps-top-wrapper">
			<shop-shopvote-badge-allvotes></shop-shopvote-badge-allvotes>
			<div class="usps-top-text">
				<h2 class="mat-h2">Unser Versprechen</h2>
				<p>Du profitierst von</p>
				<ul>
					<li>fast 30 Jahren Erfahrung</li>
					<li>unserem eigenen Archiv</li>
					<li>unserem gut ausgebildeten und freundlichen Team</li>
				</ul>
				<p>Dies bestätigen mehr als 1.000 positive Bewertungen pro Jahr.</p>
			</div>
		</div>
		<div class="usps--grid">
			<div class="usps--grid__item">
				<div class="usps--grid__item-headline">
					<mat-icon svgIcon="mdi-certificate-outline"></mat-icon>
					<h3 class="mat-h3">Mit&nbsp;Echtheits&shy;zertifikat</h3>
				</div>
				<p>Unsere Zeitungen sind zu 100 % Original — Kein Nachdruck, keine Kopie.</p>
			</div>
			<div class="usps--grid__item">
				<div class="usps--grid__item-headline">
					<mat-icon svgIcon="mdi-gift-outline"></mat-icon>
					<h3 class="mat-h3">Individuelles Geschenk</h3>
				</div>
				<p>Auf Wunsch mit persönlicher Geschenkurkunde in einer geschmackvollen Geschenkmappe.</p>
			</div>
			<div class="usps--grid__item">
				<div class="usps--grid__item-headline">
					<mat-icon svgIcon="mdi-truck-fast-outline"></mat-icon>
					<h3 class="mat-h3">Schnelle Lieferung</h3>
				</div>
				<p>Alle Zeitungen im eigenen Archiv sind sofort verfügbar und versandfertig.</p>
			</div>
			<div class="usps--grid__item">
				<div class="usps--grid__item-headline">
					<mat-icon svgIcon="mdi-receipt"></mat-icon>
					<h3 class="mat-h3">Rechnungskauf</h3>
				</div>
				<p>Bezahle erst nach Erhalt Deiner Bestellung.</p>
			</div>
		</div>
	</div>
</section>

import { Injectable } from '@angular/core';
import { Action, ActionCreator, ActionReducer, createReducer, on, ReducerTypes } from '@ngrx/store';
import { IEntity } from 'src/models/entity.model';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { IRatingExtract } from '../models/article.model';
import { IEntityState } from './app.state';
import { IEntityActions } from './entity.actions';

@Injectable()
export class EntityReducer {
	public static create<TEntity extends IEntity, TEntityState extends IEntityState<TEntity>>(
		initialState: TEntityState,
		actions: IEntityActions<TEntity>,
		rehydrationProperty: string = null,
		...ons: ReducerTypes<TEntityState, ActionCreator[]>[]
	): ActionReducer<TEntityState, Action> {
		if (rehydrationProperty != null) {
			return createRehydrateReducer(
				rehydrationProperty,
				initialState,
				on(actions.load, state => EntityReducer.onLoad(state)),
				on(actions.loaded, (state, { list, ratings }) => EntityReducer.onLoaded(state, list, ratings)),
				on(actions.failed, (state, { message }) => EntityReducer.onFailed(state, message)),
				on(actions.selected, (state, { entity }) => EntityReducer.onSelected(state, entity)),
				...ons
			);
		} else {
			return createReducer(
				initialState,
				on(actions.load, state => EntityReducer.onLoad(state)),
				on(actions.loaded, (state, { list, ratings }) => EntityReducer.onLoaded(state, list, ratings)),
				on(actions.failed, (state, { message }) => EntityReducer.onFailed(state, message)),
				on(actions.selected, (state, { entity }) => EntityReducer.onSelected(state, entity)),
				...ons
			);
		}
	}

	public static onLoad<TEntity extends IEntity, TState extends IEntityState<TEntity>>(state: TState): TState {
		return {
			...state,
			isLoading: true,
		};
	}

	public static onLoaded<TEntity extends IEntity, TState extends IEntityState<TEntity>>(state: TState, list: TEntity[], ratings?: IRatingExtract[]): TState {
		const result: TState = {
			...state,
			items: list.reduce((obj, entity) => ({ ...obj, [entity._id]: entity }), {}),
			ratings: ratings != null ? ratings.reduce((obj, rating) => ({ ...obj, [rating.articleId]: rating }), {}) : state.ratings,
			isLoading: false,
			error: null,
		};

		return result;
	}

	public static onSelected<TEntity extends IEntity, TState extends IEntityState<TEntity>>(state: TState, entity: TEntity): TState {
		return {
			...state,
			selected: entity,
		};
	}

	public static onFailed<TEntity extends IEntity, TState extends IEntityState<TEntity>>(state: TState, error: string): TState {
		return {
			...state,
			isLoading: false,
			items: [],
			error: error || 'Es ist ein unbekannter Fehler aufgetreten',
		};
	}

	public static onReset<TEntity extends IEntity, TState extends IEntityState<TEntity>>(state: TState): TState {
		return {
			...state,
			selected: null,
		};
	}
}

import { on } from '@ngrx/store';
import moment from 'moment';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { IPresentationState } from '../app.state';
import { fromPresentationActions } from './presentation.actions';

export const initialState: IPresentationState = {
	pageStyle: null,
	headerStyle: null,
	scrollTop: 0,
	testingAcknowledgedAt: null,
};

export const presentationReducer = createRehydrateReducer(
	'presentation',
	initialState,
	on(fromPresentationActions.pageStyleChanged, (state, { pageStyle }) => ({ ...state, pageStyle })),
	on(fromPresentationActions.headerStyleChanged, (state, { headerStyle }) => ({ ...state, headerStyle })),
	on(fromPresentationActions.scrollTopChanged, (state, { scrollTop }) => ({ ...state, scrollTop })),
	on(fromPresentationActions.acknowledgedTesting, state => ({ ...state, testingAcknowledgedAt: moment().startOf('day').toISOString() }))
);

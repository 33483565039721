import { createReducer, on } from '@ngrx/store';
import { ICrossSellingState } from '../app.state';
import { fromCrosssellingActions } from './crossselling.actions';

const initialState: ICrossSellingState = {
	articles: [],
};

export const crosssellingReducer = createReducer(
	initialState,
	on(fromCrosssellingActions.load, state => ({ ...state, articles: [] })),
	on(fromCrosssellingActions.loaded, (state, { articles }) => ({ ...state, articles: [...state.articles, ...articles] }))
);

<div class="container">
	<div class="grid grid--flex">
		<ng-container *ngFor="let element of params.elements">
			<a [routerLink]="element.link">
				<mat-card>
					<shop-picture mat-card-image [image]="imageFacade.loadSingleById(element.image) | async" [width]="326" [height]="184"></shop-picture>
					<mat-card-content>
						{{ element.content }}
					</mat-card-content>
				</mat-card>
			</a>
		</ng-container>
	</div>
</div>

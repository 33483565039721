import { on } from '@ngrx/store';
import { IMagazine } from '../../models/article.model';
import { IMagazineState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromMagazineActions } from './magazine.actions';

export const initialState = MediaArticleState.create<IMagazine, IMagazineState>(
	{
		skip: 0,
		top: 20,
	},
	{
		current: 4,
		min: 4,
		max: 1024,
	}
);

export const magazineReducer = EntityReducer.create<IMagazine, IMagazineState>(
	initialState,
	fromMagazineActions,
	'magazine',
	on(fromMagazineActions.load, state => ({ ...state, isLoading: true, paging: { ...state.paging, top: initialState.paging.top }, dayRange: { ...state.dayRange, current: state.dayRange.min } })),
	on(fromMagazineActions.loaded, (state, { list }) => ({ ...EntityReducer.onLoaded(state, list), isExtending: false })),
	on(fromMagazineActions.extended, state => ({ ...state, isExtending: false })),
	on(fromMagazineActions.dateChanged, (state, { date }) => ({ ...state, date })),
	on(fromMagazineActions.pagingChanged, (state, { paging }) => ({ ...state, paging, isExtending: true })),
	on(fromMagazineActions.dayRangeChanged, (state, { dayRange }) => ({ ...state, dayRange, isExtending: true })),
	on(fromMagazineActions.regionChanged, (state, { region }) => ({ ...state, region })),
	on(fromMagazineActions.isFilterVisibleChanged, (state, { isFilterVisible }) => ({ ...state, isFilterVisible })),
	on(fromMagazineActions.nameChanged, (state, { name }) => ({ ...state, name })),
	on(fromMagazineActions.loadMagazine, state => ({ ...state, isLoading: true })),
	on(fromMagazineActions.loadedMagazine, (state, { magazine }) => ({ ...state, isLoading: false, selected: magazine }))
);

<section class="newsletter" *ngIf="(routerFacade.url$ | async) != '/kasse'">
	<div class="container">
		<div class="newsletter--left">
			<h3 class="mat-h3">Nichts mehr verpassen</h3>
			<p>Bleibe auf dem Laufenden und melde Dich für unseren kostenlosen Newsletter an!</p>
		</div>
		<div class="newsletter--right">
			<form action="">
				<mat-form-field appearance="outline">
					<mat-label>Deine E-Mail-Adresse</mat-label>
					<input matInput placeholder="Deine E-Mail-Adresse" autocomplete="off" type="email" />
				</mat-form-field>
				<button mat-flat-button color="primary">
					<mat-icon svgIcon="mdi-email-outline"></mat-icon>
					Anmeldung bestätigen
				</button>
			</form>
			<small> Informationen darüber, wie Geschenkzeitung mit Deinen Daten umgeht, findest Du in unserer <a href="#">Datenschutzerklärung</a>. Du kannst dich jederzeit kostenfrei abmelden. </small>
		</div>
	</div>
</section>

import { on } from '@ngrx/store';
import { IImage } from 'src/models/image.model';
import { EntityState, IImageState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromImageActions } from './image.actions';

export const initialState = EntityState.create<IImage, IImageState>();
export const imageReducer = EntityReducer.create<IImage, IImageState>(
	initialState,
	fromImageActions,
	null,
	on(fromImageActions.loadedImages, (state, { images }) => ({
		...state,
		items: { ...state.items, ...images.reduce((obj, image) => ({ ...obj, [image._id]: image }), {}) },
	}))
);

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ShippingKind } from '../../models/order.model';
import { CartFacade } from '../../state/cart/cart.facade';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';

@Component({
	selector: 'shop-shippingkind-selector',
	templateUrl: './shippingkind-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingKindSelectorComponent {
	public ShippingKinds = ShippingKind;
	@Output() public shippingKindChange = new EventEmitter<ShippingKind>();

	constructor(public configurationFacade: ConfigurationFacade, public cartFacade: CartFacade) {}

	public onChange(shippingKind: ShippingKind): void {
		this.cartFacade.changeShippingKind(shippingKind);
		this.shippingKindChange.emit(shippingKind);
	}
}

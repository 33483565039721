import { createAction, props } from '@ngrx/store';
import { IAddress } from '../../models/address.model';

const stateName = '[Address]';
const suggestPayer = createAction(`${stateName} Suggest Payer`, props<{ address: IAddress }>());
const suggestedPayer = createAction(`${stateName} Suggested Payer`, props<{ suggestedList: IAddress[] }>());
const suggestReceiver = createAction(`${stateName} Suggest Receiver`, props<{ address: IAddress }>());
const suggestedReceiver = createAction(`${stateName} Suggested Receiver`, props<{ suggestedList: IAddress[] }>());
const failed = createAction(`${stateName} Failed`, props<{ message: string }>());
const cleared = createAction(`${stateName} Cleared`);

export const fromAddressActions = {
	suggestPayer,
	suggestedPayer,
	suggestReceiver,
	suggestedReceiver,
	failed,
	cleared,
};

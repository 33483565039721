import { createAction, props } from '@ngrx/store';
import { IAddress } from '../../models/address.model';
import { IArticle } from '../../models/article.model';
import { ICart, ICartTax } from '../../models/cart.model';
import { IContactInfo } from '../../models/contact-info.model';
import { IOrder, PaymentKind, ShippingKind } from '../../models/order.model';
import { IArticlePosition, IBundlePosition, IPosition } from '../../models/position.model';
import { IIpInfo } from '../../services/utility/ipinfo.service';
import { ICustomer } from '../app.state';

const stateName = '[Cart]';
const unavailablePositionsRemoved = createAction(`${stateName} Unavailable Positions Removed`);
const positionAdded = createAction(`${stateName} Position Added`, props<{ articles: IArticle[] }>());
const positionRemoved = createAction(`${stateName} Position Removed`, props<{ position: IPosition }>());
const receiverDifferingChanged = createAction(`${stateName} Receiver Differing Changed`, props<{ isReceiverDiffering: boolean }>());
const enableSaturdayDeliveryChanged = createAction(`${stateName} Enable Saturday Delivery Changed`, props<{ enableSaturdayDelivery: boolean }>());
const packstationChanged = createAction(`${stateName} Packstation Changed`, props<{ isPackstation: boolean }>());
const shippingWithoutInvoiceChanged = createAction(`${stateName} Shipping Without Invoice Changed`, props<{ shippingWithoutInvoice: boolean }>());
const consentEmailTransferChanged = createAction(`${stateName} Consent Email Transfer Changed`, props<{ consentEmailTransfer: boolean }>());
const articleAdded = createAction(`${stateName} Article Added`, props<{ position: IArticlePosition; replacementIndex?: number }>());
const bundleAdded = createAction(`${stateName} Bundle Added`, props<{ position: IBundlePosition; replacementIndex?: number }>());
const emailChanged = createAction(`${stateName} Email Changed`, props<{ email: string }>());
const shippingKindChanged = createAction(`${stateName} Shipping Kind Changed`, props<{ shippingKind: ShippingKind }>());
const paymentKindChanged = createAction(`${stateName} Payment Kind Changed`, props<{ paymentKind: PaymentKind }>());
const payerChanged = createAction(`${stateName} Payer Changed`, props<{ payer: IContactInfo }>());
const customerChanged = createAction(`${stateName} Customer Changed`, props<{ customer: ICustomer }>());
const receiverChanged = createAction(`${stateName} Receiver Changed`, props<{ receiver: IContactInfo }>());
const update = createAction(`${stateName} Update`);
const updated = createAction(`${stateName} Updated`, props<{ cart: ICart }>());
const checkout = createAction(`${stateName} Checkout`, props<{ receiver: IContactInfo }>());
const checkedOut = createAction(`${stateName} Checked Out`, props<{ order: IOrder; taxes: ICartTax[] }>());
const failed = createAction(`${stateName} Failed`, props<{ message: string }>());
const changedPayerAddress = createAction(`${stateName} Payer Address Changed`, props<{ address: IAddress }>());
const changedReceiverAddress = createAction(`${stateName} Receiver Address Changed`, props<{ address: IAddress }>());
const reloaded = createAction(`${stateName} Reloaded`);
const markedAllAsTouched = createAction(`${stateName} Marked All As Touched`);
const loadIpInfo = createAction(`${stateName} Load IP Info`);
const loadedIpInfo = createAction(`${stateName} Loaded IP Info`, props<{ ipInfo: IIpInfo }>());

export const fromCartActions = {
	unavailablePositionsRemoved,
	positionAdded,
	positionRemoved,
	articleAdded,
	receiverDifferingChanged,
	enableSaturdayDeliveryChanged,
	packstationChanged,
	shippingWithoutInvoiceChanged,
	consentEmailTransferChanged,
	bundleAdded,
	emailChanged,
	shippingKindChanged,
	paymentKindChanged,
	payerChanged,
	customerChanged,
	receiverChanged,
	update,
	updated,
	checkout,
	checkedOut,
	changedPayerAddress,
	changedReceiverAddress,
	failed,
	reloaded,
	markedAllAsTouched,
	loadIpInfo,
	loadedIpInfo,
};

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export abstract class ApiService {
	constructor(private http: HttpClient) {}

	public get<TResponse>(path: string[], headers?: HttpHeaders, params?: any): Observable<TResponse> {
		return this.http.get<TResponse>(this.combineRootWithPath(path), { headers, params });
	}

	public post<TResponse, TBody>(path: string[], body: TBody, headers?: HttpHeaders, params?: any): Observable<TResponse> {
		return this.http.post<TResponse>(this.combineRootWithPath(path), body, { headers, params });
	}

	protected combineRootWithPath(path: string[]): string {
		return this.pathJoin([`${environment.serverUrl}:${environment.serverPort}`, environment.apiUrl, ...path]);
	}

	protected pathJoin(parts: string[]): string {
		return parts.filter(x => x != null && x.trim() !== '').join('/');
	}
}

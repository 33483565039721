import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LayoutService } from 'src/services/utility/layout.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromPageActions } from '../page/page.actions';
import { fromPresentationActions } from '../presentation/presentation.actions';
import { RouterSelector } from './router.selectors';

@Injectable()
export class RouterEffects extends BaseEffects {
	constructor(actions$: Actions, store: Store<AppState>, private routerSelector: RouterSelector, private templateService: LayoutService) {
		super(actions$, store);
	}

	public onRouterNavigation$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ROUTER_NAVIGATION),
				tap(() => this.templateService.closeDrawer())
			),
		{
			dispatch: false,
		}
	);

	public onRouterNavigatedForPresentation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED, fromPageActions.loaded),
			concatLatestFrom(() => this.store.select(this.routerSelector.routeData)),
			concatLatestFrom(() => this.store.select(this.routerSelector.routeParams)),
			filter(([[, routeData], routeParams]) => routeData != null && routeParams != null),
			switchMap(([[, routeData]]) => [fromPresentationActions.pageStyleChanged({ pageStyle: routeData.pageStyle }), fromPresentationActions.headerStyleChanged({ headerStyle: routeData.headerStyle })])
		)
	);
}

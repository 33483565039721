import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable, of, tap } from 'rxjs';
import { IConfiguration, IPaymentTypeInformation, IShippingTypeInformation } from '../../models/configuration.model';
import { PaymentKind, ShippingKind } from '../../models/order.model';
import { AppState, IConfigurationState, IEstimatedDelivery } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromConfigurationActions } from './configuration.actions';
import { ConfigurationSelector } from './configuration.selectors';

@Injectable()
export class ConfigurationFacade extends EntityFacade<IConfiguration, IConfigurationState> {
	constructor(store: Store<AppState>, private configurationSelector: ConfigurationSelector) {
		super(store, configurationSelector);
	}

	public shippingInformation$ = this.store.select(this.configurationSelector.shippingInformation);
	public paymentInformation$ = this.store.select(this.configurationSelector.paymentInformation);

	public loadShippingInformation(): void {
		this.store.dispatch(fromConfigurationActions.loadShippingInformation());
	}

	public loadPaymentInformation(): void {
		this.store.dispatch(fromConfigurationActions.loadPaymentInformation());
	}

	public shippingType(shippingKind: ShippingKind): Observable<IShippingTypeInformation> {
		return this.store.select(this.configurationSelector.shippingType(shippingKind));
	}

	public paymentType(paymentKind: PaymentKind): Observable<IPaymentTypeInformation> {
		return this.store.select(this.configurationSelector.paymentType(paymentKind));
	}

	public estimateDeliveries(): void {
		for (const shippingKind of Object.values(ShippingKind)) {
			this.store.dispatch(this.actions.loadEstimatedDelivery({ shippingKind }));
		}
	}

	public estimatedDelivery(shippingKind: ShippingKind): Observable<IEstimatedDelivery> {
		if (shippingKind == null) {
			return of(null);
		}

		const result = this.store.select(this.configurationSelector.estimatedDelivery(shippingKind));

		result
			.pipe(
				filter(estimatedDelivery => estimatedDelivery == null),
				tap(() => this.store.dispatch(this.actions.loadEstimatedDelivery({ shippingKind })))
			)
			.subscribe();

		return result;
	}

	public suggestShippingKind(shippingKind: ShippingKind, referenceDate: Date): Observable<ShippingKind> {
		return this.store.select(this.configurationSelector.suggestShippingKind(shippingKind, referenceDate));
	}

	protected actions = fromConfigurationActions;
}

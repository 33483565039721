import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ICoin } from '../../models/article.model';
import { ICoinState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { CoinFacade } from '../../state/coin/coin.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-coin-detaildialog',
	templateUrl: 'coin-detaildialog.component.html',
	animations: [crossFade],
})
export class CoinDetailDialogComponent extends DetailDialogComponent<ICoin, ICoinState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: ICoin }, cartFacade: CartFacade, coinFacade: CoinFacade) {
		super(data, cartFacade, coinFacade);
	}
}

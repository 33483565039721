<h2 mat-dialog-title>Antiquarisches Buch</h2>
<mat-dialog-content>
	<shop-picture [image]="article.product.image"></shop-picture>
	<div>
		<div class="text-preline">{{ article.product.description }}</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="xselling-dialog--price-info">
		<div class="price">
			{{ article.product.price | currency: 'EUR' }}
		</div>
		<div class="tax-shipping">inkl. MwSt., zzgl. Versand</div>
	</div>
	<button mat-flat-button mat-dialog-close color="accent" (click)="cartFacade.addPosition([article])">
		<mat-icon svgIcon="mdi-cart-outline"></mat-icon>
		In den Warenkorb
	</button>
</mat-dialog-actions>
<button class="close" mat-icon-button color="default" mat-dialog-close>
	<mat-icon svgIcon="mdi-close"></mat-icon>
</button>

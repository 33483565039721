import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEntity } from 'src/models/entity.model';
import { ApiService } from './api.service';
import { IListResult, IResult } from './dto.service';

export interface IEntityResult<TEntity extends IEntity> extends IResult<TEntity> {}

export interface IEntityListResult<TEntity extends IEntity> extends IListResult<TEntity> {}

@Injectable()
export abstract class EntityService<TEntity extends IEntity> extends ApiService {
	protected abstract entityName: string;

	constructor(http: HttpClient) {
		super(http);
	}

	public getOne(id: string): Observable<TEntity> {
		return this.get<IEntityResult<TEntity>>([this.entityName, id]).pipe(map(result => result.data));
	}

	public getAll(): Observable<TEntity[]> {
		return this.get<IEntityListResult<TEntity>>([this.entityName]).pipe(map(result => result.data));
	}
}

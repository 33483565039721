import { createAction, props } from '@ngrx/store';
import { ICertificate } from '../../models/article.model';
import { AddressForm, OccasionType } from '../../models/product.model';
import { EntityActions } from '../entity.actions';

export const fromCertificateActions = {
	...EntityActions.create<ICertificate>('[Certificate]'),
	customOccasionChanged: createAction(`[Certificate] Custom Occasion Changed`, props<{ customOccasion: string }>()),
	occasionChanged: createAction(`[Certificate] Occasion Changed`, props<{ occasion: OccasionType }>()),
	dateChanged: createAction(`[Certificate] Date Changed`, props<{ date: Date }>()),
	gifteeChanged: createAction(`[Certificate] Giftee Changed`, props<{ giftee: string }>()),
	gifteeBirthNameChanged: createAction(`[Certificate] Giftee Birthname Changed`, props<{ gifteeBirthName: string }>()),
	partnerChanged: createAction(`[Certificate] Partner Changed`, props<{ partner: string }>()),
	partnerBirthNameChanged: createAction(`[Certificate] Partner Birthname Changed`, props<{ partnerBirthName: string }>()),
	messageChanged: createAction(`[Certificate] Message Changed`, props<{ message: string }>()),
	customMessageChanged: createAction(`[Certificate] Custom Message Changed`, props<{ customMessage: string }>()),
	originalMessageChanged: createAction(`[Certificate] Original Message Changed`, props<{ originalMessage: string }>()),
	isCustomizedChanged: createAction(`[Certificate] Is Customized Changed`, props<{ isCustomized: boolean }>()),
	addressFormChanged: createAction(`[Certificate] Address Form Changed`, props<{ addressForm: AddressForm }>()),
	textChanged: createAction(`[Certificate] Text Changed`, props<{ text: string }>()),
	anniversaryChanged: createAction(`[Certificate] Anniversary Changed`, props<{ anniversary: number }>()),
};

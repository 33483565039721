import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IChronicle } from '../../models/article.model';
import { AppState, IChronicleState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromChronicleActions } from './chronicle.actions';
import { ChronicleSelector } from './chronicle.selectors';

@Injectable()
export class ChronicleFacade extends EntityFacade<IChronicle, IChronicleState> {
	constructor(store: Store<AppState>, chronicleSelector: ChronicleSelector) {
		super(store, chronicleSelector);
	}

	protected actions = fromChronicleActions;
}

import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { addressReducer } from './address/address.reducer';
import { AppState } from './app.state';
import { birthdayBookReducer } from './birthday-book/birthday-book.reducer';
import { birthdayChronicleReducer } from './birthday-chronicle/birthday-chronicle.reducer';
import { bookReducer } from './book/book.reducer';
import { bundleReducer } from './bundle/bundle.reducer';
import { cartReducer } from './cart/cart.reducer';
import { cdReducer } from './cd/cd.reducer';
import { certificateReducer } from './certificate/certificate.reducer';
import { chronicleReducer } from './chronicle/chronicle.reducer';
import { coinReducer } from './coin/coin.reducer';
import { configurationReducer } from './configuration/configuration.reducer';
import { crosssellingReducer } from './crossselling/crossselling.reducer';
import { dvdReducer } from './dvd/dvd.reducer';
import { externalLibrariesReducer } from './externallibraries/externallibraries.reducer';
import { faqReducer } from './faq/faq.reducer';
import { folderReducer } from './folder/folder.reducer';
import { heroTeaserReducer } from './hero-teaser/hero-teaser.reducer';
import { imageReducer } from './image/image.reducer';
import { magazineReducer } from './magazine/magazine.reducer';
import { newspaperReducer } from './newspaper/newspaper.reducer';
import { orderReducer } from './order/order.reducer';
import { pageTeaserReducer } from './page-teaser/page-teaser.reducer';
import { pageReducer } from './page/page.reducer';
import { presentationReducer } from './presentation/presentation.reducer';
import { publicationCycleReducer } from './publication-cycle/publication-cycle.reducer';
import { regionReducer } from './region/region.reducer';
import { stickReducer } from './stick/stick.reducer';
import { yearBookReducer } from './year-book/year-book.reducer';

export const actionReducerMap: ActionReducerMap<AppState> = {
	router: routerReducer,
	newspaper: newspaperReducer,
	magazine: magazineReducer,
	folder: folderReducer,
	certificate: certificateReducer,
	faq: faqReducer,
	page: pageReducer,
	address: addressReducer,
	heroTeaser: heroTeaserReducer,
	image: imageReducer,
	pageTeaser: pageTeaserReducer,
	chronicle: chronicleReducer,
	birthdayChronicle: birthdayChronicleReducer,
	crossselling: crosssellingReducer,
	coin: coinReducer,
	book: bookReducer,
	birthdayBook: birthdayBookReducer,
	yearBook: yearBookReducer,
	order: orderReducer,
	cd: cdReducer,
	dvd: dvdReducer,
	stick: stickReducer,
	publicationCycle: publicationCycleReducer,
	externalLibraries: externalLibrariesReducer,
	presentation: presentationReducer,
	region: regionReducer,
	bundle: bundleReducer,
	cart: cartReducer,
	configuration: configurationReducer,
};

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { BehaviorSubject, filter, first, map, of, withLatestFrom } from 'rxjs';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { BaseComponent } from '../base.component';
import { AnniversaryHeaderComponent } from './anniversary-header.component';

@UntilDestroy()
@Component({
	selector: 'shop-date-input',
	templateUrl: './date-input.component.html',
	styleUrls: ['./date-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent extends BaseComponent implements OnInit {
	@ViewChild('dateInput') public dateInput: ElementRef<HTMLInputElement>;
	public anniversaryHeaderComponent = AnniversaryHeaderComponent;
	public minDate = moment('1800-01-01');
	public maxDate = moment();
	public date$ = new BehaviorSubject<Date>(null);

	constructor(public newspaperFacade: NewspaperFacade) {
		super();
	}

	public ngOnInit(): void {
		this.newspaperFacade.date$.pipe(untilDestroyed(this)).subscribe(date => this.date$.next(date));
		this.date$
			.pipe(
				untilDestroyed(this),
				filter(date => date != null)
			)
			.subscribe(date => this.newspaperFacade.changeDate(date));
	}

	public onBlur(value: string): void {
		of(value)
			.pipe(
				withLatestFrom(this.newspaperFacade.date$),
				first(),
				map(([searchDate, date]) => {
					if (!searchDate) {
						return date;
					}

					const replacedDate = searchDate.replace(/[^a-zA-Z0-9]/g, ' ');
					const dateResult = require('any-date-parser').attempt(replacedDate, 'de-DE');

					if (dateResult.invalid != null) {
						return date;
					}

					return new Date(Date.UTC(dateResult.year, dateResult.month - 1, dateResult.day, 0, 0, 0, 0));
				})
			)
			.subscribe(date => this.date$.next(date));
	}

	public onKeyUp(value: string): void {
		const replacedValue = value.replace(/[^0-9\.]/g, '');

		if (replacedValue !== value) {
			this.dateInput.nativeElement.value = replacedValue;
		}
	}

	public onFocus(value: string): void {
		this.date$.next(null);
	}

	public onDateChange(date: Date): void {
		this.date$.next(date);
	}
}

<div class="shopping-cart--content__name">{{ position.title }}</div>
<div class="shopping-cart--content__date">
	<ng-container *ngIf="position | asArticlePosition as articlePosition">
		<ng-container *ngIf="articlePosition.article | asMediaArticle as mediaArticle">vom {{ mediaArticle.publicationDate | amDateFormat: 'DD.MM.YYYY' }}</ng-container>
		<ng-container *ngIf="articlePosition.article | asAccessoryArticle as accessoryArticle"
			>{{ accessoryArticle.product.name }} <span *ngIf="accessoryArticle | asCertificate as certificate">für {{ certificate.giftee }}</span></ng-container
		>
	</ng-container>
	<ng-container *ngIf="position | asBundlePosition as bundlePosition">
		<ul>
			<ng-container *ngFor="let article of bundlePosition.articles">
				<li *ngIf="article | asMediaArticle as mediaArticle" [class.text-error]="article.isUnavailable">{{ mediaArticle.product.name }} vom {{ mediaArticle.publicationDate | amDateFormat: 'DD.MM.YYYY' }}</li>
				<li *ngIf="article | asAccessoryArticle as accessoryArticle" [class.text-error]="article.isUnavailable">
					{{ accessoryArticle.product.name }} <span *ngIf="accessoryArticle | asCertificate as certificate">für {{ certificate.giftee }}</span>
				</li>
			</ng-container>
		</ul>
	</ng-container>
</div>

import { IChronicle } from '../../models/article.model';
import { IChronicleState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromChronicleActions } from './chronicle.actions';

export const initialState = MediaArticleState.create<IChronicle, IChronicleState>(
	{
		skip: 0,
		top: 10,
	},
	{
		current: 3650,
		min: 3650,
		max: 3650,
	}
);

export const chronicleReducer = EntityReducer.create<IChronicle, IChronicleState>(initialState, fromChronicleActions);

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, IPresentationState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable()
export class PresentationSelector extends BaseSelector<IPresentationState> {
	protected stateSelector = (state: AppState): IPresentationState => state.presentation;

	public pageStyle = createSelector(this.selectState, state => state.pageStyle);
	public headerStyle = createSelector(this.selectState, state => state.headerStyle);
	public scrollTop = createSelector(this.selectState, state => state.scrollTop);
	public testingAcknowledgedAt = createSelector(this.selectState, state => state.testingAcknowledgedAt);
}

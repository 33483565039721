import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { AppState, IRegionState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable()
export class RegionSelector extends EntitySelector<IRegion, IRegionState> {
	protected stateSelector = (state: AppState): IRegionState => state.region;

	public name = createSelector(this.selectState, state => state.name);
	public selectedAddress = createSelector(this.selected, seleted => seleted?.address || '');
}

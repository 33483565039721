import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPosition } from '../../models/position.model';

@Component({
	selector: 'shop-position',
	templateUrl: './position.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionComponent {
	@Input() position: IPosition;
}

<ng-container *ngIf="!(heroTeaserFacade.hasError$ | async)">
	<section class="container how-it-works" *ngFor="let heroTeaser of heroTeaserFacade.list$ | async">
		<div class="how-it-works--left">
			<h2 class="mat-h2">{{ heroTeaser.headline }}</h2>
			<div [innerHtml]="heroTeaser.text | safe"></div>
		</div>
		<div class="how-it-works--right">
			<figure>
				<shop-picture [image]="heroTeaser.image" [width]="652" class="how-it-works--product"></shop-picture>
				<img src="/assets/images/how-it-works-product-price.svg" loading="lazy" alt="Schon ab 45 € zzgl. Versand" class="how-it-works--product__price" />
			</figure>
			<small>Preisbeispiel: BILD vom 29.10.1968 (Original) + Stern vom 21.02.1971 (Original) + Geschenkmappe V.I.P. mit persönlicher Geschenkurkunde = Paketpreis 69,80&thinsp;€</small>
		</div>
	</section>
</ng-container>
<ng-container *ngIf="heroTeaserFacade.hasError$ | async">
	<section class="container">
		<div class="alert alert--warning">
			<mat-icon svgIcon="mdi-alert-circle-outline" class="alert--icon"></mat-icon>
			<div class="alert--text">Hero Teaser konnte nicht geladen werden: {{ heroTeaserFacade.error$ | async }}</div>
			<button mat-button class="alert--button" (click)="heroTeaserFacade.load()">Erneut versuchen</button>
		</div>
	</section>
</ng-container>

import { on } from '@ngrx/store';
import { IPage } from 'src/models/page.model';
import { EntityState, IPageState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromPageActions } from './page.actions';

export const initialState = { ...EntityState.create<IPage, IPageState>(), isSelected: false };
export const pageReducer = EntityReducer.create<IPage, IPageState>(
	initialState,
	fromPageActions,
	'page',
	on(fromPageActions.load, state => ({ ...state, isSelected: false })),
	on(fromPageActions.selected, state => ({ ...state, isSelected: true }))
);

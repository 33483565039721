import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { NewspaperFacade } from 'src/state/newspaper/newspaper.facade';
import { ArticleKind } from '../../models/article.model';
import { TemplateService } from '../../services/utility/template.service';
import { BookFacade } from '../../state/book/book.facade';
import { CdFacade } from '../../state/cd/cd.facade';
import { ChronicleFacade } from '../../state/chronicle/chronicle.facade';
import { CoinFacade } from '../../state/coin/coin.facade';
import { DvdFacade } from '../../state/dvd/dvd.facade';
import { FolderFacade } from '../../state/folder/folder.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { StickFacade } from '../../state/stick/stick.facade';
import { YearBookFacade } from '../../state/year-book/year-book.facade';
import { ContentBlockComponent } from './contentblocks.component';

@Component({
	selector: 'shop-searchheader',
	templateUrl: './search-header.component.html',
	styleUrls: ['./search-header.component.scss'],
})
export class SearchHeaderComponent extends ContentBlockComponent {
	public ArticleKinds = ArticleKind;

	constructor(
		changeDetector: ChangeDetectorRef,
		routerFacade: RouterFacade,
		templateService: TemplateService,
		newspaperFacade: NewspaperFacade,
		public imageFacade: ImageFacade,
		private bookFacade: BookFacade,
		private yearBookFacade: YearBookFacade,
		private cdFacade: CdFacade,
		private dvdFacade: DvdFacade,
		private coinFacade: CoinFacade,
		private chronicleFacade: ChronicleFacade,
		private folderFacade: FolderFacade,
		private stickFacade: StickFacade,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		super(changeDetector, routerFacade, newspaperFacade, templateService);

		this.routerFacade.articleKind$.pipe(first()).subscribe(articleKind => this.search(articleKind));
	}

	public gotoCategory(articleKind: ArticleKind): void {
		if (articleKind != null) {
			this.router.navigate(['../', articleKind.toLowerCase().replace(' ', '-')], { relativeTo: this.activatedRoute.parent });
			this.search(articleKind);
		} else {
			this.router.navigate(['../'], { relativeTo: this.activatedRoute });
		}
	}

	public search(articleKind: ArticleKind): void {
		switch (articleKind) {
			case ArticleKind.Book:
				this.bookFacade.load();
				break;
			case ArticleKind.YearBook:
				this.yearBookFacade.load();
				break;
			case ArticleKind.Cd:
				this.cdFacade.load();
				break;
			case ArticleKind.Dvd:
				this.dvdFacade.load();
				break;
			case ArticleKind.Coin:
				this.coinFacade.load();
				break;
			case ArticleKind.Chronicle:
				this.chronicleFacade.load();
				break;
			case ArticleKind.Folder:
				this.folderFacade.load();
				break;
			case ArticleKind.Stick:
				this.stickFacade.load();
				break;
		}
	}
}

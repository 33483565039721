import { IBook } from '../../models/article.model';
import { IBookState, MediaArticleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromBookActions } from './book.actions';

export const initialState = MediaArticleState.create<IBook, IBookState>(
	{
		skip: 0,
		top: 100,
	},
	{
		current: 36500,
		min: 36500,
		max: 36500,
	}
);

export const bookReducer = EntityReducer.create<IBook, IBookState>(initialState, fromBookActions);

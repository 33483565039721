import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IFolder } from '../../models/article.model';
import { AppState, IFolderState } from '../app.state';
import { ArticleSelector } from '../entity.selector';

@Injectable()
export class FolderSelector extends ArticleSelector<IFolder, IFolderState> {
	protected stateSelector = (state: AppState): IFolderState => state.folder;

	public preselected = createSelector(this.selectState, state => state.preselected);
}

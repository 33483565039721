import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WindowRefService } from '../../services/utility/window.service';
import { CartFacade } from '../../state/cart/cart.facade';

@Component({
	selector: 'shop-carterror-alert',
	templateUrl: './carterror-alert.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartErrorAlertComponent {
	constructor(public cartFacade: CartFacade, private windowService: WindowRefService) {}

	public reload(): void {
		this.windowService.nativeWindow.localStorage.clear();
		this.windowService.nativeWindow.location.reload();
	}
}

import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { filter, first } from 'rxjs';
import { IRegion } from '../../models/region.model';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { RegionFacade } from '../../state/region/region.facade';

@Component({
	selector: 'shop-searchresult-form',
	templateUrl: './searchresult-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchresultFormComponent {
	@ViewChild('region', { static: true }) public regionElement: ElementRef;

	constructor(public regionFacade: RegionFacade, public newspaperFacade: NewspaperFacade) {}

	public displayRegionWith = (region: IRegion) => region.name;

	public onBlurRegion(value: string): void {
		this.regionFacade.selected$
			.pipe(
				first(),
				filter(region => region == null && value != null && value.length > 0)
			)
			.subscribe(() => {
				this.regionFacade.changeName('');
				this.regionElement.nativeElement.value = '';
			});
	}
}

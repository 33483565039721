import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStick } from '../../models/article.model';
import { AppState, IStickState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromStickActions } from './stick.actions';
import { StickSelector } from './stick.selectors';

@Injectable()
export class StickFacade extends EntityFacade<IStick, IStickState> {
	constructor(store: Store<AppState>, stickSelector: StickSelector) {
		super(store, stickSelector);
	}

	protected actions = fromStickActions;
}

<section class="additional-cta container">
	<p *ngIf="params.text">{{ params.text }}</p>
	<div class="search-cta">
		<form>
			<shop-date-input></shop-date-input>
			<button type="button" mat-flat-button color="accent" (click)="newspaperFacade.load()" routerLink="/suche">
				<mat-icon svgIcon="mdi-magnify"></mat-icon>
				Zeitungen finden
			</button>
		</form>
	</div>
	<small class="text-center"><b>Original-Zeitung ab 39,90&thinsp;€</b> (Preis variiert nach Datum und Titel)</small>
	<small *ngIf="params.subText">{{ params.subText }}</small>
</section>

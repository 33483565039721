<section class="page-top">
	<div class="container">
		<div class="intro">
			<div class="intro--text">
				<h1 class="mat-h1">{{ params.headline }}</h1>
				<p>{{ params.text }}</p>
			</div>
			<div class="intro--bg">
				<shop-picture [image]="imageFacade.loadSingleById(params.image) | async" [width]="700" [height]="409" [loading]="'eager'"></shop-picture>
			</div>
		</div>
	</div>
</section>
<section class="container search-form__container">
	<div class="search-form">
		<div class="search-form__top" *ngIf="routerFacade.articleKind$ | async as articleKind">
			<mat-form-field appearance="outline" *ngIf="params.hasDate">
				<mat-label>Datum</mat-label>
				<input
					matInput
					placeholder="Datum"
					#dateInput
					(keyup.enter)="search(articleKind)"
					(click)="dateInput.select()"
					[value]="newspaperFacade.date$ | async"
					(dateChange)="newspaperFacade.changeDate($event.value)"
					autocomplete="off"
					[matDatepicker]="picker" />
				<mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker touchUi></mat-datepicker>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="!params.hasDate">
				<mat-label>Datum</mat-label>
				<input matInput [disabled]="true" placeholder="Datum" value="Keine Datumsauswahl" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Produktkategorie</mat-label>
				<mat-select [value]="articleKind" (selectionChange)="gotoCategory($event.value)">
					<mat-option [value]="null"></mat-option>
					<mat-option [value]="ArticleKinds.Book">Antiquarische Bücher</mat-option>
					<mat-option [value]="ArticleKinds.YearBook">Geschenkbücher</mat-option>
					<mat-option [value]="ArticleKinds.Cd">CDs</mat-option>
					<mat-option [value]="ArticleKinds.Dvd">DVDs</mat-option>
					<mat-option [value]="ArticleKinds.Coin">Historische Zahlungsmittel</mat-option>
					<mat-option [value]="ArticleKinds.Chronicle">Jahrgangs-Chroniken</mat-option>
					<mat-option [value]="ArticleKinds.Folder">Mappen</mat-option>
					<mat-option [value]="ArticleKinds.Stick">Zeitungshalter</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</section>

import { on } from '@ngrx/store';
import { isValid } from 'iban';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { maxLength, minLength, pattern, required } from 'ngrx-forms/validation';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { IContactInfo, SalutationKind } from '../../models/contact-info.model';
import { PaymentKind, ShippingKind } from '../../models/order.model';
import { IPosition } from '../../models/position.model';
import { ICartState, ICustomer } from '../app.state';
import { fromCartActions } from './cart.actions';

const initialState: ICartState = {
	positions: [],
	selected: null,
	isLoading: false,
	isDisabled: false,
	error: null,
	isReceiverDiffering: false,
	shippingWithoutInvoice: false,
	consentEmailTransfer: true,
	enableSaturdayDelivery: true,
	isPackstation: false,
	customer: null,
	payer: null,
	receiver: null,
	totalPrice: null,
	taxes: [],
	ipInfo: null,
	allowedShippingKinds: [],
	allowShippingAddress: true,
	allowPackstation: true,
	paymentKind: PaymentKind.Paypal,
	shippingKind: ShippingKind.Dhl,
	receiverForm: createFormGroupState<IContactInfo>('RECEIVER', {
		salutationKind: SalutationKind.Mr,
		firstName: '',
		lastName: '',
		company: '',
		iban: '',
		accountOwner: '',
		address: {
			street: '',
			streetNumber: '',
			postCode: '',
			city: '',
			country: 'Deutschland',
		},
	}),
	payerForm: createFormGroupState<IContactInfo>('PAYER', {
		salutationKind: SalutationKind.Mr,
		firstName: '',
		lastName: '',
		company: '',
		iban: '',
		accountOwner: '',
		address: {
			street: '',
			streetNumber: '',
			postCode: '',
			city: '',
			country: 'Deutschland',
		},
	}),
	customerForm: createFormGroupState<ICustomer>('CUSTOMER', {
		salutationKind: null,
		email: '',
		telephone: '',
	}),
};

function addToList(positions: IPosition[], position: IPosition, replacementIndex?: number): IPosition[] {
	if (replacementIndex == null) {
		return [...positions, position];
	}

	const newList = [...positions];
	newList.splice(replacementIndex, 1, position);

	return newList;
}

export const cartReducer = wrapReducerWithFormStateUpdate(
	wrapReducerWithFormStateUpdate(
		wrapReducerWithFormStateUpdate(
			createRehydrateReducer(
				'cart',
				initialState,
				onNgrxForms(),
				on(fromCartActions.unavailablePositionsRemoved, state => ({ ...state, positions: state.positions.filter(x => !x.isUnavailable) })),
				on(fromCartActions.positionRemoved, (state, { position }) => ({ ...state, isLoading: true, positions: state.positions.filter(x => x != position) })),
				on(fromCartActions.articleAdded, (state, { position, replacementIndex }) => ({ ...state, isLoading: true, positions: addToList(state.positions, position, replacementIndex) })),
				on(fromCartActions.bundleAdded, (state, { position, replacementIndex }) => ({ ...state, isLoading: true, positions: addToList(state.positions, position, replacementIndex) })),
				on(fromCartActions.emailChanged, (state, { email }) => ({ ...state, email })),
				on(fromCartActions.payerChanged, (state, { payer }) => ({ ...state, payer })),
				on(fromCartActions.receiverDifferingChanged, (state, { isReceiverDiffering }) => ({ ...state, isReceiverDiffering })),
				on(fromCartActions.enableSaturdayDeliveryChanged, (state, { enableSaturdayDelivery }) => ({ ...state, enableSaturdayDelivery })),
				on(fromCartActions.shippingWithoutInvoiceChanged, (state, { shippingWithoutInvoice }) => ({ ...state, shippingWithoutInvoice })),
				on(fromCartActions.consentEmailTransferChanged, (state, { consentEmailTransfer }) => ({ ...state, consentEmailTransfer })),
				on(fromCartActions.packstationChanged, (state, { isPackstation }) => ({ ...state, isPackstation })),
				on(fromCartActions.receiverChanged, (state, { receiver }) => ({ ...state, isLoading: true, receiver })),
				on(fromCartActions.customerChanged, (state, { customer }) => ({ ...state, customer })),
				on(fromCartActions.paymentKindChanged, (state, { paymentKind }) => ({ ...state, isLoading: true, paymentKind })),
				on(fromCartActions.shippingKindChanged, (state, { shippingKind }) => ({ ...state, isLoading: true, shippingKind })),
				on(fromCartActions.update, state => ({ ...state, isLoading: true, isDisabled: true })),
				on(fromCartActions.failed, (state, { message }) => ({ ...state, isLoading: false, isDisabled: false, error: message })),
				on(fromCartActions.updated, (state, { cart }) => ({
					...state,
					...cart,
					isLoading: false,
					isDisabled: false,
					isReceiverDiffering: cart.allowShippingAddress ? state.isReceiverDiffering : false,
					receiver: cart.allowShippingAddress ? state.payer : null,
					error: null,
				})),
				on(fromCartActions.checkout, (state, { receiver }) => ({ ...state, receiver, isLoading: true, isDisabled: true })),
				on(fromCartActions.checkedOut, (state, { order, taxes }) => ({
					...state,
					isLoading: false,
					isDisabled: false,
					error: null,
					positions: [],
					taxes: null,
					paymentKind: initialState.paymentKind,
					shippingKind: initialState.shippingKind,
					isReceiverDiffering: initialState.isReceiverDiffering,
					receiverForm: initialState.receiverForm,
				})),
				on(fromCartActions.reloaded, state => ({
					...state,
					isDisabled: false,
				})),

				on(fromCartActions.loadIpInfo, state => ({
					...state,
					ipInfo: null,
				})),
				on(fromCartActions.loadedIpInfo, (state, { ipInfo }) => ({
					...state,
					ipInfo,
				}))
			),
			state => state.receiverForm,
			updateGroup({
				salutationKind: validate(required),
				firstName: validate(required),
				lastName: validate(required),
				address: updateGroup({
					street: validate(required),
					streetNumber: validate(required),
					postCode: validate(required, pattern(/^[0-9]{5}$/), minLength(5), maxLength(5)),
					city: validate(required),
					country: validate(required),
				}),
			})
		),
		state => state.payerForm,
		updateGroup({
			salutationKind: validate(required),
			firstName: validate(required),
			lastName: validate(required),
			iban: validate(value => {
				if (!value || isValid(value)) {
					return {};
				}

				return {
					iban: value,
				};
			}),
			address: updateGroup({
				street: validate(required),
				streetNumber: validate(required),
				postCode: validate(required, pattern(/^[0-9]{5}$/), minLength(5), maxLength(5)),
				city: validate(required),
				country: validate(required),
			}),
		})
	),
	state => state.customerForm,
	updateGroup({
		telephone: validate(required, value => {
			if (!value) {
				return {};
			}

			const phoneResult = parsePhoneNumberFromString(value, 'DE');

			if (phoneResult != null && phoneResult.isValid()) {
				return {};
			}

			return {
				telephone: value,
			};
		}),
		email: validate(required),
	})
);

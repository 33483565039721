import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { bufferTime, filter, map, mergeMap } from 'rxjs/operators';
import { IImage } from 'src/models/image.model';
import { ImageApiService } from 'src/services/api/image.service';
import { AppState, IImageState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { RouterSelector } from '../router/router.selectors';
import { fromImageActions } from './image.actions';
import { ImageSelector } from './image.selectors';

@Injectable()
export class ImageEffects extends EntityEffects<IImage, IImageState> {
	constructor(actions$: Actions, store: Store<AppState>, private imageApiService: ImageApiService, imageSelector: ImageSelector, routerSelector: RouterSelector, @Inject(PLATFORM_ID) private platformId: Object) {
		super(actions$, store, imageApiService, imageSelector, routerSelector, fromImageActions);
	}

	public onLoadImage$ = isPlatformBrowser(this.platformId)
		? createEffect(() =>
				this.actions$.pipe(
					ofType(fromImageActions.loadImage),
					bufferTime(500),
					map(actions => actions.map(action => action.id).filter(id => !!id)),
					filter(ids => ids.length > 0),
					concatLatestFrom(ids => this.store.select(this.entitySelector.hasItems(ids))),
					map(([ids, result]) => ids.map((id, index) => (!result[index] ? id : null)).filter(id => !!id)),
					filter(ids => ids.length > 0),
					mergeMap(missingIds => this.imageApiService.getMany(missingIds).pipe(map(images => fromImageActions.loadedImages({ images }))))
				)
		  )
		: null;
}

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { ArticleKind } from '../../models/article.model';
import { IBundle } from '../../models/bundle.model';
import { CurrencyService } from '../../services/utility/currency.service';
import { AppState, IBundleState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable()
export class BundleSelector extends EntitySelector<IBundle, IBundleState> {
	protected stateSelector = (state: AppState): IBundleState => state.bundle;

	constructor(private currencyService: CurrencyService) {
		super();
	}

	public containsNewspaper = createSelector(this.selected, selected => selected != null && selected.components.some(x => x.articleKind == ArticleKind.Newspaper));
	public containsMagazine = createSelector(this.selected, selected => selected != null && selected.components.some(x => x.articleKind == ArticleKind.Magazine));
	public getDowngrades = (bundle: IBundle) =>
		createSelector(this.list, bundles =>
			bundles.filter(downgrade => downgrade.components.length == bundle.components.length - 1 && downgrade.components.every(component => bundle.components.some(x => x.articleKind == component.articleKind)))
		);
	public getDowngradeForArticleKind = (bundle: IBundle, articleKind: ArticleKind) => createSelector(this.getDowngrades(bundle), downgrades => downgrades.find(downgrade => downgrade.components.every(x => x.articleKind != articleKind)));
	public forArticleKind = (articleKind: ArticleKind) => createSelector(this.list, bundles => bundles.filter(x => x.components.some(y => y.articleKind == articleKind)));
	public getComponent = (articleKind: ArticleKind) => createSelector(this.selected, bundle => bundle.components.find(x => x.articleKind == articleKind));
	public getDiscount = (articleKind: ArticleKind, price: number) =>
		createSelector(this.getComponent(articleKind), component => (component != null && component.discount > 0 ? { articleKind, price: this.currencyService.round(price - component.discount * price) } : null));
}

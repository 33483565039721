<div class="wrapper">
	<button mat-icon-button class="double-arrow" (click)="previousClicked('decade')">
		<mat-icon svgIcon="mdi-chevron-left"></mat-icon>
		<mat-icon svgIcon="mdi-chevron-left"></mat-icon>
	</button>
	<button mat-icon-button (click)="previousClicked('year')">
		<mat-icon svgIcon="mdi-chevron-left"></mat-icon>
	</button>
	<span class="label">{{ periodLabel }}</span>
	<button mat-icon-button (click)="nextClicked('year')">
		<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
	</button>
	<button mat-icon-button class="double-arrow" (click)="nextClicked('decade')">
		<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
		<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
	</button>
</div>
<div class="anniversaries">
	<button mat-button color="primary" (click)="goTo(40)">40.</button>
	<button mat-button color="primary" (click)="goTo(50)">50.</button>
	<button mat-button color="primary" (click)="goTo(60)">60.</button>
	<button mat-button color="primary" (click)="goTo(70)">70.</button>
	<button mat-button color="primary" (click)="goTo(80)">80.</button>
</div>

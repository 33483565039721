import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { IBook } from '../../models/article.model';
import { IBookState } from '../../state/app.state';
import { BookFacade } from '../../state/book/book.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-book-detaildialog',
	templateUrl: 'book-detaildialog.component.html',
	animations: [crossFade],
})
export class BookDetailDialogComponent extends DetailDialogComponent<IBook, IBookState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: IBook }, cartFacade: CartFacade, bookFacade: BookFacade) {
		super(data, cartFacade, bookFacade);
	}
}

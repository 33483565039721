import { createAction, props } from '@ngrx/store';
import { IMagazine, IPaging } from '../../models/article.model';
import { IRegion } from '../../models/region.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromMagazineActions = {
	...EntityActions.create<IMagazine>('[Magazine]'),
	dateChanged: createAction(`[Magazine] Date Changed`, props<{ date: Date }>()),
	extended: createAction(`[Magazine] Extended`),
	pagingChanged: createAction(`[Magazine] Paging Changed`, props<{ paging: IPaging }>()),
	dayRangeChanged: createAction(`[Magazine] Day Range Changed`, props<{ dayRange: IDayRange }>()),
	regionChanged: createAction(`[Magazine] Region Changed`, props<{ region: IRegion }>()),
	isFilterVisibleChanged: createAction(`[Magazine] Is Filter Visible Changed`, props<{ isFilterVisible: boolean }>()),
	nameChanged: createAction(`[Magazine] Name Changed`, props<{ name: string }>()),
	loadMagazine: createAction(`[Magazine] Load Magazine`, props<{ id: string }>()),
	loadedMagazine: createAction(`[Magazine] Loaded Magazine`, props<{ magazine: IMagazine }>()),
};

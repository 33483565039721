import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAddress } from '../../models/address.model';
import { ArticleKind, IArticle, IMagazine, INewspaper } from '../../models/article.model';
import { IContactInfo } from '../../models/contact-info.model';
import { PaymentKind, ShippingKind } from '../../models/order.model';
import { IArticlePosition, IBundlePosition, IPosition, PositionKind } from '../../models/position.model';
import { AppState, ICustomer } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromCartActions } from './cart.actions';
import { CartSelector } from './cart.selectors';

@Injectable()
export class CartFacade extends BaseFacade {
	constructor(store: Store<AppState>, private cartSelector: CartSelector) {
		super(store);
	}

	public cartState$ = this.store.select(this.cartSelector.cartState);
	public payer$ = this.store.select(this.cartSelector.payer);
	public receiver$ = this.store.select(this.cartSelector.receiver);
	public receiverForm$ = this.store.select(this.cartSelector.receiverForm);
	public payerForm$ = this.store.select(this.cartSelector.payerForm);
	public customerForm$ = this.store.select(this.cartSelector.customerForm);
	public isReceiverDiffering$ = this.store.select(this.cartSelector.isReceiverDiffering);
	public allowShippingAddress$ = this.store.select(this.cartSelector.allowShippingAddress);
	public allowPackstation$ = this.store.select(this.cartSelector.allowPackstation);
	public ipInfo$ = this.store.select(this.cartSelector.ipInfo);
	public shippingWithoutInvoice$ = this.store.select(this.cartSelector.shippingWithoutInvoice);
	public enableSaturdayDelivery$ = this.store.select(this.cartSelector.enableSaturdayDelivery);
	public consentEmailTransfer$ = this.store.select(this.cartSelector.consentEmailTransfer);
	public taxes$ = this.store.select(this.cartSelector.taxes);
	public isPackstation$ = this.store.select(this.cartSelector.isPackstation);
	public shippingKind$ = this.createBehaviourSubject(this.store.select(this.cartSelector.shippingKind), ShippingKind.Dhl);
	public paymentKind$ = this.createBehaviourSubject(this.store.select(this.cartSelector.paymentKind), PaymentKind.Prepayment);
	public list$ = this.store.select(this.cartSelector.positions);
	public productPositions$ = this.store.select(this.cartSelector.productPositions);
	public positionsAvailable$ = this.store.select(this.cartSelector.positionsAvailable);
	public positionsUnavailable$ = this.store.select(this.cartSelector.positionsUnavailable);
	public shippingPosition$ = this.store.select(this.cartSelector.shippingPosition);
	public paymentPosition$ = this.store.select(this.cartSelector.paymentPosition);
	public subtotal$ = this.store.select(this.cartSelector.subtotal);
	public isUnavailable$ = this.store.select(this.cartSelector.isUnavailable);
	public isPartiallyUnavailable$ = this.store.select(this.cartSelector.isPartiallyUnavailable);
	public totalPrice$ = this.store.select(this.cartSelector.totalPrice);
	public isLoading$ = this.store.select(this.cartSelector.isLoading);
	public error$ = this.store.select(this.cartSelector.error);
	public hasError$ = this.store.select(this.cartSelector.hasError);
	public isDisabled$ = this.store.select(this.cartSelector.isDisabled);

	public isAvailable(shippingKind: ShippingKind): Observable<boolean> {
		return this.store.select(this.cartSelector.isAvailable(shippingKind));
	}

	public update(): void {
		this.store.dispatch(fromCartActions.update());
	}

	public checkout(receiver: IContactInfo): void {
		this.store.dispatch(fromCartActions.checkout({ receiver }));
	}

	public removeUnavailablePositions(): void {
		this.store.dispatch(fromCartActions.unavailablePositionsRemoved());
	}

	public addPosition(articles: IArticle[]): void {
		this.store.dispatch(fromCartActions.positionAdded({ articles }));
	}

	public removePosition(position: IPosition): void {
		this.store.dispatch(fromCartActions.positionRemoved({ position }));
	}

	public changeReceiverDiffering(isReceiverDiffering: boolean): void {
		this.store.dispatch(fromCartActions.receiverDifferingChanged({ isReceiverDiffering }));
	}

	public changeEnableSaturdayDelivery(enableSaturdayDelivery: boolean): void {
		this.store.dispatch(fromCartActions.enableSaturdayDeliveryChanged({ enableSaturdayDelivery }));
	}

	public changePackstation(isPackstation: boolean): void {
		this.store.dispatch(fromCartActions.packstationChanged({ isPackstation }));
	}

	public changeShippingWithoutInvoice(shippingWithoutInvoice: boolean): void {
		this.store.dispatch(fromCartActions.shippingWithoutInvoiceChanged({ shippingWithoutInvoice }));
	}

	public changeConsentEmailTransfer(consentEmailTransfer: boolean): void {
		this.store.dispatch(fromCartActions.consentEmailTransferChanged({ consentEmailTransfer }));
	}

	public changePayer(payer: IContactInfo): void {
		this.store.dispatch(fromCartActions.payerChanged({ payer }));
	}

	public changeCustomer(customer: ICustomer): void {
		this.store.dispatch(fromCartActions.customerChanged({ customer }));
	}

	public changePayerAddress(address: IAddress): void {
		this.store.dispatch(fromCartActions.changedPayerAddress({ address }));
	}

	public changeReceiverAddress(address: IAddress): void {
		this.store.dispatch(fromCartActions.changedReceiverAddress({ address }));
	}

	public changeReceiver(receiver: IContactInfo): void {
		this.store.dispatch(fromCartActions.receiverChanged({ receiver }));
	}

	public changePaymentKind(paymentKind: PaymentKind): void {
		if (paymentKind == null) {
			return;
		}

		this.store.dispatch(fromCartActions.paymentKindChanged({ paymentKind }));
	}

	public changeShippingKind(shippingKind: ShippingKind): void {
		if (shippingKind == null) {
			return;
		}

		this.store.dispatch(fromCartActions.shippingKindChanged({ shippingKind }));
	}

	public changeShippingKindDelivery(): void {
		if (this.shippingKind$.value == ShippingKind.Pickup) {
			this.store.dispatch(fromCartActions.shippingKindChanged({ shippingKind: ShippingKind.Dhl }));
		}
	}

	public changeShippingKindPickup(): void {
		if (this.shippingKind$.value != ShippingKind.Pickup) {
			this.store.dispatch(fromCartActions.shippingKindChanged({ shippingKind: ShippingKind.Pickup }));
		}
	}

	public markAllAsTouched(): void {
		this.store.dispatch(fromCartActions.markedAllAsTouched());
	}

	public findNewspaper(position: IPosition): INewspaper {
		if (position.positionKind == PositionKind.Article) {
			const articlePosition = position as IArticlePosition;

			if (articlePosition.article.articleKind == ArticleKind.Newspaper) {
				return articlePosition.article as INewspaper;
			}
		} else if (position.positionKind == PositionKind.Bundle) {
			const bundlePosition = position as IBundlePosition;
			const article = bundlePosition.articles.find(article => article.articleKind == ArticleKind.Newspaper);

			if (article != null) {
				return article as INewspaper;
			}
		}

		return null;
	}

	public findMagazine(position: IPosition): IMagazine {
		if (position.positionKind == PositionKind.Article) {
			const articlePosition = position as IArticlePosition;

			if (articlePosition.article.articleKind == ArticleKind.Magazine) {
				return articlePosition.article as IMagazine;
			}
		} else if (position.positionKind == PositionKind.Bundle) {
			const bundlePosition = position as IBundlePosition;
			const article = bundlePosition.articles.find(article => article.articleKind == ArticleKind.Magazine);

			if (article != null) {
				return article as IMagazine;
			}
		}

		return null;
	}
}

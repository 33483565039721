<ng-container *ngIf="!(stickFacade.isLoading$ | async) && !(stickFacade.list$ | async).length"> </ng-container>

<ng-container *ngIf="stickFacade.isLoading$ | async">
	<div class="search-results">
		<div class="search-results--tables" [@crossFade]>
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Zeitungshalter</strong> werden gesucht...</p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let _ of [].constructor(1)" class="search-results--table__row gz-skeleton">
						<div class="search-results--table__column search-results--table__column-image">
							<div class="gz-skeleton__figure"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div class="gz-skeleton__logo"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-region">
							<div class="location">
								<div class="gz-skeleton__text"></div>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
							<div class="gz-skeleton__text"></div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<div class="gz-skeleton__text"></div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!(stickFacade.isLoading$ | async) && (stickFacade.list$ | async).length">
	<div class="search-results" [@crossFade]>
		<div class="search-results--tables">
			<div class="search-results--table">
				<div class="search-results--table__header">
					<p><strong>Zeitungshalter</strong></p>
				</div>
				<mat-card class="mat-elevation-z1">
					<div *ngFor="let stick of stickFacade.list$ | async; trackBy: articleById" class="search-results--table__row">
						<div class="search-results--table__column search-results--table__column-image">
							<shop-picture *ngIf="stick.product.image != null" [image]="stick.product.image" [width]="130" [height]="130" (click)="onSelect(stick)"></shop-picture>
							<shop-picture *ngIf="stick.product.image == null && stick.product.mediaBrand.image != null" [image]="stick.product.mediaBrand.image" [width]="130" [height]="130" (click)="onSelect(stick)"></shop-picture>
						</div>
						<div class="search-results--table__column search-results--table__column-logo">
							<div>
								<b>{{ stick.product.name }}</b>
							</div>
						</div>
						<div class="search-results--table__column search-results--table__column-description">
							<div *ngIf="stick.product.shortDescription">{{ stick.product.shortDescription }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-price">
							<div class="availability"><b>&bull;</b> verfügbar</div>
							<div class="price">{{ stick.product.price | currency: 'EUR' }}</div>
						</div>
						<div class="search-results--table__column search-results--table__column-button">
							<button mat-flat-button color="accent" (click)="onSelect(stick)">
								<mat-icon svgIcon="mdi-chevron-right"></mat-icon>
								<span>Auswählen</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>

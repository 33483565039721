import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { APP_BASE_HREF, CommonModule, isPlatformServer, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { EffectsModule } from '@ngrx/effects';
import * as handlebars from 'handlebars/dist/cjs/handlebars';
import moment from 'moment';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgLetModule } from 'ng-let';
import { MomentModule } from 'ngx-moment';
import { ShippingAlertStaticComponent } from 'src/components/alert/shipping-alert-static.component';
import { DividerComponent } from 'src/components/contentblocks/divider.component';
import { GridComponent } from 'src/components/contentblocks/grid.component';
import { HowItWorksComponent } from 'src/components/contentblocks/how-it-works.component';
import { SpacerComponent } from 'src/components/contentblocks/spacer.component';
import { UspsComponent } from 'src/components/contentblocks/usps.component';
import { VirtualTourComponent } from 'src/components/contentblocks/virtual-tour.component';
import { PositionComponent } from 'src/components/position/position.component';
import { ShopvoteBadgeAllVotesComponent } from 'src/components/shopvote-badge-allvotes/shopvote-badge-allvotes.component';
import { CustomErrorStateMatcherDirective } from 'src/directives/error-state-matcher.directive';
import { SafePipe } from 'src/pipes/safe.pipe';
import { ArraySortPipe } from 'src/pipes/sort.pipe';
import { CartErrorAlertComponent } from '../components/alert/carterror-alert.component';
import { CartUnavailabilityAlertComponent } from '../components/alert/cartunavilability-alert.component';
import { OrderErrorAlertComponent } from '../components/alert/ordererror-alert.component';
import { ShippingAlertComponent } from '../components/alert/shipping-alert.component';
import { BundleDialogComponent } from '../components/bundle-dialog/bundle-dialog.component';
import { AccordionComponent } from '../components/contentblocks/accordion.component';
import { CallToActionComponent } from '../components/contentblocks/call-to-action.component';
import { ContentBlocksComponent } from '../components/contentblocks/contentblocks.component';
import { CustomerReviewsComponent } from '../components/contentblocks/customer-reviews.component';
import { HeadlineComponent } from '../components/contentblocks/headline.component';
import { ImageComponent } from '../components/contentblocks/image.component';
import { ImageTextComponent } from '../components/contentblocks/imagetext.component';
import { IntroTeaserComponent } from '../components/contentblocks/intro-teaser.component';
import { NewsletterComponent } from '../components/contentblocks/newsletter.component';
import { SearchHeaderComponent } from '../components/contentblocks/search-header.component';
import { TextComponent } from '../components/contentblocks/text.component';
import { VideoComponent } from '../components/contentblocks/video.component';
import { AnniversaryHeaderComponent } from '../components/date-input/anniversary-header.component';
import { DateInputComponent } from '../components/date-input/date-input.component';
import { PictureComponent } from '../components/picture/picture.component';
import { SearchresultBookComponent } from '../components/searchresult-book/searchresult-book.component';
import { SearchresultCdComponent } from '../components/searchresult-cd/searchresult-cd.component';
import { SearchresultChronicleComponent } from '../components/searchresult-chronicle/searchresult-chronicle.component';
import { SearchresultCoinComponent } from '../components/searchresult-coin/searchresult-coin.component';
import { SearchresultDvdComponent } from '../components/searchresult-dvd/searchresult-dvd.component';
import { SearchresultFolderComponent } from '../components/searchresult-folder/searchresult-folder.component';
import { SearchresultFormComponent } from '../components/searchresult-form/searchresult-form.component';
import { SearchresultMagazineComponent } from '../components/searchresult-magazine/searchresult-magazine.component';
import { SearchresultNewspaperComponent } from '../components/searchresult-newspaper/searchresult-newspaper.component';
import { SearchresultStickComponent } from '../components/searchresult-stick/searchresult-stick.component';
import { SearchresultYearbookComponent } from '../components/searchresult-yearbook/searchresult-year-book.component';
import { ShippingKindDialogComponent } from '../components/shippingkind-dialog/shippingkind-dialog.component';
import { ShippingKindDeliveryRangeComponent } from '../components/shippingkind-selector/shippingkind-deliveryrange.component';
import { ShippingKindSelectorComponent } from '../components/shippingkind-selector/shippingkind-selector.component';
import { ShopvoteBadge50Component } from '../components/shopvote-badge-50/shopvote-badge-50.component';
import { MatListOptionFixDirective } from '../directives/mat-list-option-fix.directive';
import { NgrxMatSelectViewAdapter } from '../directives/ngrx-mat-select-viewadapter.directive';
import { environment } from '../environments/environment';
import { JsonParserHttpInterceptor } from '../interceptors/json-parser.interceptor';
import { AddressPipe } from '../pipes/address.pipe';
import { CastAccessoryArticlePipe, CastArticlePositionPipe, CastBundlePositionPipe, CastCertificatePipe, CastMediaArticlePipe } from '../pipes/cast.pipe';
import { CurrencyService } from '../services/utility/currency.service';

@NgModule({
	declarations: [
		PictureComponent,
		DateInputComponent,
		AnniversaryHeaderComponent,
		SafePipe,
		ArraySortPipe,
		AddressPipe,
		CastMediaArticlePipe,
		CastAccessoryArticlePipe,
		CastCertificatePipe,
		CastArticlePositionPipe,
		CastBundlePositionPipe,
		CustomErrorStateMatcherDirective,
		SearchresultFormComponent,
		SearchresultNewspaperComponent,
		SearchresultBookComponent,
		SearchresultChronicleComponent,
		SearchresultFolderComponent,
		SearchresultStickComponent,
		SearchresultCoinComponent,
		SearchresultCdComponent,
		SearchresultDvdComponent,
		SearchresultYearbookComponent,
		SearchresultMagazineComponent,
		MatListOptionFixDirective,
		NgrxMatSelectViewAdapter,
		ShippingAlertComponent,
		ShippingAlertStaticComponent,
		CartErrorAlertComponent,
		OrderErrorAlertComponent,
		CartUnavailabilityAlertComponent,
		BundleDialogComponent,
		ContentBlocksComponent,
		CallToActionComponent,
		CustomerReviewsComponent,
		ShippingKindSelectorComponent,
		ShippingKindDeliveryRangeComponent,
		ShippingKindDialogComponent,
		TextComponent,
		HeadlineComponent,
		ImageComponent,
		ImageTextComponent,
		VideoComponent,
		IntroTeaserComponent,
		SearchHeaderComponent,
		NewsletterComponent,
		AccordionComponent,
		SpacerComponent,
		DividerComponent,
		VirtualTourComponent,
		GridComponent,
		PositionComponent,
		UspsComponent,
		HowItWorksComponent,
		ShopvoteBadgeAllVotesComponent,
		ShopvoteBadge50Component,
	],
	exports: [
		PictureComponent,
		DateInputComponent,
		AnniversaryHeaderComponent,
		SafePipe,
		ArraySortPipe,
		AddressPipe,
		CastMediaArticlePipe,
		CastAccessoryArticlePipe,
		CastCertificatePipe,
		CastArticlePositionPipe,
		CastBundlePositionPipe,
		CustomErrorStateMatcherDirective,
		SearchresultFormComponent,
		SearchresultNewspaperComponent,
		SearchresultBookComponent,
		SearchresultChronicleComponent,
		SearchresultFolderComponent,
		SearchresultStickComponent,
		SearchresultCoinComponent,
		SearchresultCdComponent,
		SearchresultDvdComponent,
		SearchresultYearbookComponent,
		SearchresultMagazineComponent,
		MatListOptionFixDirective,
		NgrxMatSelectViewAdapter,
		ShippingAlertComponent,
		ShippingAlertStaticComponent,
		CartErrorAlertComponent,
		OrderErrorAlertComponent,
		CartUnavailabilityAlertComponent,
		BundleDialogComponent,
		ContentBlocksComponent,
		CustomerReviewsComponent,
		ShippingKindSelectorComponent,
		ShippingKindDeliveryRangeComponent,
		ShippingKindDialogComponent,
		CallToActionComponent,
		TextComponent,
		HeadlineComponent,
		ImageComponent,
		ImageTextComponent,
		VideoComponent,
		IntroTeaserComponent,
		SearchHeaderComponent,
		NewsletterComponent,
		AccordionComponent,
		SpacerComponent,
		DividerComponent,
		VirtualTourComponent,
		GridComponent,
		PositionComponent,
		UspsComponent,
		HowItWorksComponent,
		ShopvoteBadgeAllVotesComponent,
		ShopvoteBadge50Component,
	],
	imports: [
		CommonModule,
		NgLetModule,
		MomentModule,
		GoogleMapsModule,
		LazyLoadImageModule,
		EffectsModule.forFeature([]),
		RouterModule.forChild([]),
		MatSnackBarModule,
		MatDatepickerModule,
		MatDialogModule,
		MatIconModule,
		MatAutocompleteModule,
		MatInputModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatDividerModule,
		MatCardModule,
		MatRadioModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		YouTubePlayerModule,
	],
	providers: [
		JsonParserHttpInterceptor,
		CurrencyService,
		{
			provide: 'googleTagManagerId',
			useValue: environment.googleTagManagerId,
		},
		{
			provide: APP_BASE_HREF,
			useValue: '/',
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'de-DE',
		},
		{
			provide: DEFAULT_CURRENCY_CODE,
			useValue: 'EUR',
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
		{
			provide: LOCALE_ID,
			useValue: 'de-DE',
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD.MM.YYYY',
				},
				display: {
					dateInput: 'DD.MM.YYYY',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			},
		},
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class SharedModule {
	constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, @Inject(PLATFORM_ID) platformId: Object) {
		const iconSetUrl = (isPlatformServer(platformId) ? `http://localhost:${process.env.PORT || 4201}` : '') + '/assets/icons/mdi-library.svg';

		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(iconSetUrl), { viewBox: '0 0 24 24' });

		moment.locale('de');
		registerLocaleData(localeDe, localeDeExtra);

		moment.parseTwoDigitYear = function (input) {
			return +input + 1900;
		};

		handlebars.registerHelper('addYears', (years: number, formatOutput: string) => moment().add(years, 'years').format(formatOutput));
		handlebars.registerHelper('ifCond', function (v1: any, operator: string, v2: any, options: any) {
			// @ts-ignore: this is a handlebars helper
			const value: any = this;

			switch (operator) {
				case '==':
					return v1 == v2 ? options.fn(value) : options.inverse(value);
				case '===':
					return v1 === v2 ? options.fn(value) : options.inverse(value);
				case '!=':
					return v1 != v2 ? options.fn(value) : options.inverse(value);
				case '!==':
					return v1 !== v2 ? options.fn(value) : options.inverse(value);
				case '<':
					return v1 < v2 ? options.fn(value) : options.inverse(value);
				case '<=':
					return v1 <= v2 ? options.fn(value) : options.inverse(value);
				case '>':
					return v1 > v2 ? options.fn(value) : options.inverse(value);
				case '>=':
					return v1 >= v2 ? options.fn(value) : options.inverse(value);
				case '&&':
					return v1 && v2 ? options.fn(value) : options.inverse(value);
				case '||':
					return v1 || v2 ? options.fn(value) : options.inverse(value);
				default:
					return options.inverse(value);
			}
		});
	}
}

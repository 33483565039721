<ng-container *ngIf="newspaperFacade.dateOfCurrentYear$ | async as date">
	<ng-container *ngIf="cartFacade.shippingKind$ | async as shippingKind">
		<ng-container *ngIf="configurationFacade.shippingType(shippingKind) | async as shippingType">
			<ng-container *ngIf="configurationFacade.estimatedDelivery(shippingKind) | async as estimatedDelivery">
				<ng-container *ngIf="configurationFacade.suggestShippingKind(shippingKind, date) | async as suggestedShippingKind">
					<div class="alert alert--success" *ngIf="suggestedShippingKind == shippingKind">
						<mat-icon svgIcon="mdi-check" class="alert--icon"></mat-icon>
						<div class="alert--text" *ngIf="shippingType.shippingDaysMin < shippingType.shippingDaysMax">
							<b>Lieferdatum:</b>&nbsp;Mit {{ shippingKind }} zwischen {{ estimatedDelivery.shippingRange.from | amDateFormat: 'DD.MM.YYYY' }} und
							{{ estimatedDelivery.shippingRange.until | amDateFormat: 'DD.MM.YYYY' }}
						</div>
						<div class="alert--text" *ngIf="shippingType.shippingDaysMax > 0 && shippingType.shippingDaysMin == shippingType.shippingDaysMax">
							<b>Lieferdatum:</b>&nbsp;Mit {{ shippingKind }} am {{ estimatedDelivery.shippingRange.from | amDateFormat: 'DD.MM.YYYY' }}
						</div>
						<div class="alert--text" *ngIf="shippingType.shippingDaysMax == 0"><b>Abholung in Wuppertal</b>&nbsp;von Mo-Fr bis {{ shippingType.shippedBeforeHour }} Uhr</div>
					</div>
					<div class="alert alert--warning" *ngIf="suggestedShippingKind != shippingKind">
						<mat-icon svgIcon="mdi-alert-circle-outline" class="alert--icon"></mat-icon>
						<div class="alert--text">
							<b>Hinweis:</b>&nbsp;Um eine rechtzeitige Lieferung zum <u>{{ date | amDateFormat: 'DD.MM.YYYY' }}</u> zu gewährleisten wähle bitte als Versandmethode <i>{{ suggestedShippingKind }}</i
							>.
						</div>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>

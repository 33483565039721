import { createAction, props } from '@ngrx/store';
import { INewspaper, IPaging } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromNewspaperActions = {
	...EntityActions.create<INewspaper>('[Newspaper]'),
	dateChanged: createAction(`[Newspaper] Date Changed`, props<{ date: Date }>()),
	extended: createAction(`[Newspaper] Extended`),
	pagingChanged: createAction(`[Newspaper] Paging Changed`, props<{ paging: IPaging }>()),
	dayRangeChanged: createAction(`[Newspaper] Day Range Changed`, props<{ dayRange: IDayRange }>()),
	isFilterVisibleChanged: createAction(`[Newspaper] Is Filter Visible Changed`, props<{ isFilterVisible: boolean }>()),
	nameChanged: createAction(`[Newspaper] Name Changed`, props<{ name: string }>()),
	loadNewspaper: createAction(`[Newspaper] Load Newspaper`, props<{ id: string }>()),
	loadedNewspaper: createAction(`[Newspaper] Loaded Newspaper`, props<{ newspaper: INewspaper }>()),
};

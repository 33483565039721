import { IAddress } from './address.model';

export interface IContactInfo {
	salutationKind: SalutationKind;
	addressKind?: AddressKind;
	address: IAddress;
	firstName: string;
	lastName: string;
	company: string;
	iban: string;
	accountOwner: string;
}

export enum AddressKind {
	Private = 'Privat',
	Business = 'Geschäftlich',
}

export enum SalutationKind {
	Mr = 'Herr',
	Mrs = 'Frau',
	Var = 'Divers',
}

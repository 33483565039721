import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IShippingTypeInformation } from '../../models/configuration.model';
import { ShippingKind } from '../../models/order.model';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';

@Component({
	selector: 'shop-shippingkind-deliveryrange',
	templateUrl: './shippingkind-deliveryrange.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingKindDeliveryRangeComponent {
	public ShippingKinds = ShippingKind;
	@Input() public shippingType: IShippingTypeInformation;

	constructor(public configurationFacade: ConfigurationFacade, public newspaperFacade: NewspaperFacade) {}
}

import { on } from '@ngrx/store';
import { IConfiguration } from '../../models/configuration.model';
import { EntityState, IConfigurationState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromConfigurationActions } from './configuration.actions';

export const initialState: IConfigurationState = { ...EntityState.create<IConfiguration, IConfigurationState>(), shippingInformation: null, estimatedDeliveries: [] };
export const configurationReducer = EntityReducer.create<IConfiguration, IConfigurationState>(
	initialState,
	fromConfigurationActions,
	null,
	on(fromConfigurationActions.loadShippingInformation, state => ({
		...state,
		isLoading: true,
	})),
	on(fromConfigurationActions.loadedShippingInformation, (state, { shippingInformation }) => ({
		...state,
		isloading: false,
		shippingInformation,
	})),
	on(fromConfigurationActions.loadPaymentInformation, state => ({
		...state,
		isLoading: true,
	})),
	on(fromConfigurationActions.loadedPaymentInformation, (state, { paymentInformation }) => ({
		...state,
		isloading: false,
		paymentInformation,
	})),
	on(fromConfigurationActions.loadEstimatedDelivery, state => ({
		...state,
		isLoading: true,
	})),
	on(fromConfigurationActions.loadedEstimatedDeliveries, (state, { estimatedDeliveries }) => ({
		...state,
		isloading: false,
		estimatedDeliveries: [...state.estimatedDeliveries.filter(x => !estimatedDeliveries.some(y => x.shippingKind == y.shippingKind)), ...estimatedDeliveries],
	}))
);

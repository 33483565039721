import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { ClearAsyncErrorAction, FormGroupState, SetAsyncErrorAction, SetValueAction, StartAsyncValidationAction } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { debounceTime, filter, map, of, startWith, switchMap } from 'rxjs';
import { AppState } from './app.state';

@Injectable()
export abstract class BaseEffects {
	constructor(protected actions$: Actions, protected store: Store<AppState>) {}

	protected createRequiredFormEffect<TForm extends { [key: string]: any }>(
		formId: string,
		dependentControlNames: (keyof TForm & string)[],
		targetControlName: keyof TForm & string,
		formSelector: MemoizedSelector<AppState, FormGroupState<TForm>, DefaultProjectorFn<FormGroupState<TForm>>>,
		formValidator: (form: TForm) => boolean
	) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(SetValueAction.TYPE),
				debounceTime(100),
				filter((formControlUpdate: SetValueAction<string>) => [...dependentControlNames, targetControlName].map(dependentControlName => `${formId}.${dependentControlName}`).indexOf(formControlUpdate.controlId) > -1),
				concatLatestFrom(() => this.store.select(formSelector)),
				switchMap(([, form]) => {
					const targetControlId = `${formId}.${targetControlName}`;
					const clearRequiredError = new ClearAsyncErrorAction(targetControlId, 'required');

					if (formValidator(form.value)) {
						return of(clearRequiredError);
					}

					const setRequiredError = new SetAsyncErrorAction(targetControlId, 'required', 'Dies ist ein Pflichtfeld');
					const validateRequiredError = new StartAsyncValidationAction(targetControlId, 'required');

					return of(required(form.value[targetControlName])).pipe(
						map(error => (error.required ? setRequiredError : clearRequiredError)),
						startWith(validateRequiredError)
					);
				})
			)
		);
	}

	protected createUpdateFormEffect<TForm extends { [key: string]: any }>(
		formId: string,
		dependentControlNames: (keyof TForm & string)[],
		targetControlName: keyof TForm & string,
		formSelector: MemoizedSelector<AppState, FormGroupState<TForm>, DefaultProjectorFn<FormGroupState<TForm>>>,
		valueGetter: (form: TForm) => any
	) {
		return createEffect(() =>
			this.actions$.pipe(
				ofType(SetValueAction.TYPE),
				debounceTime(100),
				filter((formControlUpdate: SetValueAction<string>) => dependentControlNames.map(dependentControlName => `${formId}.${dependentControlName}`).indexOf(formControlUpdate.controlId) > -1),
				concatLatestFrom(() => this.store.select(formSelector)),
				switchMap(([, form]) => of(new SetValueAction(`${formId}.${targetControlName}`, valueGetter(form.value))))
			)
		);
	}
}

<div class="search-form">
	<div class="search-form__top">
		<shop-date-input></shop-date-input>
		<div class="region" *ngLet="regionFacade.selected$ | async as region">
			<div class="region-label text-center">Nach Erscheinungsgebiet sortieren</div>
			<div class="region-wrapper">
				<div>
					<a *ngIf="region?.name == 'Deutschland'" alt="Deutschland" class="map search selected map-deutschland" (click)="regionFacade.unselect()"></a>
					<a *ngIf="region?.name != 'Deutschland'" alt="Auswahl zurücksetzen" class="map search map-deutschland" (click)="regionFacade.changeName('Deutschland')"></a>
				</div>
				<div>
					<a *ngIf="region?.name == 'West'" alt="West" class="map search selected map-west" (click)="regionFacade.unselect()"></a>
					<a *ngIf="region?.name != 'West'" alt="Auswahl zurücksetzen" class="map search map-west" (click)="regionFacade.changeName('West')"></a>
				</div>
				<div>
					<a *ngIf="region?.name == 'Ost'" alt="Ost" class="map search selected map-ost" (click)="regionFacade.unselect()"></a>
					<a *ngIf="region?.name != 'Ost'" alt="Auswahl zurücksetzen" class="map search map-ost" (click)="regionFacade.changeName('Ost')"></a>
				</div>
				<div>
					<a *ngIf="region?.name == 'Nord'" alt="Nord" class="map search selected map-nord" (click)="regionFacade.unselect()"></a>
					<a *ngIf="region?.name != 'Nord'" alt="Auswahl zurücksetzen" class="map search map-nord" (click)="regionFacade.changeName('Nord')"></a>
				</div>
				<div>
					<a *ngIf="region?.name == 'Süd'" alt="Süd" class="map search selected map-sud" (click)="regionFacade.unselect()"></a>
					<a *ngIf="region?.name != 'Süd'" alt="Auswahl zurücksetzen" class="map search map-sud" (click)="regionFacade.changeName('Süd')"></a>
				</div>
			</div>
		</div>
	</div>
</div>

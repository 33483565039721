<ng-container *ngIf="configurationFacade.estimatedDelivery(ShippingKinds.DhlExpress) | async as estimatedDhlExpressDelivery">
	<div class="alert alert--default">
		<mat-icon svgIcon="mdi-truck-fast-outline" class="alert--icon"></mat-icon>
		<div class="alert--text" *ngIf="!showDhl">
			<b>Expressversand</b>
			<span *ngIf="estimatedDhlExpressDelivery.shippingRange.from != estimatedDhlExpressDelivery.shippingRange.until">
				zwischen {{ estimatedDhlExpressDelivery.shippingRange.from | amDateFormat: 'DD.MM.' }} und {{ estimatedDhlExpressDelivery.shippingRange.until | amDateFormat: 'DD.MM.YYYY' }}
			</span>
			<span *ngIf="estimatedDhlExpressDelivery.shippingRange.from == estimatedDhlExpressDelivery.shippingRange.until"> bis {{ estimatedDhlExpressDelivery.shippingRange.from | amDateFormat: 'dddd, DD.MM.YYYY' }} </span>
			<ng-container>möglich.</ng-container>
		</div>
		<div class="alert--text" *ngIf="showDhl">
			<b>Expresslieferung</b>
			<span *ngIf="estimatedDhlExpressDelivery.shippingRange.from != estimatedDhlExpressDelivery.shippingRange.until">
				zwischen {{ estimatedDhlExpressDelivery.shippingRange.from | amDateFormat: 'DD.MM.' }} und {{ estimatedDhlExpressDelivery.shippingRange.until | amDateFormat: 'DD.MM.YYYY' }}
			</span>
			<span *ngIf="estimatedDhlExpressDelivery.shippingRange.from == estimatedDhlExpressDelivery.shippingRange.until"> am {{ estimatedDhlExpressDelivery.shippingRange.from | amDateFormat: 'dddd, DD.MM.YYYY' }}</span>
			<ng-container *ngIf="configurationFacade.shippingType(ShippingKinds.Dhl) | async as shippingType">, mit DHL-Paket max. {{ shippingType.shippingDaysMax }} Werktage.</ng-container>
		</div>
	</div>
</ng-container>

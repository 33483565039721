import { ChangeDetectorRef, Component } from '@angular/core';
import { HeroTeaserFacade } from 'src/state/hero-teaser/hero-teaser.facade';
import { TemplateService } from '../../services/utility/template.service';
import { NewspaperFacade } from '../../state/newspaper/newspaper.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { ContentBlockComponent } from './contentblocks.component';

@Component({
	selector: 'shop-howitworks',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent extends ContentBlockComponent {
	constructor(changeDetector: ChangeDetectorRef, public heroTeaserFacade: HeroTeaserFacade, newspaperFacade: NewspaperFacade, routerFacade: RouterFacade, templateService: TemplateService) {
		super(changeDetector, routerFacade, newspaperFacade, templateService);
	}
}

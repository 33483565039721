import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { IEntity } from 'src/models/entity.model';
import { EntityService } from 'src/services/entity.service';
import { IErrorResult } from '../services/dto.service';
import { AppState, IEntityState } from './app.state';
import { BaseEffects } from './base.effects';
import { IEntityActions } from './entity.actions';
import { EntitySelector } from './entity.selector';
import { RouterSelector } from './router/router.selectors';

@Injectable()
export abstract class EntityEffects<TEntity extends IEntity, TEntityState extends IEntityState<TEntity>> extends BaseEffects {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		protected entityService: EntityService<TEntity>,
		protected entitySelector: EntitySelector<TEntity, TEntityState>,
		protected routerSelector: RouterSelector,
		@Inject('') protected entityActions: IEntityActions<TEntity>
	) {
		super(actions$, store);
	}

	public onLoad$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.load),
			debounceTime(10),
			switchMap(() =>
				this.entityService.getAll().pipe(
					map(list => this.entityActions.loaded({ list })),
					catchError((response: IErrorResult) => of(this.entityActions.failed({ message: response.error?.error || 'Es ist ein unbekannter Fehler aufgetreten' })))
				)
			)
		)
	);
}

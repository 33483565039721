import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { IPage } from 'src/models/page.model';
import { AppState, IPageState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable()
export class PageSelector extends EntitySelector<IPage, IPageState> {
	protected stateSelector = (state: AppState): IPageState => state.page;
	public list = createSelector(this.items, items => _.values(items));
	public listHeader = createSelector(this.list, list => list.filter(x => x.visibility.header).map(x => ({ ...x, children: x.children.filter(y => y.visibility.header) })));
	public listFooter = createSelector(this.list, list => list.filter(x => x.visibility.footer).map(x => ({ ...x, children: x.children.filter(y => y.visibility.footer) })));
	public listMobile = createSelector(this.list, list => list.filter(x => x.visibility.mobile).map(x => ({ ...x, children: x.children.filter(y => y.visibility.mobile) })));
	public listCheckout = createSelector(this.list, list => list.filter(x => x.visibility.footer && x.visibility.checkout));
	public isCheckout = createSelector(this.selected, selected => selected != null && (selected.url.startsWith('/kasse') || selected.url.startsWith('/bestellung')));
	public isConfig = createSelector(this.selected, selected => selected != null && selected.url.startsWith('/konfiguration'));
	public isSelected = createSelector(this.selectState, state => state.isSelected);
}

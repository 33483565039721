import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { crossFade } from '../../animations/crossfade.animation';
import { ICd } from '../../models/article.model';
import { ICdState } from '../../state/app.state';
import { CartFacade } from '../../state/cart/cart.facade';
import { CdFacade } from '../../state/cd/cd.facade';
import { DetailDialogComponent } from './detaildialog.component';

@Component({
	selector: 'shop-cd-detaildialog',
	templateUrl: 'cd-detaildialog.component.html',
	animations: [crossFade],
})
export class CdDetailDialogComponent extends DetailDialogComponent<ICd, ICdState> {
	constructor(@Inject(MAT_DIALOG_DATA) data: { article: ICd }, cartFacade: CartFacade, cdFacade: CdFacade) {
		super(data, cartFacade, cdFacade);
	}
}

import { Action, ActionCreator, ActionReducer, ActionType, createReducer, ReducerTypes } from '@ngrx/store';

export function createRehydrateReducer<State, A extends Action = Action>(key: string, initialState: State, ...ons: ReducerTypes<State, ActionCreator[]>[]): ActionReducer<State, A> {
	const item = localStorage.getItem(key);
	const newInitialState = (item && JSON.parse(item)) ?? initialState;
	const newOns: ReducerTypes<State, ActionCreator[]>[] = [];

	ons.forEach((on: ReducerTypes<State, ActionCreator[]>) => {
		newOns.push({
			...on,
			reducer: (state: State | undefined, action: ActionType<ActionCreator[][number]>) => {
				const newState = on.reducer(state, action);
				localStorage.setItem(key, JSON.stringify(newState));
				return newState;
			},
		});
	});

	return createReducer(newInitialState, ...newOns);
}
